import {Setting} from "./setting.models";
import {CloudFunctions} from "../parse";
import {SettingKeys} from "./constants";

const Parse = require('parse/dist/parse');

export async function getSettingByKey(key: string,sessionToken:string | undefined): Promise<Setting>{
    const query = new Parse.Query(Setting);
    query.equalTo('key', 'listMembership');
    return await query.first({sessionToken: sessionToken});
}
export async function getAllGoalsYears():Promise<string[]>{
    return Parse.Cloud.run(CloudFunctions.GET_GOALS_YEARS,{"key": SettingKeys.GOALS_YEARS});
}