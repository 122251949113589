import {FranchiseeMembership} from "../../hubspot";
import {User} from "./user.models";
const Parse = require('parse/dist/parse');

export function getUserFranchiseeMembership(memberships: FranchiseeMembership[],type:'coach'|'member') {
    return memberships.filter((membership)=> ( membership.user && membership.type.trim().toLowerCase() === type.trim().toLowerCase() ) )
}
export function getUserFromLocalStorage(): User | null {
    let currentUserInfo = localStorage.getItem('currentUserInfo') ?? '';
    if (currentUserInfo){
           return  Parse.User.fromJSON(
                Object.assign(JSON.parse(currentUserInfo), { className: '_User' })
            ) as User;
    }
    return null;
}