// import { Parse } from 'lib/parse'
const Parse = require('parse/dist/parse');

export class Territory extends Parse.Object {
    constructor() {
        super('Territory');
    }

    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }

    get description(): string {
        return this.get('description');
    }
    set description(value) {
        this.set('description', value);
    }

    get zipCodes(): string {
        return this.get('zipCodes');
    }
    set zipCodes(value) {
        this.set('zipCodes', value);
    }

    get cities(): string {
        return this.get('cities');
    }
    set cities(value) {
        this.set('cities', value);
    }

    get counties(): string {
        return this.get('counties');
    }
    set counties(value) {
        this.set('counties', value);
    }

    get states(): string {
        return this.get('states');
    }
    set states(value) {
        this.set('states', value);
    }

    get country(): string {
        return this.get('country');
    }
    set country(value) {
        this.set('country', value);
    }

    get meta(): Record<string, any> {
        return this.get('meta');
    }
    set meta(value) {
        this.set('meta', value);
    }

}
Parse.Object.registerSubclass('Territory', Territory);
