import {CloudFunctions} from "../../parse";
import {getTimeZoneDeviationInMinutes} from "../../common";
import {IAdsFilter, IGoogleAdsReport} from "./googleads.report.interfaces";
const Parse = require('parse/dist/parse');


export async function getFranchiseeCampaigns(filter: IAdsFilter): Promise<IGoogleAdsReport> {
    return Parse.Cloud.run(CloudFunctions.GET_GOOGLE_ADS_REPORTS, {
        ...filter,
        timeZoneDeviationInMinutes: getTimeZoneDeviationInMinutes(filter.startDateTime)
    }).then((response: any) => {
        return response;
    }).catch((error: any) => {
        throw (error);
    });
}