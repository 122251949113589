import {HubSpotToParseObject} from "../../common";

export class BusinessLocation extends HubSpotToParseObject {
    constructor() {
        super('BusinessLocation');
    }
    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }
    get alias(): string {
        return this.get('alias');
    }
    set alias(value) {
        this.set('alias', value);
    }
    get location(): Record<string, any>  {
        return this.get('location');
    }
    set location(value) {
        this.set('location', value);
    }
    get status(): string  {
        return this.get('status');
    }
    set status(value) {
        this.set('status', value);
    }

}
