import { HubSpotToParseObject } from '../../common';
import { List } from '../list';
import { Contact } from '../contact';
const Parse = require('parse/dist/parse');

export class ListMembership extends HubSpotToParseObject {
    constructor() {
        super('ListMembership');
    }

    get addedAt(): string {
        return this.get('addedAt');
    }
    set addedAt(value) {
        this.set('addedAt', value);
    }

    get list(): List {
        return this.get('list');
    }
    set list(value) {
        this.set('list', value);
    }

    get contact(): Contact {
        return this.get('contact');
    }
    set contact(value) {
        this.set('contact', value);
    }


}
Parse.Object.registerSubclass('ListMembership', ListMembership);
