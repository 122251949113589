import {CloudFunctions} from "../../parse";
import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {ILCSResults} from "./lcs.interfaces";
import {LifeCycleStage} from "./lcs.models";
const Parse = require('parse/dist/parse');


/**
 * Syncs life cycle stages in hubspot with that of parse server
 **/

export async function syncLifeCycleStages() : Promise<any>{
    Parse.Cloud.run(CloudFunctions.SYNC_LCS).then((response: any) =>{
        return response;
    }).catch((error: any) => {
        throw(error)
    });
}

/**
 * Fetches a paginated list of life cycle stages from app internal database
 * @param filter filter parameter of type [IFetchFilter]
 */
export async function getLifeCycleStages(filter?: IFetchFilter) : Promise<ILCSResults>{

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(LifeCycleStage).matches('uid', keyword, 'i'),
            new Parse.Query(LifeCycleStage).matches('name', keyword, 'i')
        ) :
        new Parse.Query(LifeCycleStage);

    if (limit === -1){
        return { results: await query.findAll() }
    }

    query.skip(skip);
    query.limit(limit);
    query.ascending('name');

    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return { results: await query.find() }
    }
}