import {t} from "i18next";


export const SOCIAL_ICON_SIZE = 24;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 64;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/;
// export const PASSWORD_LOWER_CASE_REGEX = /(?=.*?[a-z])/;
// export const PASSWORD_UPPER_CASE_REGEX = /(?=.*?[A-Z])/;
// export const PASSWORD_DIGITS_REGEX = /(?=.*?[0-9])/;
// export const PASSWORD_SPECIAL_CHARACTER_REGEX = /(?=.*?[#?!@$%^&*-])/;

// export const USERNAME_MIN_LENGTH = 1;
// export const USERNAME_MAX_LENGTH = 20;
// export const USERNAME_REGEX = /^(\w){1,20}$/;

// export const NAME_MIN_LENGTH = 1;
export const NAME_MAX_LENGTH = 255;
// export const MIN_FILES_NUMBER = 1;
// export const MIN_PHONE_NUMBERS = 1;
//
// export const TOAST_DELAY = 5000;

export const SUPPORTED_LANGUAGES = ["nl", "fr", "de", "en", "zh", "ar"]
// export const SUPPORTED_LOCALES = ["en", "de"]
export const SUPPORTED_COUNTRIES = ["DE", "US", "FR", "CM"]
// export const SUPPORTED_CURRENCIES = ["EUR", "USD", "GBP"]
// export const PAGE_SIZE = 100
export const MAX_IMAGE_UPLOAD_SIZE = 10 // value in megabytes
// export const MAX_FILE_UPLOADS = 50
export const CURRENCY_SYMBOL ="$"
export const TABLE_PAGE_SIZE = 25
export const BATCH_SIZE = 100
export const KEYWORD_SEARCH_DEBOUNCE_DELAY = 1000; //1s
export const DEFAULT_PRIVATE_ROUTE = '/welcome';
// export const PERMISSION_ROUTE = '/dashboard/load-permissions';
export const COLORS = [
    '#4ecdc4',
    '#DF7401',
    '#ffe378',
    '#005935',
    '#d78989',
    '#cb0f0f',
    '#61380B',
    '#5e0d70',
    '#145060',
    '#000000',
    '#043f80',
]
export const QUICK_DATES: Record<string, string> = {

    "custom": t('quickDates.custom'),
    "thisWeek" : t('quickDates.thisWeek'),
    "lastWeek" :t('quickDates.lastWeek'),
    "thisMonth" : t('quickDates.thisMonth'),
    "thisYear": t('quickDates.thisYear'),
    "lastMonth" : t('quickDates.lastMonth'),
    "thisQuarter" : t('quickDates.thisQuarter'),
    "lastQuarter" : t('quickDates.lastQuarter'),
    "last30Days" : t('quickDates.last30Days'),
    "last60Days" : t('quickDates.last60Days'),
    "lastYear" : t('quickDates.lastYear'),
}
export enum QUICK_DATES_KEYS {
    THIS_WEEK = 'thisWeek',
    THIS_YEAR = 'thisYear',
    LAST_WEEK = 'lastWeek',
    THIS_MONTH = 'thisMonth',
    LAST_MONTH = 'lastMonth',
    THIS_QUARTER = 'thisQuarter',
    LAST_QUARTER = 'lastQuarter',
    LAST_30_DAYS = 'last30Days',
    LAST_60_DAYS = 'last60Days',
    LAST_YEAR = 'lastYear',
    CUSTOM = 'custom'
}
export const UTC_OFFSET_RANGES: Record<string, string> = {
    "-12": "UTC-12:00",
    "-11": "UTC-11:00",
    "-10": "UTC-10:00",
    "-9.5": "UTC-09:30",
    "-9": "UTC-09:00",
    "-8": "UTC-08:00",
    "-7": "UTC-07:00",
    "-6": "UTC-06:00",
    "-5": "UTC-05:00",
    "-4": "UTC-04:00",
    "-3.5": "UTC-03:30",
    "-3": "UTC-03:00",
    "-2": "UTC-02:00",
    "-1": "UTC-01:00",
    "0": "UTC±00:00",
    "1": "UTC+01:00",
    "2": "UTC+02:00",
    "3": "UTC+03:00",
    "3.5": "UTC+03:30",
    "4": "UTC+04:00",
    "5": "UTC+05:00",
    "5.5": "UTC+05:30",
    "5.75": "UTC+05:45",
    "6": "UTC+06:00",
    "6.5": "UTC+06:30",
    "7": "UTC+07:00",
    "8": "UTC+08:00",
    "8.5": "UTC+08:30",
    "8.75": "UTC+08:45",
    "9": "UTC+09:00",
    "9.5": "UTC+09:30",
    "10": "UTC+10:00",
    "10.5": "UTC+10:30",
    "11": "UTC+11:00",
    "12": "UTC+12:00",
    "12.75": "UTC+12:45",
    "13": "UTC+13:00",
    "14": "UTC+14:00",
}

