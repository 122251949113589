import {CloudFunctions} from "../../parse";
import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {IListResults} from "./list.interfaces";
import {List} from "./list.models";
const Parse = require('parse/dist/parse');


/**
 * Syncs lists in hubspot with that of parse server
 **/

export async function syncLists() : Promise<any>{
    Parse.Cloud.run(CloudFunctions.SYNC_LISTS).then((response: any) =>{
        return response;
    }).catch((error: any) => {
        throw(error)
    });
}

/**
 * Fetches a paginated list of Lists from app internal database
 * @param filter filter parameter of type [IFetchFilter]
 */
export async function getLists(filter?: IFetchFilter) : Promise<IListResults>{

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(List).matches('uid', keyword, 'i'),
            new Parse.Query(List).matches('name', keyword, 'i'),
        ) :
        new Parse.Query(List);
    if (limit === -1){
        return { results: await query.findAll() }
    }

    query.skip(skip);
    query.limit(limit);
    query.includeAll();
    query.descending('createdAt');

    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return { results: await query.find() }
    }
}