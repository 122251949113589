import { IGoalResults, IGoalsFilter} from "./goal.interface";
import { TABLE_PAGE_SIZE} from "../../common";
import {Goal} from "./goal.models";
const Parse = require('parse/dist/parse');

/**
 * Fetches a paginated list of goals from app internal database
 * @param filter filter parameter of type [IGoalsFilter]
 */
export async function getGoals(filter?: IGoalsFilter): Promise<IGoalResults> {
    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(Goal).matches('description', keyword, 'i'),
            new Parse.Query(Goal).matches('name', keyword, 'i'),
        ) :
        new Parse.Query(Goal);
    if (filter?.isFranchiseeGoals){
        query.equalTo('isFranchiseeGoal',filter?.isFranchiseeGoals)
    }
    if (limit === -1) {
        return {results: await query.findAll()}
    }
    query.skip(skip);
    query.limit(limit);

    if (filter?.withCount) {
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return {results: await query.find()}
    }
}