import { IFranchiseeResults} from "./franchisee.interfaces";
import {CloudFunctions} from "lib/parse";
import {Franchisee} from "./franchisee.models";
import { IFetchFilter, ReactUserSelectOptions, TABLE_PAGE_SIZE} from "../../common";
import {List} from "../list";
import {FranchiseeMembership} from "./members/franchiseeMembers.models";
import {getFranchiseeMembership} from "./members/franchiseeMemebers.services";

const Parse = require('parse/dist/parse');

/**
 * Fetches a paginated list of franchises from app internal database
 * @param filter filter parameter of type [IFetchFranchiseesFilter]
 */
export async function getFranchisees(filter?: IFetchFilter): Promise<IFranchiseeResults> {

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(Franchisee).matches('uid', keyword, 'i'),
            new Parse.Query(Franchisee).matches('shortName', keyword, 'i'),
            new Parse.Query(Franchisee).matches('name', keyword, 'i'),
            new Parse.Query(Franchisee).matches('headline', keyword, 'i'),
            new Parse.Query(Franchisee).matches('addressLine1', keyword, 'i'),
            new Parse.Query(Franchisee).matches('addressLine2', keyword, 'i'),
            new Parse.Query(Franchisee).matches('zipCode', keyword, 'i'),
            new Parse.Query(Franchisee).matches('county', keyword, 'i'),
            new Parse.Query(Franchisee).matches('state', keyword, 'i'),
            new Parse.Query(Franchisee).matches('country', keyword, 'i'),
            new Parse.Query(Franchisee).matches('phone', keyword, 'i'),
            new Parse.Query(Franchisee).matches('email', keyword, 'i'),
            new Parse.Query(Franchisee).matches('pathname', keyword, 'i')
        ) :
        new Parse.Query(Franchisee);

    query.include('lists');
    // query.rela
    if (limit === -1) {
        return {results: await query.findAll()}
    }

    // limit = 25;

    query.skip(skip);
    query.limit(limit);
    query.ascending('name');

    if (filter?.withCount) {
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return {results: await query.find()}
    }
}

export async function getFranchiseeById(
    id: string | number
  ): Promise<Franchisee | any> {
    const query = Parse.Query.or(
      new Parse.Query(Franchisee).equalTo('uid', `${id}`),
      new Parse.Query(Franchisee).equalTo('objectId', `${id}`)
    );
    query.includeAll();
    return await query.first({ useMasterKey: true });
  }


/**
 * Syncs franchisees in hubdb with that of parse server
 */
export async function syncFranchisees(): Promise<any> {
    Parse.Cloud.run(CloudFunctions.SYNC_FRANCHISEES).then((response: any) => {
        return response;
    }).catch((error: any) => {
        throw(error)
    });
}

/**
 * Syncs and publishes the underlying hubdb
 * and returns franchisee data for the current page being viewed by the user
 */
export async function publish(data?: IFetchFilter): Promise<any> {
    return await Parse.Cloud.run(CloudFunctions.SYNC_FRANCHISEES, data).then((response: any) => {
        return response;
    }).catch((error: any) => {
        throw(error)
    });
}

export async function assignLists(franchisee: Franchisee, data: List[]) {
    data = data.map((list)=>{
        list.franchisee = franchisee
        return list
    });
    franchisee.set('lists', data);
    return await franchisee.save(null, {
        cascadeSave: true,
    });
}

export async function assignFranchiseeMembers(franchisee: Franchisee, data: ReactUserSelectOptions[], type: 'coach' | 'member'): Promise<boolean> {
    for (const user of data) {
        if (user) {
            //Only save new membership
            const membership = await getFranchiseeMembership(franchisee, user.value, type) ?? new FranchiseeMembership();
            if (membership?.isNew()) {
                membership.type = type;
                membership.franchisee =franchisee;
                membership.user = user.value
                await membership.save();
            }
        }
    }
    return true;
}

export async function getFranchiseeMembers(franchisee:Franchisee): Promise<FranchiseeMembership[]> {
    const query = new Parse.Query('FranchiseeMembership');
    query.equalTo('franchisee',franchisee);
    query.includeAll();
    return await query.findAll();
}
export async function removeFranchiseeList(id:string): Promise<any>{
    return await Parse.Cloud.run(CloudFunctions.DESTROY_ASSIGNED_LIST, {id: id});
}