import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {ISurveyReportResultsWithCount} from "../../report/birdEye";
import {SurveyResponse} from "./survey-reponse.models";
const Parse = require('parse/dist/parse');

export async function getSurveysResponses(filter?: IFetchFilter): Promise<ISurveyReportResultsWithCount> {

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(SurveyResponse).matches('uid', keyword, 'i'),
            new Parse.Query(SurveyResponse).matches('source.answers.questionTitle', keyword, 'i'),
            new Parse.Query(SurveyResponse).matches('businessLocationName', keyword, 'i'),
            new Parse.Query(SurveyResponse).matches('surveyName', keyword, 'i')
        ) :
        new Parse.Query(SurveyResponse);

    query.include('survey.businessLocation.name');
    // query.rela
    if (limit === -1) {
        return {results: await query.findAll()}
    }

    // limit = 25;

    query.skip(skip);
    query.limit(limit);

    if (filter?.withCount) {
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return {results: await query.find()}
    }
}