import { User } from './user.models';
import {
    IUserResults,
    IApproveUser,
    IForgotPasswordFormData,
    ILoginFormData,
    IRegistrationFormData,
    IUserFilter, IUserDependencies, IChangePasswordFormData
} from './user.interfaces';
import {BATCH_SIZE, getTimeZoneDeviationInMinutes, IStatisticFilter, IStatistics, TABLE_PAGE_SIZE} from "../../common";
import {CloudFunctions} from "../../parse";
import {USER_STATUSES} from "./user.constants";
import {Role} from "../role";
import {FranchiseeMembership} from "../../hubspot";
import date from "date-and-time";
import {getEndDateAndStartDateInUTC} from "../../report";
import {IStatisticsReport} from "../../report/birdEye";

const Parse = require('parse/dist/parse');

export async function signUp(data: IRegistrationFormData) : Promise<User>{
    // Instantiate a new user object
    const user = new User({ username: data.email, ...data });
    // Sign up user with parse server
    return await user.signUp()
    // Remove any persisted user from session as we don't want user instance after sign up
    // await logOut()
}
export async function logIn(data: ILoginFormData) : Promise<User>{
    const user = new User({ username: data.username, password: data.password ,verificationCode: data.verificationCode})
    // Sign In user with parse server
    if (data.verificationCode){
        await validateVerificationCode(data)
    }
       return await user.logIn()
}

export async function logOut() : Promise<void>{
    await Parse.User.logOut();
}

export async function requestPasswordReset(data: IForgotPasswordFormData) : Promise<void> {
    await Parse.User.requestPasswordReset(data.email);
}
export async function requestChangePassword(user : User | Parse.User, data: IChangePasswordFormData) : Promise<void> {
    //@ts-ignore
    await Parse.User.verifyPassword(user.get('username'), data.currentPassword);
    user.setPassword(data.newPassword); //.password = ;
   return  await user.save(null, { sessionToken: user.getSessionToken() });
}

export async function getUsers(filter: IUserFilter,roleName?: string) : Promise<IUserResults> {
    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();
    const status = filter?.status?.trim()?.toLowerCase() ?? '';

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(User).matches('username', keyword, 'i'),
            new Parse.Query(User).matches('firstName', keyword, 'i'),
            new Parse.Query(User).matches('lastName', keyword, 'i'),
            new Parse.Query(User).matches('email', keyword, 'i'),
            new Parse.Query(User).matches('phone', keyword, 'i'),
        ) :
        new Parse.Query(User);

    if (USER_STATUSES.includes(status)){
        switch (status) {
            case 'approved':
                query.equalTo('approved', true);
                break;
            case 'unapproved':
                query.notEqualTo('approved', true);
                break;
            case 'verified':
                query.equalTo('emailVerified', true);
                break;
            case 'unverified':
                query.notEqualTo('emailVerified', true);
                break;
            case 'archived':
                query.equalTo('archived', true);
                break;
            case 'unarchived':
                query.notEqualTo('archived', true);
                break;
        }
    }
    //get users having a particular role
    if (roleName){
        const roleQuery = new Parse.Query(Role);
        roleQuery.matches('name',roleName,'i');
        query.matchesQuery('roles',roleQuery);
    }
    query.include(['roles.name'])
    if (filter?.pageSize === -1){
        return await query.findAll({batchSize: BATCH_SIZE,sessionToken: filter.sessionToken});
    }

    query.skip(skip);
    query.limit(limit);
    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find({sessionToken: filter.sessionToken});
    } else {
        return {
            results: await query.find({sessionToken: filter.sessionToken})
        }
    }
}

export async function getUserById(id:string): Promise<User> {
    const query = Parse.Query.or(
        new Parse.Query(User).equalTo('username', id),
        new Parse.Query(User).equalTo('email', id),
        new Parse.Query(User).equalTo('objectId', id),
    );
    query.includeAll();
    return query.first();

}
export async function getUserOrganisations(user:User): Promise<FranchiseeMembership[]> {
    const query = new Parse.Query(FranchiseeMembership);
    query.equalTo('user',user);
    query.include(['franchisee']);
    return await query.findAll();
}
export async function getUserByIdAndItsOrganisations(userId:string): Promise<User> {
    let user = await getUserById(userId);
    if (user){
        user['organisations'] = await getUserOrganisations(user);
        return user;
    }
    return user
}

export async function approveUser(data:IApproveUser): Promise<boolean>{
    return await Parse.Cloud.run(CloudFunctions.APPROVE_USER,data) as boolean;
}

export async function destroyUser(id:string): Promise<User>{
    return await Parse.Cloud.run(CloudFunctions.DESTROY_USER, {id});
}

export async function updateUser(user:User): Promise<User>{
    const data = (user.dirtyKeys() as string[]).reduce<Record<string, any>>((acc, key) => {
        acc[key] = user.get(key);
        return acc;
    }, {id: user.id});
    if (data['roles']){
        data['roles'] = data.roles.map((role:Role)=> role.id)
    }
    return await Parse.Cloud.run(CloudFunctions.UPDATE_USER, data);
}
export async function getUserDependencies(userId: User | string): Promise<IUserDependencies> {
    return (await Parse.Cloud.run(CloudFunctions.GET_USER_DEPENDENCIES, { userId })) as IUserDependencies;
}
export async function getSystemStatistics(filter: IStatisticFilter): Promise<IStatisticsReport> {
    // @ts-ignore todo adding comparing start date and end date to make server request'
    if (filter.comparisonPeriod) {
        filter.comparisonStateEndDate = {
            startDate: '',
            endDate: date.format(date.addDays(new Date(filter.startDateTime), -1), 'YYYY-MM-DD')
        };
        if (filter.comparisonPeriod.type === 'day') {
            filter.comparisonStateEndDate.startDate = date.format(date.addDays(new Date(filter.startDateTime), filter.comparisonPeriod.total), 'YYYY-MM-DD');
        } else if (filter.comparisonPeriod.type === 'week') {
            filter.comparisonStateEndDate.startDate = date.format(date.addDays(new Date(filter.startDateTime), -(filter.comparisonPeriod.total * 7)), 'YYYY-MM-DD');
        } else if (filter.comparisonPeriod.type === 'month') {
            filter.comparisonStateEndDate.startDate = date.format(date.addMonths(new Date(filter.startDateTime), -(filter.comparisonPeriod.total)), 'YYYY-MM-DD');
        } else {
            filter.comparisonStateEndDate.startDate = date.format(date.addYears(new Date(filter.startDateTime), -(filter.comparisonPeriod.total)), 'YYYY-MM-DD');
        }
        const{utcStartDate: compareStartDate,utcEndDate: compareEndDate}  = getEndDateAndStartDateInUTC(filter.comparisonStateEndDate.startDate,filter.comparisonStateEndDate.endDate,filter.timeZone);
        filter.comparisonStateEndDate.startDate = compareStartDate.toISOString();
        filter.comparisonStateEndDate.endDate = compareEndDate.toISOString();
    }
    return await Parse.Cloud.run(CloudFunctions.GET_STATISTICS,{...filter,
        timeZoneDeviationInMinutes:getTimeZoneDeviationInMinutes(filter.startDateTime)
    }) ;
}

export async function sendLoginVerificationCode(data: ILoginFormData): Promise<boolean>{
    return  (await Parse.Cloud.run(CloudFunctions.SEND_LOGIN_VERIFICATION_CODE,data) );
}
export async function validateVerificationCode(data: ILoginFormData): Promise<boolean>{
    return await Parse.Cloud.run(CloudFunctions.VALIDATE_VERIFICATION_CODE,data) as boolean;
}
export async function resendVerificationCode(data: ILoginFormData): Promise<boolean>{
    return await Parse.Cloud.run(CloudFunctions.RESEND_VERIFICATION_CODE,data) as boolean;
}
