export enum CloudFunctions {
    SYNC_FRANCHISEES = 'syncFranchisees',
    FETCH_FRANCHISEES = 'fetchFranchisees',
    SYNC_LISTS = 'syncLists',
    SYNC_LCS = 'syncLifeCycleStages',
    SYNC_CONTACTS = 'syncContacts',
    SYNC_DEALS = 'syncDeals',
    SYNC_PIPELINES = 'syncPipelines',
    SYNC_COMPANIES = 'syncCompanies',
    FETCH_CONTACT_REPORT = 'fetchContactReport',
    FETCH_LEADS_REPORT = 'fetchLeadsReport',
    FETCH_SALES_REPORT = 'fetchSalesReport',
    FETCH_GOALS_REPORT = 'fetchGoalsReport',
    FETCH_DEAL_REPORT = 'fetchDealReport',
    FETCH_PIPELINES = 'fetchPipelines',
    FETCH_PIPELINES_STAGES = 'fetchPipelinesStages',
    APPROVE_USER='approveUser',
    DESTROY_USER='destroyUser',
    UPDATE_USER='updateUser',
    SAVE_ROLE='saveRole',
    DESTROY_ROLE='destroyRole',
    GET_ROLE_AND_PERMISSIONS='getRolesAndPermissions',
    SAVE_ROLE_PERMISSIONS='saveRolePermissions',
    GET_USER_ROLES_WITH_PERMISSIONS='getUserRolesWithPermissions',
    GET_USER_DEPENDENCIES='getUserDependencies',
    GET_USER_PERMISSIONS='getUserPermissions',
    GET_STATISTICS ='getStatistics',
    DESTROY_FRANCHISEE_MEMBERSHIP = 'destroyFranchiseeMembership',
    REMOVE_FRANCHISEE_LIST = 'removeFranchiseeList',
    FETCH_SURVEY_REPORT = 'fetchSurveyReport',
    SEND_LOGIN_VERIFICATION_CODE = 'sendLoginVerificationCode',
    VALIDATE_VERIFICATION_CODE ="validateVerificationCode",
    RESEND_VERIFICATION_CODE ="resendVerificationCode",
    GET_CONTACT_STATISTICS ='getContactStatistics',
    GET_FORM_CONVERSION_STATISTICS ='getFormConversionStatistics',
    GET_CONTACT_SOURCES_FROM_STATISTICS = 'getContactSourcesFromStatistics',
    SAVE_GOALS ='saveGoals',
    SAVE_FRANCHISEE_GOALS ='saveFranchiseeGoals',
    GET_GOALS_YEARS ='getGoalsYears',
    GET_REVIEWS = 'getReviews',
    SAVE_FEEDBACK = 'saveFeedback',
    DESTROY_ASSIGNED_LIST = "destroyAssignedList",
    CHANGE_PASSWORD = "changePassword",
    GET_GOOGLE_ADS_REPORTS = 'getGoogleAdsReport',
}
