const Parse = require('parse/dist/parse');

export class Setting extends Parse.Object {
    constructor() {
        super('Setting');
    }
    get key(): string {
        return this.get('key');
    }
    set key(value) {
        this.set('key', value);
    }
    get config(): Record<string, any> {
        return this.get('config');
    }
    set config(value) {
        this.set('config', value);
    }
}