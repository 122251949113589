import {HubSpotToParseObject} from "../../common";

export class Review extends HubSpotToParseObject {
    constructor() {
        super('Review');
    }
    get rating(): number {
        return this.get('rating');
    }
    set rating(value) {
        this.set('rating', value);
    }
    get reviewDate(): string {
        return this.get('reviewDate');
    }
    set reviewDate(value) {
        this.set('reviewDate', value);
    }
    get responseDate(): string {
        return this.get('responseDate');
    }
    set responseDate(value) {
        this.set('responseDate', value);
    }
    get reviewer(): Record<string, any>  {
        return this.get('reviewer');
    }
    set reviewer(value) {
        this.set('reviewer', value);
    }

}
