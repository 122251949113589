import {Permission} from "../permission";

const Parse = require('parse/dist/parse');
export class Role extends Parse.Role {
    constructor(param: Parse.Attributes) {
        super(param?.name, param?.acl);
    }

    get name(): string {
        return this.getName() || this.get('name');
    }
    set name(value: string) {
        this.setName(value);
    }

    get description(): string {
        return this.get('description');
    }
    set description(value) {
        this.set('description', value);
    }

    get default(): boolean {
        return this.get('default');
    }
    set default(value) {
        this.set('default', value);
    }
    // @ts-ignore
    get permissions(): Parse.Relation<this, Permission> {
        return this.relation('permissions');
    }
    get rolePermissions(): Permission[] {
        return this.get('rolePermissions');
    }
    set rolePermissions(value){
         this.set('rolePermissions',value);
    }

    get meta(): Record<string, any> {
        return this.get('meta');
    }
    set meta(value) {
        this.set('meta', value);
    }

}