import {HubSpotToParseObject} from "../../common";
import {PipelineStage} from "../pipeline-stage";
const Parse = require('parse/dist/parse');

export class Pipeline extends HubSpotToParseObject {
    constructor() {
        super('Pipeline');
    }
    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }

    get pipelineStages(): PipelineStage[] {
        return this.get('pipelineStages');
    }
    set pipelineStages(value) {
        this.set('pipelineStages', value);
    }
}
