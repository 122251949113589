import {BATCH_SIZE, IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {IPermissionResults} from "./permission.interface";
import {Permission} from "./permission.models";
import {User} from "../user";
import {CloudFunctions} from "../../parse";
const Parse = require('parse/dist/parse');


export async function getPermissions(filter: IFetchFilter) : Promise<IPermissionResults> {
    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(Permission).matches('name', keyword, 'i'),
            new Parse.Query(Permission).matches('key', keyword, 'i'),
            new Parse.Query(Permission).matches('module', keyword, 'i'),
            new Parse.Query(Permission).matches('description', keyword, 'i'),
        ) :
        new Parse.Query(Permission);

    if (filter?.pageSize === -1){
        return await query.findAll({batchSize: BATCH_SIZE});
    }

    query.skip(skip);
    query.limit(limit);

    // Add sort parameters
    query.descending('module');
    query.addAscending('weight');

    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return {
            results: await query.find()
        }
    }
}
export async function getPermissionById(id: string): Promise<Permission | undefined> {
    const query =  new Parse.Query(Permission).equalTo("objectId", id);
    query.includeAll();
    return await query.first();
}

export async function getPermission(module:string, key: string): Promise<Permission | undefined> {
    const query =  new Parse.Query(Permission)
        .equalTo("module", module)
        .equalTo("key", key);
    query.includeAll();
    return await query.first();
}


export async function getUserPermissions(user: User | string): Promise<Permission[]> {
    return (await Parse.Cloud.run(CloudFunctions.GET_USER_PERMISSIONS, {
        userId: typeof(user) === "string" ? user : user?.id
    }));
}