import {Franchisee} from "../franchisee.models";
import {User} from "../../../auth";
import {FranchiseeMembership} from "./franchiseeMembers.models";
import {useAuthStore} from "../../../state/zustand";
import {CloudFunctions} from "../../../parse";

const Parse = require('parse/dist/parse');

export async function getFranchiseeMembership(franchisee: Franchisee, user: User, type: 'coach' | 'member'): Promise<FranchiseeMembership | undefined> {
    const query = new Parse.Query('FranchiseeMembership');
    query.equalTo("type", type);
    query.equalTo("user", user);
    query.equalTo("franchisee", franchisee);
    return await query.first();
}
export async function getFranchiseeMembershipByAttribute(attr: string, attrValue: User | 'coach' | 'member' | Franchisee): Promise<FranchiseeMembership[] | undefined> {
    const query = new Parse.Query('FranchiseeMembership');
    query.equalTo(attr, attrValue);
    query.include(['franchisee']);
    return await query.findAll();
}
export async function removeFranchiseeMember(franchiseeMember: FranchiseeMembership,sessionToken:string) {
    await franchiseeMember.destroy({sessionToken: sessionToken})
}
export async function destroyFranchiseeMembership(id:string): Promise<User>{
    return await Parse.Cloud.run(CloudFunctions.DESTROY_FRANCHISEE_MEMBERSHIP, {id});
}