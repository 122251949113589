import {HubSpotToParseObject} from "../../common";
import {Territory} from "../territory";
import {Franchisee} from "../franchisee";

const Parse = require('parse/dist/parse');

export class List extends HubSpotToParseObject {
    constructor() {
        super('List');
    }

    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }

    get description(): string {
        return this.get('description');
    }
    set description(value) {
        this.set('description', value);
    }

    get listMemberships(): Parse.Relation {
        return this.relation('listMemberships');
    }

    get territory(): Territory {
        return this.get('territory');
    }
    set territory(value) {
        this.set('territory', value);
    }
    get franchisee(): Franchisee {
        return this.get('franchisee');
    }
    set franchisee(value) {
        this.set('franchisee', value);
    }

}
Parse.Object.registerSubclass('List', List);
