import config from '../../config';
import { Permission, Role, User } from '../auth';
import { Contact } from '../hubspot/contact';
import { ListMembership } from '../hubspot/list-membership';
import { Franchisee, FranchiseeMembership } from '../hubspot/franchisee';
import { List } from '../hubspot/list';
import { Company } from '../hubspot/company';
import { Deal } from '../hubspot/deal';
import { Pipeline } from '../hubspot/pipeline';
import { PipelineStage } from '../hubspot/pipeline-stage';
import { BusinessLocation } from '../customer-experience/business-location';
import { Survey } from '../customer-experience/survey/survey.models';
import { SurveyResponse } from '../customer-experience/survey-response';
import { Review } from '../customer-experience/review/review.models';
import { DealStage } from '../hubspot/deal-stage/deal-stage.models';
import { Goal } from '../auth/goal/goal.models';
import { Setting } from '../setting/setting.models';
import { Feedback } from '../feedback/feedback.models';

const Parse = require('parse/dist/parse');

export function initializeParseServer(language?: string): User | Parse.User | null {
    Parse.initialize(config.PARSE_SERVER_APPLICATION_ID, config.PARSE_SERVER_JAVASCRIPT_KEY);
    Parse.serverURL = config.PARSE_SERVER_URL;

    Parse.getServerHealth().then((response: any) => {
        console.log('Server Check :: ', response.status?.toUpperCase());
        if (response.status === 'ok') {
            // Register subclasses
            Parse.Role.registerSubclass('_Role', Role);
            Parse.User.registerSubclass('_User', User);
            Parse.Object.registerSubclass('Company', Company);
            Parse.Object.registerSubclass('Contact', Contact);
            Parse.Object.registerSubclass('ListMembership', ListMembership);
            Parse.Object.registerSubclass('Franchisee', Franchisee);
            Parse.Object.registerSubclass('List', List);
            Parse.Object.registerSubclass('PipelineStage', PipelineStage);
            Parse.Object.registerSubclass('Pipeline', Pipeline);
            Parse.Object.registerSubclass('Deal', Deal);
            Parse.Object.registerSubclass('Permission', Permission);
            Parse.Object.registerSubclass('FranchiseeMembership', FranchiseeMembership);
            Parse.Object.registerSubclass('BusinessLocation', BusinessLocation);
            Parse.Object.registerSubclass('Survey', Survey);
            Parse.Object.registerSubclass('SurveyResponse', SurveyResponse);
            Parse.Object.registerSubclass('Review', Review);
            Parse.Object.registerSubclass('DealStage', DealStage);
            Parse.Object.registerSubclass('Goal', Goal);
            Parse.Object.registerSubclass('Setting', Setting);
            Parse.Object.registerSubclass('Feedback', Feedback);

            // Enable user encryption
            // Parse.enableEncryptedUser()
            // Parse.secret = config.PARSE_SERVER_ENCRYPT_USER_SECRET;

            // Enable pinning in your application.
            Parse.enableLocalDatastore();
        }
    });

    return Parse.User.current() as User;
}
