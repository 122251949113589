import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN_US from 'lib/translate/locales/en-US.json';

declare module 'i18next' {
interface CustomTypeOptions {
      returnNull: false;
    }
}

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        returnNull: false,
        resources: {
            "en-US": {
                translation: translationEN_US,
            },
        },
        lng: 'en-US',
        fallbackLng: 'en-US', // use en if detected lng is not available

        // keySeparator: true, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
