import { BATCH_SIZE, IFetchFilter, TABLE_PAGE_SIZE } from '../../common';
import { IRoleAndPermissions, IRoleFormData, IRoleResults } from './role.interfaces';
import { Role } from './role.models';
import { CloudFunctions } from '../../parse';
import { Permission } from '../permission';
const Parse = require('parse/dist/parse');

export async function getRoleById(id: string): Promise<Role> {
    const query = Parse.Query.or(
        new Parse.Query(Role).equalTo('objectId', id),
        new Parse.Query(Role).equalTo('name', id)
    );
    query.includeAll();
    return await query.first();
}

export async function getRoles(filter: IFetchFilter): Promise<IRoleResults> {
    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? ((filter?.page || 1) - 1) * limit;
    const keyword = filter?.keyword?.trim();

    const query = keyword ? Parse.Query.or(new Parse.Query(Role).matches('name', keyword, 'i')) : new Parse.Query(Role);

    if (filter?.pageSize === -1) {
        return await query.findAll({ batchSize: BATCH_SIZE });
    }

    query.skip(skip);
    query.limit(limit);
    if (filter?.keyword) {
        query.ascending('name');
    } else {
        query.descending('createdAt');
    }

    if (filter?.withCount) {
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return {
            results: await query.find(),
        };
    }
}

export async function saveRole(data: IRoleFormData): Promise<Role> {
    return await Parse.Cloud.run(CloudFunctions.SAVE_ROLE, data);
}

export async function destroyRole(id: string): Promise<Role> {
    return await Parse.Cloud.run(CloudFunctions.DESTROY_ROLE, { id });
}

export async function getRoleAndPermissions(roleId?: string | number): Promise<IRoleAndPermissions> {
    const response = (await Parse.Cloud.run(CloudFunctions.GET_ROLE_AND_PERMISSIONS, {
        roleId,
    })) as IRoleAndPermissions;
    if (response) {
        response.roles = response.roles.map((role) => {
            return convertJsonRoleToParseRole(role);
        });
    }
    return response;
}
export function convertJsonRoleToParseRole(json: Record<string, any>) {
    return Role.fromJSON(Object.assign(json, { className: '_Role' }));
}
export async function saveRolePermissions(roles: Role[]): Promise<boolean> {
    //TODO only send ids
    const rolePermissions = roles.map((role) => {
        const permissions = role.rolePermissions?.map((permission) => permission.id) ?? [];
        return {
            roleId: role.id,
            permissions,
        };
    });
    return await Parse.Cloud.run(CloudFunctions.SAVE_ROLE_PERMISSIONS, {
        editedRoles: JSON.stringify(rolePermissions),
    });
}

export async function getRolePermissions(role: Role): Promise<Permission[]> {
    const permissionRelations = role.relation('permissions');
    return await permissionRelations.query().find();
}
