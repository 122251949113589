import {useState} from "react";
import { IAlert } from "./interfaces";

/**
 * Hook to display alerts
 */
export const useAlerts = () => {
    const [alert, setAlert] = useState<IAlert | null>();
    return {
        alert,
        setAlert
    }
}


/**
 * Hook to toggle hide/show password
 */
export const useTogglePassword = () => {
    // Toggle password visibility on and off
    const [hidePassword, setHidePassword] = useState(true);
    const togglePasswordVisibility = () => setHidePassword(!hidePassword);

    return {
        hidePassword,
        togglePasswordVisibility
    }
}

/**
 * App locale utility hook
 */
export function useSwitchLocale(){
    //const router = useRouter();
    // function switchLocale(locale: ISupportedLocale) {
    //     if (router.locale !== locale){
    //         // Once there is a manual switch of locale, remove any locale cookie set.
    //         // This means the user wants to take back control of the locale.
    //         // Locale cookie shall only be set again on next login
    //         Cookie.remove(ECookieKeys.AUTH_USER_LOCALE);
    //         const {pathname, query } = router;
    //         router.push({
    //             pathname,
    //             query
    //         }, undefined, {locale});
    //     }
    // }

    // return {
    //     switchLocale
    // };
}