import {CloudFunctions} from "../../parse";
import {IReportFilter} from "../../common";
import {Deal} from "./deal.models";
import {
    getEndDateAndStartDateInUTC
} from "../../report";
import {IDealResults} from "./deal.interfaces";
import {Contact} from "../contact";
const Parse = require('parse/dist/parse');


/**
 * Fetches a paginated list of Lists from app internal database
 * @param filter filter parameters
 */
export async function getDealReport(filter: IReportFilter) : Promise<IDealResults> {

    const{utcStartDate,utcEndDate}  = getEndDateAndStartDateInUTC(filter.startDateTime,filter.endDateTime,filter.timeZone);
    return  await Parse.Cloud.run(CloudFunctions.FETCH_DEAL_REPORT, {
        ...filter,
        startDateTime: utcStartDate.toISOString(),
        endDateTime:   utcEndDate.toISOString(),
        pageSize:   filter.pageSize
    }) as IDealResults;
}
export async function getDealById(id: string): Promise<Deal> {
    const query = Parse.Query.or(
        (new Parse.Query(Deal)).equalTo("uid", `${id}`),
        (new Parse.Query(Deal)).equalTo("source.id", `${id}`),
        (new Parse.Query(Deal)).equalTo("objectId", `${id}`)
    );
    query.include(['pipelineStage','pipelineStage.pipeline.name']);
    const deal =  await query.first();
    if (deal){
        deal['dealContacts'] = await getDealContacts(deal);
    }
    return deal
}

export async function getDealContacts(deal: Deal): Promise<Contact[]>{
    const contactQuery = deal.relation('contacts');
    return contactQuery.query().find();
}