import {create} from 'zustand'
import { LayoutActionTypes } from './interfaces';
import {LayoutColor, LayoutType, LayoutWidth, SideBarTheme, SideBarWidth} from "appConstants";

export type LayoutActionType<TPayload> = {
    type:
        | LayoutActionTypes.CHANGE_LAYOUT
        | LayoutActionTypes.CHANGE_LAYOUT_COLOR
        | LayoutActionTypes.CHANGE_LAYOUT_WIDTH
        | LayoutActionTypes.CHANGE_SIDEBAR_THEME
        | LayoutActionTypes.CHANGE_SIDEBAR_TYPE
        | LayoutActionTypes.SHOW_RIGHT_SIDEBAR
        | LayoutActionTypes.HIDE_RIGHT_SIDEBAR;
    payload?: TPayload;
}

interface LayoutState {
    layoutType: LayoutType,
    layoutColor: LayoutColor,

    layoutWidth: LayoutWidth,
    leftSideBarTheme: SideBarTheme,
    leftSideBarType: SideBarWidth,
    rightSidebarVisible: boolean,
    changeLayout: (layout: LayoutType) => void
    changeLayoutColor: (color: LayoutColor) => void
    changeLayoutWidth: (width: LayoutWidth) => void
    changeSidebarTheme: (theme: SideBarTheme) => void
    changeSidebarType: (sideBarWidth: SideBarWidth) => void
    toggleRightSidebar: () => void
    showRightSidebar: () => void
    hideRightSidebar: () => void

}

export const useLayoutStore = create<LayoutState>((set, get) => ({
    layoutType: LayoutType.LAYOUT_VERTICAL,
    layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
    layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_DARK,
    leftSideBarType: SideBarWidth.LEFT_SIDEBAR_TYPE_FIXED,
    rightSidebarVisible: false,
    changeLayout: (layout: LayoutType) => set((state) => ({
        ...state,
        layoutType: layout
    })),
    changeLayoutColor: (color: LayoutColor) => set((state) => ({
        ...state,
        layoutColor: color
    })),
    changeLayoutWidth: (width: LayoutWidth) => set((state) => ({
        ...state,
        layoutWidth: width
    })),
    changeSidebarTheme: (theme: SideBarTheme) => set((state) => ({
        ...state,
        leftSideBarTheme: theme
    })),
    changeSidebarType: (sideBarWidth: SideBarWidth) => set((state) => ({
        ...state,
        leftSideBarType: sideBarWidth
    })),
    toggleRightSidebar: () => set((state) => ({
        ...state,
        rightSidebarVisible: !state.rightSidebarVisible
    })),
    showRightSidebar: () => set((state) => ({
        ...state,
        rightSidebarVisible: true
    })),
    hideRightSidebar: () => set((state) => ({
        ...state,
        rightSidebarVisible: false
    }))
}))
