import {
    StateEndDate,
    FranchiseeSelectOptions,
    IDate,
    IReportFilter,
    QuarterAndYear,
    ReactRoleSelectOptions,
    ReactSelectOptions,
    ReactUserSelectOptions,
    ReportViewTypes,
    StartAndEndDate,
    SortDirectionConfig,
    ISortable,
    SortDirection,
    IReactGAEvent
} from "./interfaces";
import {getCompanies} from "../hubspot/company/company.services";
import {FranchiseeMembership, getFranchisees} from "../hubspot/franchisee";
import {QUICK_DATES, QUICK_DATES_KEYS, UTC_OFFSET_RANGES} from "./constants";
import {getLifeCycleStages} from "../hubspot/lcs";
import {getUsers, IUserPermissions, Role, User} from "../auth";
import {getRoles} from "../auth/role/role.services";
import {getBusinessLocations} from "../customer-experience/business-location/business-location.services";
import {getSurveys} from "../customer-experience/survey/survey.services";
import {getContactSources} from "../hubspot";
import {ILocationSurveysData} from "../report/birdEye";
import {getTimeZoneDate, IChartDataWithLabels, IContactReportFilterParams, IReportTimeInterval} from "../report";
import date from "date-and-time";
import {getGoals} from "../auth/goal";
import {getAllGoalsYears} from "../setting/setting.services";
import {userHasAnyPermission} from "../auth/permission/helpers";
import {NavigateFunction} from "react-router-dom";
import {Location} from "history";
import {t} from "i18next";
import ReactGA from "react-ga4";


const Parse = require('parse/dist/parse');
const CryptoJS = require("crypto-js");


export function range(start: number, end: number) {
    const length = end - start + 1;
    return Array.from({length}, (_, i) => start + i);
}

export function getWeekNumber(date: Date): number {
    const firstJanuary = new Date(date.getFullYear(), 0, 1);
    // return Math.ceil((((date.getTime() - firstJanuary.getTime()) / 86400000) + firstJanuary.getDay() + 1) / 7);
    return Math.ceil((Math.floor((date.getTime() - firstJanuary.getTime()) / (24 * 60 * 60 * 1000)) / 7));
}

export function formatDate(date?: Date | string, format: string = 'MM/DD/YYYY'): string {
    // @ts-ignore
    return moment(new Date(date || '')).format(format);
}


export function shuffleWord(word: string) {
    return word.split('')
        .sort(() => 0.5 - Math.random())
        .join('');
}

export function getUsDateFormat(date: Date) {
    return new Intl.DateTimeFormat('en-US').format(date)
}

export function stripMillis(isoDateString: string): string {
    return isoDateString.split('.')[0];
}

export function getNumberFormat(number: number | bigint, locale: string = 'en-US', currency: string = 'USD', style: string = 'currency') {

    return new Intl.NumberFormat(locale, {
        maximumSignificantDigits: 2,
        style,
        currency
    }).format(number)
}

export function getDefaultDate(): IReportTimeInterval {
    const now = new Date();
    const year = now.getFullYear();
    return {
        start: date.format(new Date(year, 0, 1), 'YYYY-MM-DD'),
        end: date.format(new Date(year, 11, getDaysInMonth(year, (12))), 'YYYY-MM-DD'),
    }
}

/**
 * Returns the various parts of an isoDate
 * Year, Month, Day, Hour, Minute, Seconds, Milliseconds
 * @param isoDate
 */
export function previousPeriod(isoDate: string): string {
    return isoDate.split("-").map((datePart, index) => {
        if (index === 0) {
            datePart = (parseInt(datePart) - 1).toString();
        }
        return datePart;
    }).join("-")
}

export function getIsoDateParts(isoDate: string): IDate {
    let isoDateTokens = isoDate.split('T');
    const dateTokens = isoDateTokens[0].split('-').map((token) => parseInt(token));
    let timeTokens: number[] = [0, 0, 0, 0];
    //Get time tokens
    if (isoDateTokens[1]) {
        let _timeTokens = isoDateTokens[1].split(':');
        let _seconds = _timeTokens[2].replace('Z', '').split('.');
        timeTokens = [_timeTokens[0], _timeTokens[1], _seconds[0], _seconds[1] ?? '0']
            .map<number>((token) => parseInt(token));
    }
    return {
        year: dateTokens[0],
        month: dateTokens[1],
        date: dateTokens[2],
        hours: timeTokens[0],
        minutes: timeTokens[1],
        seconds: timeTokens[2],
        ms: timeTokens[3]
    }
}

/**
 * Gets the number fo days for a given month
 * @param year The year for which the month is calculated
 * @param month The month of interest - one base i.e. January = 1
 * @returns {int} The number of days for the given month
 */
export function getDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
}

export async function getCompanyOptions(inputValue: string) {
    return await getCompanies({keyword: inputValue}).then((response) => {
        return response?.results?.map((c) => ({value: c.uid, label: c.name})) ?? []
    });
}

export async function getFranchiseeOptions(inputValue: string): Promise<FranchiseeSelectOptions[]> {
    return await getFranchisees({keyword: inputValue}).then((response) => {
        return response?.results?.map((franchisee) => ({value: franchisee, label: franchisee.name}))
    });
}

export async function getGoalsOption(inputValue: string): Promise<ReactSelectOptions[]> {
    return await getGoals({keyword: inputValue, isFranchiseeGoals: true}).then((response) => {
        return response?.results?.map((goal) => ({value: goal.id, label: goal.name}))
    });
}

export async function getYearsOption(): Promise<ReactSelectOptions[]> {
    return await getAllGoalsYears().then((response) => {
        return response?.map((year) => ({value: year, label: year}))
    });
}

export function getQuickDateOptions(): ReactSelectOptions[] {
    return Object.keys(QUICK_DATES).map((key) => {
        return {
            value: key,
            label: QUICK_DATES[key]
        }
    }) as ReactSelectOptions[];
}

function getDateQuarterAndYear(currentDate: Date, lastQuarter?: boolean): QuarterAndYear {
    let year = currentDate.getFullYear();
    let quarter = Math.trunc(currentDate.getMonth() / 3) + 1;
    if (lastQuarter) {
        if (quarter === 1) {
            quarter = 4; // the last quarter of the previous year
            year = year - 1; // get the last year
        } else {
            quarter = quarter - 1;
        }
    }
    return {year, quarter}
}

function getQuarterDates(year: number, quarter: number): StartAndEndDate {
// Convert the parameters to numbers if they are not already
    year = Number(year);
    quarter = Number(quarter);
// Round down and get the absolute value of the parameters
    year = Math.floor(Math.abs(year));
    quarter = Math.floor(Math.abs(quarter));

// Calculate the start and end month of the quarter based on the quarter number
    let startMonth, endMonth;
    switch (quarter) {
        case 1: // First quarter: January to March
            startMonth = 0; // January is month 0 in JavaScript
            endMonth = 2; // March is month 2 in JavaScript
            break;
        case 2: // Second quarter: April to June
            startMonth = 3; // April is month 3 in JavaScript
            endMonth = 5; // June is month 5 in JavaScript
            break;
        case 3: // Third quarter: July to September
            startMonth = 6; // July is month 6 in JavaScript
            endMonth = 8; // September is month 8 in JavaScript
            break;
        case 4: // Fourth quarter: October to December
            startMonth = 9; // October is month 9 in JavaScript
            endMonth = 11; // December is month 11 in JavaScript
            break;
        default: // Invalid quarter number: limit it between 1 and 4
            quarter = Math.min(Math.max(quarter, 1), 4); // Use Math.min and Math.max to limit the value
            return getQuarterDates(year, quarter); // Recursively call the function with the limited value
    }
    // Return an array with the start and end date objects as elements
    return {
        startDateTime: date.format(new Date(year, startMonth, 1), 'YYYY-MM-DD'),
        endDateTime: date.format(new Date(year, endMonth + 1, 0), 'YYYY-MM-DD') // Use endMonth + 1 and day 0 to get the last day of the end month
    }
}

/**
 *
 * @param key QUICK_DATES_KEYS
 * @return StartAndEndDate
 */
export function getQuickDate(key: string): StartAndEndDate {
    const now = new Date();
    const currentYear = now.getFullYear();
    const month = now.getMonth();
    // Get the current day of the week (0 for Sunday, 6 for Saturday)
    let currentDay = now.getDay();
    currentDay = currentDay === 0 ? 6 : currentDay;
    if (key === QUICK_DATES_KEYS.LAST_WEEK) {
        return {
            startDateTime: date.format(new Date(currentYear, month, (now.getDate() - (7 + currentDay))), 'YYYY-MM-DD'),
            endDateTime: date.format(new Date(currentYear, month, (now.getDate() - (currentDay + 1))), 'YYYY-MM-DD')
        }
    } else if (key === QUICK_DATES_KEYS.THIS_MONTH) {
        return {
            startDateTime: date.format(new Date(currentYear, month, 1), 'YYYY-MM-DD'),
            endDateTime: date.format(new Date(currentYear, month, getDaysInMonth(currentYear, (month + 1))), 'YYYY-MM-DD')
        }
    } else if (key === QUICK_DATES_KEYS.LAST_MONTH) {
        const lastMonth = month - 1;
        return {
            startDateTime: date.format(new Date(currentYear, lastMonth, 1), 'YYYY-MM-DD'),
            endDateTime: date.format(new Date(currentYear, lastMonth, getDaysInMonth(currentYear, (lastMonth + 1))), 'YYYY-MM-DD')
        }
    } else if (key === QUICK_DATES_KEYS.THIS_QUARTER) {
        const {year, quarter} = getDateQuarterAndYear(now);
        return getQuarterDates(year, quarter);
    } else if (key === QUICK_DATES_KEYS.LAST_QUARTER) {
        const {year, quarter} = getDateQuarterAndYear(now, true);
        return getQuarterDates(year, quarter);
    } else if (key === QUICK_DATES_KEYS.LAST_30_DAYS) {
        return {
            startDateTime: date.format(date.addDays(now, -30), 'YYYY-MM-DD'),
            endDateTime: date.format(now, 'YYYY-MM-DD')
        }
    } else if (key === QUICK_DATES_KEYS.LAST_60_DAYS) {
        return {
            startDateTime: date.format(date.addDays(now, -60), 'YYYY-MM-DD'),
            endDateTime: date.format(now, 'YYYY-MM-DD')
        }
    } else if (key === QUICK_DATES_KEYS.LAST_YEAR) {
        return {
            startDateTime: date.format(new Date((currentYear - 1), 0, 1), 'YYYY-MM-DD'),
            endDateTime: date.format(new Date((currentYear - 1), 11, 31), 'YYYY-MM-DD')
        }
    } else if (key === QUICK_DATES_KEYS.THIS_WEEK) {
        return {
            startDateTime: date.format(new Date(currentYear, month, (now.getDate() - currentDay)), 'YYYY-MM-DD'),
            endDateTime: date.format(new Date(currentYear, month, (now.getDate() + (6 - currentDay))), 'YYYY-MM-DD')
        }

    } else {

        return {
            startDateTime: date.format(new Date(currentYear, 0, 1), 'YYYY-MM-DD'),
            endDateTime: date.format(new Date(currentYear, month, getDaysInMonth(currentYear, (month + 1))), 'YYYY-MM-DD')
        }
    }

}

export function getYearsSinceEpoch(): ReactSelectOptions[] {
    const allYears = [];
    let year = (new Date()).getFullYear(); //start with current Year
    while (1970 <= year) {
        allYears.push(year.toString());
        year -= 1;
    }
    return allYears.map((year) => ({value: year, label: year}))

}

export async function getSourceOptions(inputValue: string): Promise<ReactSelectOptions[]> {
    return await getContactSources({keyword: inputValue}).then((response) => {
        // @ts-ignore
        return response?.map((source) => ({value: source, label: source.toUpperCase()}));
    });
}

export async function getBusinessLocationsOptions(inputValue: string): Promise<ReactSelectOptions[]> {
    return await getBusinessLocations({keyword: inputValue}).then((response) => {
        return response?.results?.map((bl) => ({value: bl.uid, label: bl.name}))
    });
}

export async function getSurveyOptions(inputValue: string): Promise<ReactSelectOptions[]> {
    return await getSurveys({keyword: inputValue}).then((response) => {
        return response?.results?.map((bl) => ({value: bl.uid, label: bl.name}))
    });
}

export async function getSurveyQuestionsOptions(inputValue: string): Promise<ReactSelectOptions[]> {
    return await getSurveys({keyword: inputValue, pageSize: -1}).then((response) => {
        return response?.results.reduce<ReactSelectOptions[]>((acc, survey) => {
            survey.source.pages.forEach((page: any) => {
                page.questions.forEach((question: Record<string, any>) => {
                    if (acc.length) {
                        if (!(acc.some((rso) => rso.label === question.title))) {
                            acc.push({
                                label: question.title,
                                value: question.title
                            })
                        }
                    } else {
                        acc.push({
                            label: question.title,
                            value: question.title
                        })
                    }
                })

            })
            return acc;
        }, []);
    });

}

export async function getUserOptions(inputValue: string, roleName?: string): Promise<ReactUserSelectOptions[]> {
    return await getUsers({keyword: inputValue, pageSize: 100}, roleName).then((response) => {
        return response?.results?.map((user) => ({value: user, label: `${user.firstName} ${user.lastName}`})) ?? []
    });
}

export async function getRolesOptions(inputValue: string): Promise<ReactRoleSelectOptions[]> {
    return await getRoles({keyword: inputValue, pageSize: 100}).then((response) => {
        return response?.results?.map((role) => ({value: role, label: role.name})) ?? []
    });
}

export async function getLCSOptions(inputValue: string) {
    return await getLifeCycleStages({keyword: inputValue}).then((response) => {
        return response?.results?.map((lcs) => ({value: lcs.uid, label: lcs.name})) ?? []
    });
}

export function getTimezoneOptions() {
    return Object.keys(UTC_OFFSET_RANGES)
        .map((offset) => parseFloat(offset))
        .sort((a, b) => ((a < b) ? -1 : (a > b) ? 1 : 0))
        .map((offset) => ({value: offset, label: UTC_OFFSET_RANGES[`${offset}`]}))
}

export function showLogo(userAndPermission: IUserPermissions): boolean {
    const userRoles = userAndPermission.user?.get('roles') ?? [];
    if (userAndPermission.permissions) {
        return userHasAnyPermission(userAndPermission, [
            {module: 'core:role', key: 'manage'}, {module: 'core:permission', key: 'manage'}
        ]);
    }
    return !!(
        userRoles?.some((role: Parse.Role | Role) => {
            const roleName = role.getName()?.toLowerCase() ?? '';
            return roleName.includes('administrator') || roleName.includes('coach');
        })
    );
}

export function uniqueFilter(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
}

// Define a function to compare two strings by their substrings after the first space
export function compareBySubstr(str1: string, str2: string) {
// Split the strings by space and get the second element
    let substr1 = str1.split(" ")[1];
    let substr2 = str2.split(" ")[1];
// Compare the substrings lexicographically in descending order
    if (substr1 > substr2) {
        return -1;
    } else if (substr1 < substr2) {
        return 1;
    } else {
        return 0;
    }
}

export function compareSurveyByS(a: string, b: string) {
    const regex = /S(\d+)/;
    let A = a.match(regex);
    let B = b.match(regex);
    const numA = parseInt(A ? A[1] : (B ? (B[1] + 1) : '0'));
    const numB = parseInt(B ? B[1] : (A ? (A[1] + 1) : '0'));
    // Compare the numbers and return the result
    return numA - numB;
}

function generateRandomColor() {
    // A string of possible characters for the color
    let chars = "0123456789ABCDEF";
    // A variable to store the color
    let color = "#";
    for (let i = 0; i < 6; i++) {
        // A random index from 0 to 15
        let index = Math.floor(Math.random() * 16);
        // A random character from the chars string
        let char = chars[index];
        // Append the character to the color
        color += char;
    }
    // Return the color
    return color;
}

export function generateColors(numberOfColorsNeeded: number, colors: string[]): string[] {
    // A copy of the colors array to avoid modifying the original one
    let newColors = [...colors];
    // A loop that runs until the newColors array has the desired length
    while (newColors.length < numberOfColorsNeeded) {
        // Generate a random color
        let color = generateRandomColor();
        // Check if the color is already in the newColors array
        let isDuplicate = newColors.includes(color);
        // If not, add it to the newColors array
        if (!isDuplicate) {
            newColors.push(color);
        }
    }
    return newColors;
}


/**
 *
 * @param cookieName
 * @param value
 * @param expireIn how many minutes the cookie should last
 */
export function setCookieExpireIn(cookieName: string, value: string, expireIn: number) {
    // get current date and time
    const now = new Date();
    now.setTime(now.getTime() + (expireIn * 60 * 1000)); // calculate the expiration time
    document.cookie = cookieName + "=" + value + "; expires=" + now.toUTCString() + ";"; // create the cookie with the given name, value and expiration time
}

// Define a function to encrypt a string
export function encryptString(stringToEncrypt: string) {
// Encrypt the string with the secret passphrase
    const encrypted = CryptoJS.AES.encrypt(stringToEncrypt, process.env.REACT_APP_CRYPTOJS_SECRET);
// Return the encrypted string
    return encrypted.toString();
}

// Define a function to decrypt a string
export function decryptString(stringToDecrypt: string) {
// Decrypt the string with the secret passphrase
    const decrypted = CryptoJS.AES.decrypt(stringToDecrypt, process.env.REACT_APP_CRYPTOJS_SECRET);
// Return the decrypted string
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function getCookieValue(cookieName: string) {
    const cookieString = document.cookie; // get the cookie string
    const cookieArray = cookieString.split(";"); // split it into individual cookies
    for (let i = 0; i < cookieArray.length; i++) { // loop through the cookies
        const cookiePair = cookieArray[i].split("="); // split the name and value
        if (cookiePair[0].trim() === cookieName) { // if the name matches
            return cookiePair[1]; // return the value
        }
    }
    return null; // if no cookie found, return null
}

export function deleteCookie(cookieName: string) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/account"; // create a cookie with an empty value and a past expiration date
}

export function getOverallScoreLabel(overAllScore: ILocationSurveysData) {
    const labels: string[] = Object.entries(overAllScore).reduce<string[]>((acc, [key, locationSurveys]) => {
        acc = [...acc, ...(Object.keys(locationSurveys.surveys))];
        return acc
    }, []);
    // return labels.filter(uniqueFilter).sort(compareBySubstr);
    return labels.filter(uniqueFilter).sort(compareSurveyByS);
}

export function getLocationReportIntervalTotals(report: ILocationSurveysData): IChartDataWithLabels[] {

    return Object.entries(report).reduce<IChartDataWithLabels[]>((acc, [location, values]) => {
        const labelData: IChartDataWithLabels = {name: location, data: []}
        const surveyLabel = getOverallScoreLabel(report);
        /**
         * Populate data[] with average of each survey response interval overall score.
         * We choose to implement it this way because a survey can have more than one response for the same interval
         */
        surveyLabel.forEach((key) => {
            const intervalCumulative = values.surveys[key]?.intervals ? Object.values(values.surveys[key]?.intervals).reduce((acc, val) => acc + val, 0) : 0;
            if (intervalCumulative) {
                labelData.data.push(parseFloat((intervalCumulative / values.surveys[key]?.total ?? 1).toFixed(1)));
            } else {
                labelData.data.push(0);
            }
        })
        acc.push(labelData)
        return acc;
    }, []);
}

export function removePageQueryParam(location: Location, navigate: NavigateFunction) {
    //Remove page if filter is applied
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('page')) {
        queryParams.delete('page');
        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        });
    }
}

export function getPreviousYearDate(isoDateString: string) {
    const date = getTimeZoneDate(isoDateString);
    const year = date.getFullYear();
    date.setFullYear(year - 1);
    return date.toISOString();
}

export function getPreviousYear(isoDateString: string) {
    // Create a new date object from the date string
    let date = new Date(isoDateString);
    let year = date.getFullYear();
    date.setFullYear(year - 1);
    return date.toISOString().slice(0, 10);// Slice the string to get only the date part
}

export function getTimeZoneDeviationInMinutes(isoDateString: string): number {
    return new Date(isoDateString).getTimezoneOffset();
}

export function getComparisonStateEndDate(filter: IReportFilter): StateEndDate | undefined {
    if (filter.compareData) {
        return {
            startDate: date.format(date.addYears(new Date(filter.startDateTime), -1), 'YYYY-MM-DD'),
            endDate: date.format(date.addYears(new Date(filter.endDateTime), -1), 'YYYY-MM-DD')
        }
    }
    return undefined
}

export const showFranchiseeFilter = (franchiseeMemberships: FranchiseeMembership[] | undefined, userAndPermission: IUserPermissions) => {
    const userRoles = userAndPermission.user?.get('roles') ?? [];
    if (isSuperAdmin(userAndPermission)) {
        return true
    } else if (userRoles?.some((role: Parse.Role | Role) => {
        const roleName = role.getName()?.toLowerCase() ?? '';
        return roleName.includes('administrator');
    })) {
        return true;
    }
    return (franchiseeMemberships?.length ?? 0) > 1;
}
export const showSalesGoalsGraph = (franchiseeMemberships: FranchiseeMembership[] | undefined, userAndPermission: IUserPermissions): Boolean => {
    const userRoles = userAndPermission.user?.get('roles') ?? [];
    if (isSuperAdmin(userAndPermission)) {
        return false
    } else if (userRoles?.some((role: Parse.Role | Role) => {
        const roleName = role.getName()?.toLowerCase() ?? '';
        return roleName.includes('administrator');
    })) {
        return false;
    }
    return (franchiseeMemberships?.length ?? 0) === 1;
}

export function isSuperAdmin(userAndPermission: IUserPermissions): boolean {
    return userHasAnyPermission(userAndPermission, [
        {module: 'core:role', key: 'manage'}, {module: 'core:permission', key: 'manage'}
    ]);
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Function returns the report period
 * @param filter IReportFilter
 * @return  yearly | weekly |monthly | daily
 */
export function getReportPeriod(filter: IReportFilter | IContactReportFilterParams): string {
    if ("reportViewType" in filter) {
        if (
            filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_YEARLY ||
            filter.reportViewType === ReportViewTypes.AGGREGATE_SALES_YEARLY
        ) {
            return 'yearly'
        } else if (
            filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_MONTHLY ||
            filter.reportViewType === ReportViewTypes.AGGREGATE_SALES_MONTHLY
        ) {
            return 'monthly'
        } else if (
            filter.reportViewType === ReportViewTypes.AGGREGATE_SALES_WEEKLY ||
            filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_WEEKLY
        ) {
            return 'weekly'
        } else {
            return getPeriodFromDateDiff(filter.startDateTime, filter.endDateTime);
        }

    } else {
        return getPeriodFromDateDiff(filter.startDateTime, filter.endDateTime);
    }
}

export function getPeriodFromDateDiff(startDateTime: string, endDateTime: string): string {
    // @ts-ignore
    const _endDate = moment(endDateTime);
    // @ts-ignore
    const _startDate = moment(startDateTime);
    const daysDifference = _endDate.diff(_startDate, 'days');

    if (daysDifference > 365) {
        return 'yearly'  //ContactReportViewType.AGGREGATE_SOURCE_YEARLY
    } else if (daysDifference <= 7) {
        return 'daily'
    } else if (daysDifference <= 31) {
        return 'weekly' //ContactReportViewType.AGGREGATE_SOURCE_WEEKLY
    } else {
        return 'monthly' //ContactReportViewType.AGGREGATE_SOURCE_MONTHLY
    }
}

export function getXAxisTitle(filter: IReportFilter | IContactReportFilterParams): string {
    const reportPeriod = getReportPeriod(filter);
    let period = "";
    switch (reportPeriod) {
        case "yearly":
            period = t("common.years");
            break;
        case "monthly":
            period = t("common.months");
            break;
        case "weekly":
            period = t("common.weeks");
            break;
        default:
            period = t("common.days");
            break;
    }
    return period;
}

export function sortObjectsByKey<T extends ISortable>(array: T[], sortConfig: SortDirectionConfig) {
    return array.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
        // Check if the values are numbers
        if (!isNaN(aValue) && !isNaN(bValue)) {
            aValue = parseFloat(aValue);
            bValue = parseFloat(bValue);
        }
        if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });
}
export function getSortDirection(sortConfig: SortDirectionConfig,columnKey: string) {
    let direction:SortDirection  = 'descending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'descending') {
        direction = 'ascending';
    } else {
        direction = 'descending';
    }
    return direction

}
export function getArrowDirection (sortConfig: SortDirectionConfig,columnKey: string) {
    if (sortConfig.key === columnKey) {
        return sortConfig.direction;
    }
    return undefined;
}
export function handleReactGAEvent  (options: IReactGAEvent){
    //Use https://www.npmjs.com/package/react-ga4 for reference
    ReactGA.event({
        category: options.category,
        action: options.action,
        label: options.label
    });
}