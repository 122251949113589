import { t } from 'i18next';
const Parse = require('parse/dist/parse');

/**
 * A generalized wrapper class for handling all exceptions
 */
type TExceptionOptions = {
  message?: string,
  code: number,
  source?: any
}
export class CException extends Parse.Error{
  source?: any
  constructor(options?: TExceptionOptions) {
    super((options?.code || Parse.Error.OTHER_CAUSE), options?.message || t('common.unknown'))
    this.source = options?.source;
  }
}
