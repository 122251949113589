import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {ITerritoryResults} from "./territory.interfaces";
import {Territory} from "./territory.models";
const Parse = require('parse/dist/parse');


/**
 * Fetches a paginated list of territories
 * @param filter filter parameter of type [IFetchFilter]
 */
export async function getTerritories(filter?: IFetchFilter) : Promise<ITerritoryResults>{

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(Territory).matches('uid', keyword, 'i'),
            new Parse.Query(Territory).matches('name', keyword, 'i'),
            new Parse.Query(Territory).matches('description', keyword, 'i'),
            new Parse.Query(Territory).matches('zipCodes', keyword, 'i'),
            new Parse.Query(Territory).matches('cities', keyword, 'i'),
            new Parse.Query(Territory).matches('counties', keyword, 'i'),
            new Parse.Query(Territory).matches('states', keyword, 'i'),
            new Parse.Query(Territory).matches('country', keyword, 'i'),
        ) :
        new Parse.Query(Territory);

    query.skip(skip);
    query.limit(limit);

    if (filter?.sorts){
        filter.sorts.forEach(({key, order}) => {
            if (order === 'ascending'){
                query.ascending(key);
            } else {
                query.descending(key);
            }
        });
    }

    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return { results: await query.find() }
    }
}