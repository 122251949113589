import {Franchisee} from "../franchisee.models";
import {User} from "../../../auth";
const Parse = require('parse/dist/parse');

export class FranchiseeMembership extends Parse.Object {
    constructor() {
        super('FranchiseeMembership');
    }
    get type(): 'member'|'coach' {
        return this.get('type');
    }
    set type(value) {
        this.set('type', value);
    }
    get franchisee(): Franchisee {
        return this.get('franchisee');
    }
    set franchisee(value) {
        this.set('franchisee', value);
    }
    get user(): User {
        return this.get('user');
    }
    set user(value) {
        this.set('user', value);
    }
    get meta(): Parse.Object {
        return this.get('meta');
    }
    set meta(value) {
        this.set('meta', value);
    }

}
Parse.Object.registerSubclass('FranchiseeMembership', FranchiseeMembership);
