import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
    NAME_MAX_LENGTH,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    PASSWORD_REGEX,
    useTogglePassword,
    useAlerts, encryptString, decryptString, sleep
} from "../../common";
import {
    IChangePasswordFormCallback,
    IChangePasswordFormData,
    IForgotPasswordFormCallback, IForgotPasswordFormData,
    ILoginFormCallback,
    ILoginFormData,
    IRegistrationFormCallback,
    IRegistrationFormData
} from "./user.interfaces";
import { useNavigate } from "react-router-dom";
import {useAuthStore} from "../../state/zustand";
import { requestPasswordReset, sendLoginVerificationCode} from "./user.services";
import {useEffect, useState} from "react";
import {User} from "./user.models";
import {COOKIE_KEYS} from "./user.constants";
import Cookies from "js-cookie";
const Parse = require('parse/dist/parse');


export function useUserRegistrationForm(params?: IRegistrationFormCallback) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // We are using signUp function from the zustand state library instead of signUp function
    // in auth.service. This gives us the added advantage of updating the application auth state
    const signUp = useAuthStore((state) => state.signUp);

    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        firstName: yup.string().trim()
            .required(t('signup.form.firstName.errors.required'))
            .max(NAME_MAX_LENGTH, t('signup.form.firstName.errors.max', {max: NAME_MAX_LENGTH})),
        lastName: yup.string().trim()
            .required(t('signup.form.lastName.errors.required'))
            .max(NAME_MAX_LENGTH, t('signup.form.lastName.errors.max', {max: NAME_MAX_LENGTH})),
        email: yup.string().trim()
            .required(t('signup.form.email.errors.required'))
            .email(t('signup.form.email.errors.email')),
        password: yup.string().trim()
            .required(t('signup.form.password.errors.required'))
            .min(PASSWORD_MIN_LENGTH, t('signup.form.password.errors.min', {min: PASSWORD_MIN_LENGTH}))
            .max(PASSWORD_MAX_LENGTH, t('signup.form.password.errors.max', {max: PASSWORD_MAX_LENGTH}))
            .matches(PASSWORD_REGEX, t('signup.form.password.errors.regex')),
        acceptTerms: yup.boolean()
            .required(t('signup.form.acceptTerms.errors.required'))
            .isTrue(t('signup.form.acceptTerms.errors.required'))
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<IRegistrationFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: IRegistrationFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }
        // Set the submitCallback to signUp service function if one was not set
        const submitCallback = params?.submitCallback ?? signUp;
        // Call submit callback function if one was provided.
        await submitCallback?.(data).catch((error) => {
            //Todo: Process errors here.
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.USERNAME_TAKEN:
                        setError('email', { message: t('signup.form.username.errors.taken') })
                        break;
                    case Parse.Error.EMAIL_TAKEN:
                        setError('email', { message: t('signup.form.email.errors.taken') })
                        break;
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        });

        // Redirect to the provided path if one was provided.
        if (params?.redirect){ navigate(params?.redirect); }
    };

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        onSubmit: handleSubmit(onSubmit)
    }
}

export function useUserLoginForm(params?: ILoginFormCallback) {
    const { t } = useTranslation();
    //todo comment the line below when sendgrid maximum quota is resolved
    const navigate = useNavigate();
    const [showLoginForm,setShowLoginForm] = useState<boolean>(true);

    // We are using login function from the zustand state library instead of signUp function
    // in auth.service. This gives us the added advantage of updating the application auth state
    //todo comment the line below when sendgrid maximum quota is resolved
    const logIn = useAuthStore((state) => state.logIn);

    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        username: yup.string().trim()
            .required(t('signin.form.username.errors.required')),
        password: yup.string().trim()
            .required(t('signin.form.password.errors.required'))
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<ILoginFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: ILoginFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }
        // Set the submitCallback to signUp service function if one was not set
        let submitCallback = params?.submitCallback ?? logIn;
        // let submitCallback = params?.submitCallback ?? sendLoginVerificationCode;
        if (process.env.NODE_ENV === 'production'){
            // @ts-ignore
            submitCallback = params?.submitCallback ?? sendLoginVerificationCode;
        }
        // Call submit callback function if one was provided.
        await submitCallback?.(data).then(()=>{
            reset();
            if (process.env.NODE_ENV === 'production'){
                const encryptedUserData = encryptString(JSON.stringify(data));
                Cookies.set(COOKIE_KEYS.AUTH_USER_kEY,encryptedUserData)
                // Hide login form and show verification form
                setShowLoginForm(false);
            }else if (params?.redirect){
                // Redirect to the provided path if one was provided.
                navigate(params?.redirect);
            }
        }).catch((error) => {
            //Todo: Process errors here.
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.OBJECT_NOT_FOUND:
                        setAlert({message: t('signin.form.submit.errors.invalidCredentials'), variant: "danger"});
                        break;
                    case Parse.Error.EMAIL_NOT_FOUND:
                        setError('username', { message: t('signin.form.username.errors.notFoundOrVerified') })
                        break;
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        });
    };

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        showLoginForm,
        setShowLoginForm,
        onSubmit: handleSubmit(onSubmit)
    }
}
export function useUserVerificationCodeForm(params?: ILoginFormCallback) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Message utility helpers
    const { alert, setAlert } = useAlerts();
    // We are using login function from the zustand state library instead of signUp function
    // in auth.service. This gives us the added advantage of updating the application auth state
    const logIn = useAuthStore((state) => state.logIn);

    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        verificationCode: yup.string()
            .required(t('signin.form.verificationCode.errors.required'))
            .max(6, t('signin.form.verificationCode.errors.max', {max: 6}))
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<{verificationCode: string|number}>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: {verificationCode: string|number}) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }
        // Set the submitCallback to signUp service function if one was not set
        const submitCallback = params?.submitCallback ?? logIn;

        const rpUserString = Cookies.get(COOKIE_KEYS.AUTH_USER_kEY);
        //getCookieValue("rpUser");
        if (rpUserString){
            const rpUser = JSON.parse( decryptString(rpUserString)) as ILoginFormData;
            // Call submit callback function if one was provided.
            await submitCallback?.({username: rpUser.username, password:rpUser.password,verificationCode:data.verificationCode}).catch((error) => {
                //Todo: Process errors here.
                if (error instanceof Parse.Error){
                    switch (error.code) {
                        case Parse.Error.OBJECT_NOT_FOUND:
                            setAlert({message: t('signin.form.submit.errors.invalidCredentials'), variant: "danger"});
                            break;
                        case Parse.Error.CONNECTION_FAILED:
                            setAlert({message: t('common.errors.connection'), variant: "danger"});
                            break;
                        case Parse.Error.INTERNAL_SERVER_ERROR:
                            setAlert({message: t('common.errors.server'), variant: "danger"});
                            break;
                        default:
                            setAlert({message: t(error.message), variant: "danger"});
                            break;
                    }
                } else {
                    setAlert({message: t(error.message), variant: "danger"});
                }
            });
            reset();
            // Redirect to the provided path if one was provided.
            if (params?.redirect){
                navigate(params?.redirect);
            }

        }


    };


    return {
        registerVerification: register,
        reset,
        getValues,
        errorsVerification: errors,
        isSubmittingVerification: isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alertVerification :alert,
        setAlertVerification:setAlert,
        onSubmitVerification: handleSubmit(onSubmit)
    }
}

export function useForgotPasswordForm(params?: IForgotPasswordFormCallback) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        email: yup.string().trim()
            .required(t('forgotPassword.form.email.errors.required'))
            .email(t('forgotPassword.form.email.errors.email')),
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<IForgotPasswordFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: IForgotPasswordFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }
        // Set the submitCallback to signUp service function if one was not set
        const submitCallback = params?.submitCallback ?? requestPasswordReset;
        // Call submit callback function if one was provided.
        await submitCallback?.(data).then(() => {
            setAlert({message: t('forgotPassword.form.submit.success'), variant: "success"});
        }).catch((error) => {
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    // case Parse.Error.OTHER_CAUSE:
                    //     setAlert({message: t('common.errors.unknown'), variant: "danger"});
                    //     break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        });

        // Redirect to the provided path if one was provided.
        if (params?.redirect){ navigate(params?.redirect); }
    };

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        onSubmit: handleSubmit(onSubmit)
    }
}

export function useLogout() {
    const { t } = useTranslation();
    const logOut = useAuthStore((state) => (state.logOut));

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    useEffect(() => {
        logOut().catch((error) => {
            setAlert({message: `${t('common.errors.unknown')} :: ${error.message}`, variant: "danger"});
        });
    }, [logOut]);

    return {
        alert, setAlert
    }
}
export function useChangePasswordForm(params?: IChangePasswordFormCallback) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // We are using login function from the zustand state library instead of signUp function
    // in auth.service. This gives us the added advantage of updating the application auth state
    //todo comment the line below when sendgrid maximum quota is resolved
    const {changePassword, logOut,currentUser} = useAuthStore((state) => (
        {
            currentUser: state.currentUser,
            changePassword: state.changePassword,
            logOut: state.logOut
        }));

    // Toggle password visibility on and off
    const {hidePassword, togglePasswordVisibility} = useTogglePassword();
    // Declare validation schema for user registration form
    const schema = yup.object().shape({
        currentPassword: yup.string().trim()
            .required(t('myAccount.changePassword.form.currentPassword.errors.required')),
        newPassword: yup.string().trim()
            .required(t('myAccount.changePassword.form.newPassword.errors.required'))
            .min(PASSWORD_MIN_LENGTH, t('myAccount.changePassword.form.newPassword.errors.min', {min: PASSWORD_MIN_LENGTH}))
            .max(PASSWORD_MAX_LENGTH, t('myAccount.changePassword.form.newPassword.errors.max', {max: PASSWORD_MAX_LENGTH}))
            .matches(PASSWORD_REGEX, t('myAccount.changePassword.form.newPassword.errors.regex')),
        confirmPassword: yup.string()
            .required(t('myAccount.changePassword.form.confirmPassword.errors.required'))
            .oneOf([yup.ref('newPassword')], t('myAccount.changePassword.form.confirmPassword.errors.match'))

    })

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setError,
        formState: { errors, isSubmitting, isValid, isSubmitted }
    } = useForm<IChangePasswordFormData>({
        resolver: yupResolver(schema),
        defaultValues: schema.cast({})
    });

    const onSubmit = async (data: IChangePasswordFormData) => {
        // Clear previous alert message if any
        if (alert?.message){ setAlert(null); }
        try{
            // Call submit callback function if one was provided.
            await changePassword(currentUser as User,data);
            reset();
            setAlert({message: t('myAccount.changePassword.form.success.passwordChanged'), variant: "success"});
            await sleep(4500);
            await logOut();

        }catch (error: any) {
            console.log(error)
            //Todo: Process errors here.
            if (error instanceof Parse.Error){
                switch (error.code) {
                    case Parse.Error.OBJECT_NOT_FOUND:
                        setAlert({message: (!error.message.includes("username/password") ? error.message :
                                t('myAccount.changePassword.form.errors.incorrectCurrentPassword') ), variant: "danger"});
                        break;
                    case Parse.Error.CONNECTION_FAILED:
                        setAlert({message: t('common.errors.connection'), variant: "danger"});
                        break;
                    case Parse.Error.INTERNAL_SERVER_ERROR:
                        setAlert({message: t('common.errors.server'), variant: "danger"});
                        break;
                    default:
                        setAlert({message: t(error.message), variant: "danger"});
                        break;
                }
            } else {
                setAlert({message: t(error.message), variant: "danger"});
            }
        }
    };

    // Message utility helpers
    const { alert, setAlert } = useAlerts();

    return {
        hidePassword,
        togglePasswordVisibility,
        register,
        reset,
        getValues,
        errors,
        isSubmitting,
        isSubmitted,
        setError,
        isValid,
        alert, setAlert,
        onSubmit: handleSubmit(onSubmit)
    }
}