import {
    DATE_RANGE_SEPARATOR,
    getReportSubTimeIntervals, getTimeZoneDate, IChartDataWithLabels, IContactReportResult, IReportIntervalsData
} from "../contact";
import {
    IIntervalWithAverageData,
    ILocationSurveysData,
    IResponseAnswer, IResponseAnswerWithChildAnswers,
    ISurveyReportFilterParams,
    ISurveyReportResult, ISurveyResponse, ISurveySent, trackerQuestions
} from "./biredEye.report.interface";
import {SurveyResponse} from "../../customer-experience/survey-response";
import {DealStage} from "../../hubspot/deal-stage";
import {Contact, Franchisee} from "../../hubspot";


/**
 *
 * @param filter
 * @param surveyResponses
 * @param useUTC
 */
export function getSurveyResponseIntervalReport(filter: ISurveyReportFilterParams, surveyResponses: SurveyResponse[] | undefined, useUTC: boolean = true): ISurveyResponse {

    let dataset: ISurveyResponse = {};
    if (surveyResponses) {
        const timeIntervals = getReportSubTimeIntervals(filter,useUTC);
        for (const surveyResponse of surveyResponses) {
            for (const interval of timeIntervals) {
                const key = `${interval.start}${DATE_RANGE_SEPARATOR}${interval.end}`;
                // Initialize the entries for the current key if not already.
                dataset[key] ??= [];
                const sentinelDate = surveyResponse.requestDate; //Todo this may be replaced with responseDate in the future
                if ((interval.start <= sentinelDate) && (sentinelDate <= interval.end)) {
                    // Add new snapshot to existing list for key
                    dataset[key] = [...dataset[key], surveyResponse];
                }
            }
        }
    }
    dataset = Object.entries(dataset).reduce<ISurveyResponse>((acc, [key, values]) => {
        const intervals = key.split(DATE_RANGE_SEPARATOR)
        const startInterval = getTimeZoneDate(intervals[0], filter.timeZone).toISOString();
        const endInterval = getTimeZoneDate(intervals[1], filter.timeZone).toISOString();
        acc[(`${startInterval}${DATE_RANGE_SEPARATOR}${endInterval}`)] = values;

        return acc;
    }, {});
    return dataset;
}
export function getSurveySentIntervalReport(filter: ISurveyReportFilterParams, surveyResponses: DealStage[] | undefined, useUTC: boolean = true): ISurveySent {

    let dataset: ISurveySent = {};
    if (surveyResponses) {
        const timeIntervals = getReportSubTimeIntervals(filter,useUTC);
        for (const surveyResponse of surveyResponses) {
            for (const interval of timeIntervals) {
                const key = `${interval.start}${DATE_RANGE_SEPARATOR}${interval.end}`;
                // Initialize the entries for the current key if not already.
                dataset[key] ??= [];
                const sentinelDate = surveyResponse.dateEntered; //Todo this may be replaced with responseDate in the future
                if ((interval.start <= sentinelDate) && (sentinelDate <= interval.end)) {
                    // Add new snapshot to existing list for key
                    dataset[key] = [...dataset[key], surveyResponse];
                }
            }
        }
    }
    dataset = Object.entries(dataset).reduce<ISurveySent>((acc, [key, values]) => {
        const intervals = key.split(DATE_RANGE_SEPARATOR)
        const startInterval = getTimeZoneDate(intervals[0], filter.timeZone).toISOString();
        const endInterval = getTimeZoneDate(intervals[1], filter.timeZone).toISOString();
        acc[(`${startInterval}${DATE_RANGE_SEPARATOR}${endInterval}`)] = values;

        return acc;
    }, {});
    return dataset;
}

export function getSurveyCompletionRate(data: ISurveyReportResult): IReportIntervalsData {
    let surveyData = Object.entries(data.surveyResponses).reduce((acc: any, [interval, listOfSurveyResponseSnapshot]) => {
        const completedKey = "Complete";
        const responseKey = "Response";
        acc[responseKey] ??= {
            intervals: {},
            total: 0
        }
        setReportIntervalData(acc,responseKey,interval,listOfSurveyResponseSnapshot.length);
        // if (listOfSurveyResponseSnapshot.length) {
        //     listOfSurveyResponseSnapshot.forEach((surveyResponse: SurveyResponse) => {
        //         if (surveyResponse.source.completed){
        //             //get completed data
        //             acc[completedKey] ??= {
        //                 intervals: {},
        //                 total: 0
        //             }
        //             setReportIntervalData(acc,completedKey,interval);
        //         }
        //
        //     });
        // }
        return acc;
    }, {}) as IReportIntervalsData;
    //  surveyData = Object.entries(data.surveySent).reduce((acc: any, [interval, listOfSurveySentSnapshot]) => {
    //
    //      const sentKey = "Sent";
    //      acc[sentKey] ??= {
    //          intervals: {},
    //          total: 0
    //      }
    //      setReportIntervalData(acc,sentKey,interval,listOfSurveySentSnapshot.length);
    //     return acc;
    // }, surveyData) as IReportIntervalsData;
    //sort by total in ascending
    return  Object.fromEntries(
        Object.entries(surveyData).sort((a, b) =>a[1]['total'] - b[1]['total']  )
    )  as IReportIntervalsData;
}

export function getSurveyOverallScoreReport(data: ISurveyResponse): ILocationSurveysData {
    const intervalsWithNoSurveyResponse: string[] = [];
    const sourceData =  Object.entries(data).reduce((acc: any, [interval, listOfSurveyResponseSnapshot]) => {
        if (listOfSurveyResponseSnapshot.length) {
            listOfSurveyResponseSnapshot.forEach((surveyResponse) => {
                const locationCity = surveyResponse.source.businessLocationName ?? surveyResponse.source.locationName;
                const survey = surveyResponse.source.surveyName ?? surveyResponse.survey.name;
                setLocationSurveyData(acc,locationCity,survey,interval,parseFloat(surveyResponse.source.overallScore ?? '0').toFixed(1));
            });
        }

        //the next two conditions are used to add 0 to sources for intervals that do not have contacts
        else if (Object.keys(acc).length) {
            setLocationSurveyIntervalWithNoResponse(acc,interval)
        }else{
            intervalsWithNoSurveyResponse.push(interval)
        }
        return acc;
    }, {}) as ILocationSurveysData;
    if (intervalsWithNoSurveyResponse.length){
        intervalsWithNoSurveyResponse.forEach((interval)=>{
            setLocationSurveyIntervalWithNoResponse(sourceData,interval)
        });
    }
    //sort by total in ascending
    return  Object.fromEntries(
        Object.entries(sourceData).sort((a, b) =>a[1]['total'] - b[1]['total']  )
    )  as ILocationSurveysData;
}
export function getHowYouFeelTrackerReport(data: ISurveyResponse): ILocationSurveysData {
    const intervalsWithNoSurveyResponse: string[] = [];
    const sourceData =  Object.entries(data).reduce((acc: any, [interval, listOfSurveyResponseSnapshot]) => {
        if (listOfSurveyResponseSnapshot.length) {
            listOfSurveyResponseSnapshot.forEach((surveyResponse) => {
                const locationCity = surveyResponse.source.businessLocationName ?? surveyResponse.source.locationName;
                const survey = surveyResponse.source.surveyName ?? surveyResponse.survey.name;
                const responseAnswers: IResponseAnswerWithChildAnswers[] = surveyResponse.source.answers as IResponseAnswer[];
                for ( const responseAnswer of responseAnswers) {
                    if ( (responseAnswer.questionTitle === trackerQuestions.HOW_DO_YOU_FEEL) || (responseAnswer.questionTitle === trackerQuestions.RECOMMEND_US)){ // questions on 10
                        const defaultScore = 10;
                        if (responseAnswer.childAnswers){
                            let sumOfAnswers = 0;
                            for ( const childAnswer of responseAnswer.childAnswers ) {
                                sumOfAnswers +=  ( ( parseInt(childAnswer.answer ?? '0') / childAnswer.maxValue )* defaultScore);
                            }
                            const average = (sumOfAnswers / responseAnswer.childAnswers.length);
                            setLocationSurveyData(acc,locationCity,survey,interval, average.toFixed(1));
                        }else{
                            const score = ( ( parseInt(responseAnswer.answer ?? '0') / responseAnswer.maxValue )* defaultScore);
                            setLocationSurveyData(acc,locationCity,survey,interval,score.toFixed(1));
                        }
                    }
                }
            });
        }

        //the next two conditions are used to add 0 to sources for intervals that do not have contacts
        else if (Object.keys(acc).length) {
            setLocationSurveyIntervalWithNoResponse(acc,interval)
        }else{
            intervalsWithNoSurveyResponse.push(interval)
        }
        return acc;
    }, {}) as ILocationSurveysData;
    if (intervalsWithNoSurveyResponse.length){
        intervalsWithNoSurveyResponse.forEach((interval)=>{
            setLocationSurveyIntervalWithNoResponse(sourceData,interval)
        });
    }
    //sort by total in ascending
    return  Object.fromEntries(
        Object.entries(sourceData).sort((a, b) =>a[1]['total'] - b[1]['total']  )
    )  as ILocationSurveysData;
}
export function getAverageChartData(reports: IIntervalWithAverageData): IChartDataWithLabels[] {
    let obj: IChartDataWithLabels = {
        name: 'Average',
        data: []
    };
    obj.data = Object.values(reports).map((val) => {
        return val['average'];
    });
    return  [obj]

}
export function filterContactReportResultByFranchisee(data: IContactReportResult,franchisee: Franchisee) {
        for (const salesKey in data) {
            data[salesKey] = data[salesKey]?.filter((contact)=>{
                return contactHasFranchisee(contact, franchisee);
            })
        }
}
export function filterSurveyReportResultByFranchisee(data: ISurveyResponse,franchisee: Franchisee) {
    for (const dataKey in data) {
        data[dataKey] =  data[dataKey]?.filter((surveyResponse)=>{
            return surveyResponse.source.businessNumber === franchisee.businessLocation.uid
        } )
    }
}
export function contactHasFranchisee(contact: Contact,franchisee: Franchisee) {
    let hasFranchisee: boolean = false;
    if (contact.listMemberships){
        for (const listMembership of contact.listMemberships) {
            if (listMembership.list?.franchisee){
                if (listMembership.list.franchisee.uid === franchisee.uid){
                    hasFranchisee = true;
                    break;
                }
            }
        }
    }
    return hasFranchisee;

}

export function getHowYouFeelTrackerIntervalReport(data: ISurveyResponse,franchisee?: Franchisee): IIntervalWithAverageData {
if (franchisee){
    filterSurveyReportResultByFranchisee(data,franchisee);
}
    return  Object.entries(data).reduce((acc: any, [interval, listOfSurveyResponseSnapshot]) => {
        acc[interval]??={
            count: 0,
            total: 0,
            average: 0
        }
        if (listOfSurveyResponseSnapshot.length) {
            listOfSurveyResponseSnapshot.forEach((surveyResponse) => {
                const responseAnswers: IResponseAnswer[] = surveyResponse.source.answers as IResponseAnswer[];
                for ( const responseAnswer of responseAnswers) {
                    if (
                        (responseAnswer.questionTitle === trackerQuestions.HOW_DO_YOU_FEEL) || (responseAnswer.questionTitle === trackerQuestions.RECOMMEND_US)
                    ){
                        setIntervalAverage(acc,interval,responseAnswer.answer ?? '0');
                    }
                }
            });
        }
        return acc;
    }, {}) as IIntervalWithAverageData;

}
export function getCommunicationScoreAverage(data: ISurveyResponse): IIntervalWithAverageData {
    return  Object.entries(data).reduce((acc: any, [interval, listOfSurveyResponseSnapshot]) => {
        acc[interval]??={
            count: 0,
            total: 0,
            average: 0
        }
        if (listOfSurveyResponseSnapshot.length) {
            listOfSurveyResponseSnapshot.forEach((surveyResponse) => {
                const responseAnswers: IResponseAnswer[] = surveyResponse.source.answers as IResponseAnswer[];
                for ( const responseAnswer of responseAnswers) {
                    if (
                        (responseAnswer.questionTitle === trackerQuestions.QUALITY_OF_COMMUNICATION)
                    ){
                        setIntervalAverage(acc,interval,responseAnswer.answer ?? '0');

                    }
                }
            });
        }
        return acc;
    }, {}) as IIntervalWithAverageData;
}
export function getOverallScoreAverage(data: ISurveyResponse): IIntervalWithAverageData {
    return  Object.entries(data).reduce((acc: any, [interval, listOfSurveyResponseSnapshot]) => {
        acc[interval]??={
            count: 0,
            total: 0,
            average: 0
        }
        if (listOfSurveyResponseSnapshot.length) {
            listOfSurveyResponseSnapshot.forEach((surveyResponse) => {
                setIntervalAverage(acc,interval,surveyResponse.source.overallScore  ?? '0');
            });
        }
        return acc;
    }, {}) as IIntervalWithAverageData;
}
function setIntervalAverage(acc:IIntervalWithAverageData, interval:string, answer:string) {
    acc[interval]['count'] =  acc[interval]['count'] + 1;
    acc[interval]['total'] = acc[interval]['total'] + parseInt(answer);
    acc[interval]['average'] = parseFloat( ( acc[interval]['total'] / acc[interval]['count'] ).toFixed(1));
}
function setLocationSurveyData(acc: ILocationSurveysData,locationCity:string,survey:string,interval:string,increment?: string){
    const _increment = increment ? parseFloat(increment) : 1;
    //get location data
    acc[locationCity] ??= {
        intervals: {},
        surveys: {},
        total: 0
    }
    acc[locationCity]['intervals'][interval] = ((acc[locationCity]?.['intervals']?.[interval] ?? 0) + _increment);
    acc[locationCity]['total'] = acc[locationCity]['total'] + 1;

    // set survey data
    acc[locationCity]['surveys'][survey] ??= {
        intervals: {},
        total: 0
    };
    acc[locationCity]['surveys'][survey]['intervals'][interval] = ((acc[locationCity]?.['surveys'][survey]['intervals'][interval] ?? 0) + _increment);
    acc[locationCity]['surveys'][survey]['total'] = acc[locationCity]['surveys'][survey]['total'] + 1;
}
export function getHDYFIntervalAverage(reports: IIntervalWithAverageData): number{
    let total = 0;
    let count = 0;
    Object.values(reports).forEach((val) => {
        count += val.count;
        total += val.total;
    })
    return  total?  parseFloat((total/count).toFixed(1)) : 0;
}
function setReportIntervalData(acc: IReportIntervalsData,key: string,interval: string,increment:number=1){
    acc[key]['intervals'][interval] = ((acc[key]?.['intervals']?.[interval] ?? 0) + increment);
    acc[key]['total'] = acc[key]['total'] +increment;
}
/**
 *
 * @param sourceData
 * @param interval
 */
function setSurveyIntervalWithNoResponse(surveyResponseData:IReportIntervalsData,interval: string ){
    Object.entries(surveyResponseData).forEach(([key, values]) => {
        surveyResponseData[key]['intervals'][interval] = 0;
    })
}
function setLocationSurveyIntervalWithNoResponse(surveyResponseData:ILocationSurveysData,interval: string ){

    Object.entries(surveyResponseData).forEach(([location, values]) => {
        surveyResponseData[location]['intervals'][interval] = 0;

        Object.keys(values['surveys']).forEach((survey) => {
            surveyResponseData[location]['surveys'][survey]['intervals'][interval] = 0
        })
    })
}