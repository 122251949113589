import {
    DATE_RANGE_SEPARATOR,
    FormConversions,
    IChartDataWithLabels, IChartDataWithLabels2,
    IContactReportFilterParams,
    IContactReportResult, IContactReportResultWithPreviousData, IContactResultsWithCount,
    IContactSnapshot,
    IContactSnapshots,
    IContactStatistics, IContactStatisticsFilter,
    IContactSummaryReportHeader,
    IFormsConversionsData,
    IHubspotPropertyWithHistory,
    IMainSourceReportData,
    IPaginatedContactReportFilterParams,
    IRecentConversionForm,
    IReportIntervalsData,
    IReportTimeInterval, LeadsLabels,
    MONTHS_OF_THE_YEAR, SalesLabels
} from "./contact.report.interface";
import {
    COLORS,
     getComparisonStateEndDate,
    getDaysInMonth,
    getIsoDateParts,
     getTimeZoneDeviationInMinutes,
    getWeekNumber,
    removePageQueryParam,
    ReportViewTypes,
    shuffleWord,
    getReportPeriod
} from "../../common";
import {utils, writeFileXLSX} from "xlsx";
import {getContactReportDetails} from "./contact.report.services";
import i18n from "../../translate/i18n";
import {Contact} from "../../hubspot/contact";
import date from "date-and-time";
import {IUserPermissions} from "../../auth";
import {userHasAnyPermission} from "../../auth/permission/helpers";
import {
     filterContactReportResultByFranchisee,
    IIntervalWithAverageData,
    ILocationSurveysData,
    ISurveyReportFilterParams,
    ISurveyResponse
} from "../birdEye";
import {Franchisee} from "../../hubspot";
import {t} from "i18next";
import {Location} from "history";
import {NavigateFunction} from "react-router-dom";

export function getYearHeaderFromInterval(dateInterval: string): IContactSummaryReportHeader {
    const [startDate, endDate] = dateInterval.split(DATE_RANGE_SEPARATOR);
    const currentDate = new Date(startDate);
    const year = currentDate.getFullYear().toString();
    return {
        year,
        name: year,
        start: startDate,
        end: endDate,
        month: MONTHS_OF_THE_YEAR[(currentDate.getMonth())],
    };
}

export function getMonthHeaderFromInterval(dateInterval: string): IContactSummaryReportHeader {
    const [startDate, endDate] = dateInterval.split(DATE_RANGE_SEPARATOR);
    const applicableDate = getTimeZoneDate(startDate);
    return {
        year: (applicableDate.getFullYear()).toString(),
        name: MONTHS_OF_THE_YEAR[(applicableDate.getMonth())],
        start: startDate,
        end: endDate
    };
}

export function getDayHeaderFromInterval(dateInterval: string): IContactSummaryReportHeader {
    const [startDate, endDate] = dateInterval.split(DATE_RANGE_SEPARATOR);
    const applicableDate = getTimeZoneDate(startDate);
    return {
        year: (applicableDate.getFullYear()).toString(),
        name: (applicableDate.getDate()).toString(),
        month: MONTHS_OF_THE_YEAR[(applicableDate.getMonth())],
        start: startDate,
        end: endDate
    };
}

export function getWeekHeaderFromInterval(dateString: string): IContactSummaryReportHeader {
    const t = i18n.t;
    const weekPeriod = dateString.split(DATE_RANGE_SEPARATOR);
    const currentDate = new Date(weekPeriod[0]);
    return {
        start: (weekPeriod[0]).split("T")[0],
        end: (weekPeriod[1]).split("T")[0],
        month: MONTHS_OF_THE_YEAR[(currentDate.getMonth())],
        name: `${t('report.leadSource.aggregate.header.week')} ${getWeekNumber(currentDate)}`,
        year: (currentDate.getFullYear()).toString()
    }
}

export function getYearlyContactStatistics(data: IContactStatistics, filter: IContactStatisticsFilter ) {
    let sourceData = {};        
    if (Object.keys(data)){
        sourceData = Object.entries(data).reduce<Record<string, any>>((acc, [franchisee,statistics])=>{
            for (const year in statistics) {
                const yearly = statistics[year];                
                acc[year] ??={};
                for (const month in yearly) {
                    acc[year][month] ??=0;
                    if (filter.source){
                        const monthly = yearly[month].sources;
                        for (const source in monthly) {
                            acc[year][month] += monthly[source];
                        }
                    }else{
                        acc[year][month] += yearly[month].total;
                    }
                }
            }
            return acc;
        },{});        
    }
    return sourceData;
}
export function getSourceData(data: IContactReportResult, userAndPermissions: IUserPermissions): IMainSourceReportData {
    const intervalsWithNoContacts: string[] = [];
    const sourceData = Object.entries(data).reduce((acc: any, [interval, listOfContactSnapshot]) => {
        if (listOfContactSnapshot.length) {
            listOfContactSnapshot.forEach((contact: any) => {

                const properties = contact.source.properties;
                let source = properties.hs_analytics_source?.toString().replace("_", " ").toUpperCase() ?? 'UNKNOWN';
                let dataSource = (properties.hs_analytics_source_data_2?.trim() || properties.hs_analytics_source_data_1?.trim())?.toString().replace("_", " ").toUpperCase();
                dataSource = (dataSource) ? ((dataSource === 'undefined') ? 'UNKNOWN' : dataSource) : 'UNKNOWN';
                //compute source 2
                dataSource = dataSource === 'undefined' || !dataSource ? 'UNKNOWN' : dataSource;
                if (source === 'PAID SEARCH') {
                    dataSource = 'GOOGLE ADs';
                } else if (source === 'PAID SOCIAL') {
                    dataSource = 'FACEBOOK ADs';
                }
                source = (source === 'PAID SEARCH' || source === 'PAID SOCIAL') ? 'PAID TRAFFIC' : source;
                const canViewUnknownSource = userHasAnyPermission(userAndPermissions, [{
                    module: 'hubspot:contact',
                    key: 'unrestricted'
                }]);
                //get source data
                acc[source] ??= {
                    intervals: {},
                    subSources: {},
                    total: 0
                }
                // set source data
                if (source === 'UNKNOWN' && canViewUnknownSource) {
                    setSourceInterval(acc, source, interval);
                } else {
                    setSourceInterval(acc, source, interval);
                }

                //get sub source
                acc[source]['subSources'][dataSource] ??= {
                    intervals: {},
                    total: 0
                };
                // set sub source data
                if (dataSource === 'UNKNOWN' && canViewUnknownSource) {
                    setSubSourceInterval(acc, source, dataSource, interval);
                } else {
                    setSubSourceInterval(acc, source, dataSource, interval);
                }


            });
        }

        //the next two conditions are used to add 0 to sources for intervals that do not have contacts
        else if (Object.keys(acc).length) {
            setSourceIntervalWithNoContact(acc, interval)
        } else {
            intervalsWithNoContacts.push(interval)
        }
        return acc;
    }, {}) as IMainSourceReportData;
    if (intervalsWithNoContacts.length) {
        intervalsWithNoContacts.forEach((interval) => {
            setSourceIntervalWithNoContact(sourceData, interval)
        });
    }
    //sort by total in ascending
    return Object.fromEntries(
        Object.entries(sourceData).sort((a, b) => a[1]['total'] - b[1]['total'])
    ) as IMainSourceReportData;
}

/**
 *
 * @param sourceData
 * @param source
 * @param interval
 */
function setSourceInterval(sourceData: IMainSourceReportData, source: string, interval: string) {
    sourceData[source]['intervals'][interval] = ((sourceData[source]?.['intervals']?.[interval] ?? 0) + 1);
    sourceData[source]['total'] = sourceData[source]['total'] + 1;
}

/**
 *
 * @param sourceData
 * @param source
 * @param dataSource
 * @param interval
 */
function setSubSourceInterval(sourceData: IMainSourceReportData, source: string, dataSource: string, interval: string) {
    sourceData[source]['subSources'][dataSource]['intervals'][interval] = ((sourceData[source]?.['subSources'][dataSource]['intervals'][interval] ?? 0) + 1);
    sourceData[source]['subSources'][dataSource]['total'] = sourceData[source]['subSources'][dataSource]['total'] + 1;
}

/**
 *
 * @param sourceData
 * @param interval
 */
function setSourceIntervalWithNoContact(sourceData: IMainSourceReportData, interval: string) {
    Object.entries(sourceData).forEach(([source, values]) => {
        sourceData[source]['intervals'][interval] = 0;
        // @ts-ignore
        Object.keys(values['subSources']).forEach((subSource) => {
            sourceData[source]['subSources'][subSource]['intervals'][interval] = 0
        })
    })
}

/**
 *
 * @param data
 */
function _getLeadsData(data: IContactReportResult,leadsKey: string): IReportIntervalsData{
    return Object.entries(data).reduce((acc: any, [interval, listOfContactSnapshot]) => {
        if (listOfContactSnapshot.length) {
            listOfContactSnapshot.forEach((contact: any) => {
                acc[leadsKey] ??= {
                    intervals: {},
                    total: 0
                }
                acc[leadsKey]['intervals'][interval] = ((acc[leadsKey]?.['intervals']?.[interval] ?? 0) + 1);
                acc[leadsKey]['total'] = acc[leadsKey]['total'] + 1;
            });
        } else {
            Object.entries(acc).forEach(([source, values]) => {
                acc[leadsKey]['intervals'][interval] = 0;
            })
        }
        return acc;
    }, {}) as IReportIntervalsData;
}
export function getLeadsData(data: IContactReportResultWithPreviousData,franchisee?: Franchisee): IReportIntervalsData {
    if (franchisee){
        filterContactReportResultByFranchisee(data.currentInterval,franchisee);
    }
    // const leadsKey = 'leads';
    let response = _getLeadsData(data.currentInterval,LeadsLabels.SELECTED_INTERVAL_);
    if (data.previousInterval){
        response = {...response,...(_getLeadsData(data.previousInterval,LeadsLabels.PREVIOUS_INTERVAL_))}
    }
    return response;
}

/**
 *
 * @param data
 */
export function getSummaryReportTableData(data: IContactReportResult): Record<string, any> {
    return Object.entries(data).reduce((acc: any, [interval, contacts]) => {
        contacts.forEach((contact: Contact) => {
            const properties = contact.source.properties;
            let source = properties.hs_analytics_source?.toString().replace("_", " ").toUpperCase() ?? 'UNKNOWN';

            let dataSource = (properties.hs_analytics_source_data_2?.trim() || properties.hs_analytics_source_data_1?.trim())?.toString().replace("_", " ").toUpperCase();
            dataSource = (dataSource) ? ((dataSource === 'undefined') ? 'UNKNOWN' : dataSource) : 'UNKNOWN';
            if (source === 'PAID SEARCH') {
                dataSource = 'GOOGLE ADs';
            } else if (source === 'PAID SOCIAL') {
                dataSource = 'FACEBOOK ADs';
            }
            source = (source === 'PAID SEARCH' || source === 'PAID SOCIAL') ? 'PAID TRAFFIC' : source;

            acc[source] ??= {};
            acc[source][dataSource] ??= {};
            acc[source][dataSource][interval] = (acc[source][dataSource][interval] ?? 0) + 1;
        });

        return acc;
    }, {});
}

/**
 *
 * @param data
 * @param mainSource
 */
export function getSubSourceData(data: IContactReportResultWithPreviousData, mainSource: string): IReportIntervalsData {
    const intervalsWithNoContacts: string[] = [];
    const subSourceData = Object.entries(data.currentInterval).reduce((acc: any, [interval, contacts]) => {
        if (contacts.length) {
            contacts.forEach((contact: any) => {
                const properties = contact.source.properties;
                if (properties.hs_analytics_source?.toString().replace("_", " ").toUpperCase() === mainSource) {
                    const dataSource2 = properties.hs_analytics_source_data_2?.toString().replace("_", " ").toUpperCase();
                    let source = dataSource2 ? dataSource2 : properties.hs_analytics_source_data_1?.toString().replace("_", " ").toUpperCase();
                    source ??= 'UNKNOWN';
                    acc[source] ??= {
                        intervals: {},
                        total: 0
                    }
                    acc[source]['intervals'][interval] = ((acc[source]?.['intervals']?.[interval] ?? 0) + 1);
                    acc[source]['total'] = acc[source]['total'] + 1;

                }
            });
        }
        //the next two conditions are used to add 0 to sources for intervals who do not have contacts
        else if (Object.keys(acc).length) {
            Object.keys(acc).forEach((source) => {
                acc[source]['intervals'][interval] = 0;
            });
        } else {
            intervalsWithNoContacts.push(interval);
        }
        return acc;
    }, {}) as IReportIntervalsData;

    if (intervalsWithNoContacts.length) {
        intervalsWithNoContacts.forEach((interval) => {
            Object.keys(subSourceData).forEach((source) => {
                subSourceData[source]['intervals'][interval] = 0;
            });
        });
    }
    //sort by total in ascending order
    return Object.fromEntries(
        Object.entries(subSourceData).sort((a, b) => a[1]['total'] - b[1]['total'])
    ) as IReportIntervalsData;
}

/**
 * function computes lifecycle stage interval report
 * @param data
 * @param mainSource
 * @returns IReportIntervalsData
 */
export function getLCSData(data: IContactReportResult,franchisee?: Franchisee,userAndPermissions?: IUserPermissions,mainSource?: string): IReportIntervalsData {
    if (franchisee){
        filterContactReportResultByFranchisee(data,franchisee);
    }
    const intervalsWithNoContacts: string[] = [];
    const lcsData = Object.entries(data).reduce((acc: any, [interval, listOfContactSnapshot]) => {
        if (listOfContactSnapshot.length) {
            listOfContactSnapshot.forEach((contact: any) => {
                const properties = contact.source.properties;
                let lcs = properties?.lifecyclestage?.toString().replace("_", " ").toUpperCase() ?? 'UNKNOWN';
                let canViewAllSources =  false ;
                if (userAndPermissions){
                    canViewAllSources =  userHasAnyPermission(userAndPermissions, [{
                        module: 'hubspot:contact',
                        key: 'unrestricted'
                    },{
                        module: 'hubspot:franchisee',
                        key: 'unrestricted'
                    }]);
                }

                if (!canViewAllSources  &&
                    (
                        (lcs === 'LEAD') || (lcs === 'SALESQUALIFIEDLEAD') || (lcs === 'SUBSCRIBER')  || (lcs === 'MARKETINGQUALIFIEDLEAD')
                    )
                ){
                    lcs = 'LEAD';
                }
                if ( mainSource ){
                    let source = properties.hs_analytics_source?.toString().replace("_", " ").toUpperCase() ?? 'UNKNOWN';
                    source = (source === 'PAID SEARCH' || source === 'PAID SOCIAL') ? 'PAID TRAFFIC' : source;
                    if (source === mainSource) {
                        setReportIntervalsData(lcs, acc, interval ,1);
                    }
                }else{
                    setReportIntervalsData(lcs, acc, interval ,1);
                }

            });
        }
        //the next two conditions are used to add 0 to life cycle stages for intervals with no contacts
        else if (Object.keys(acc).length) {
            Object.keys(acc).forEach((key) => {
                acc[key]['intervals'][interval] = 0;
            })
        } else {
            intervalsWithNoContacts.push(interval);
        }
        return acc;
    }, {}) as IReportIntervalsData;

    if (intervalsWithNoContacts.length) {
        intervalsWithNoContacts.forEach((interval) => {
            Object.keys(lcsData).forEach((key) => {
                lcsData[key]['intervals'][interval] = 0;
            });
        });
    }
    //sort by total in ascending order
    return Object.fromEntries(
        Object.entries(lcsData).sort((a, b) => a[1]['total'] - b[1]['total'])
    ) as IReportIntervalsData;
}

function setReportIntervalsData(key: string, acc: IReportIntervalsData, interval: string ,increment: number) {
    acc[key] ??= {
        intervals: {},
        total: 0
    }
    acc[key]['intervals'][interval] = ((acc[key]?.['intervals']?.[interval] ?? 0) + increment);
    acc[key]['total'] = acc[key]['total'] + increment;
}

function setFranchiseInterval(franchiseeName: string, acc: IReportIntervalsData, interval: string) {
    franchiseeName = (franchiseeName === 'undefined' || !franchiseeName) ? 'UNKNOWN' : franchiseeName;
    acc[franchiseeName] ??= {
        intervals: {},
        total: 0
    }
    acc[franchiseeName]['intervals'][interval] = ((acc[franchiseeName]?.['intervals']?.[interval] ?? 0) + 1);
    acc[franchiseeName]['total'] = acc[franchiseeName]['total'] + 1;
}
function setFormInterval(formName: string, acc: IReportIntervalsData, interval: string) {
    acc[formName] ??= {
        intervals: {},
        total: 0
    }
    acc[formName]['intervals'][interval] = ((acc[formName]?.['intervals']?.[interval] ?? 0) + 1);
    acc[formName]['total'] = acc[formName]['total'] + 1;
}

/**
 * function computes franchisee interval report
 * @param data
 * @param userAndPermissions
 *
 * @returns IReportIntervalsData
 */
export function getFranchiseeReportData(data: IContactReportResult, userAndPermissions: IUserPermissions,franchisees?: Franchisee[]): IReportIntervalsData {
    const intervalsWithNoContacts: string[] = [];
    let franchiseeData = Object.entries(data).reduce((acc: any, [interval, contacts]) => {
        if (contacts.length) {
            contacts.forEach((contact) => {
                const canViewUnknownSource = userHasAnyPermission(userAndPermissions, [{
                    module: 'hubspot:contact',
                    key: 'unrestricted'
                }]);
                let contactBelongsToFranchisee = false;
                if (franchisees){
                    franchisees.forEach((franchisee)=>{
                        let franchiseeCompanies: string[] = (franchisee.source.pool_order_company ?? '').split(',');
                        if (franchiseeCompanies.length){
                           const isFranchiseeContact: boolean = franchiseeCompanies.some((company) => contact.source.properties.company === company);
                           if (isFranchiseeContact){
                               contactBelongsToFranchisee = true;
                               setFranchiseInterval(franchisee.name, acc, interval);
                           }
                        }
                    });
                    if (!contactBelongsToFranchisee){
                        setFranchiseInterval("UNKNOWN", acc, interval);
                    }
                }else{
                    let numberOfUnknownFranchisees = 0;
                    let totalListMemberships = contact.listMemberships?.length || 0;
                    if (totalListMemberships) {
                        let hasVirginia = false;
                        contact.listMemberships.forEach((listMembership) => {
                            if (listMembership.list?.franchisee) {
                                //Eliminate one of the Virginia's franchisee
                                if (listMembership.list?.franchisee?.name?.toLowerCase().includes('virginia') && !hasVirginia){
                                    setFranchiseInterval(listMembership.list?.franchisee?.name, acc, interval);
                                    hasVirginia = true;
                                }else if(!hasVirginia){
                                    setFranchiseInterval(listMembership.list?.franchisee?.name, acc, interval);
                                }

                            }else{
                                numberOfUnknownFranchisees+= 1;
                            }
                        });
                        if (canViewUnknownSource && numberOfUnknownFranchisees === totalListMemberships) {
                            setFranchiseInterval("UNKNOWN", acc, interval);
                        }

                    } else if (canViewUnknownSource) {
                        setFranchiseInterval("UNKNOWN", acc, interval);
                    }
                }
            });
        }
        //the next two conditions are used to add 0 to franchisees for intervals with no contacts
        else if (Object.keys(acc).length) {
            Object.keys(acc).forEach((key) => {
                acc[key]['intervals'][interval] = 0;
            })
        } else {
            intervalsWithNoContacts.push(interval);
        }
        return acc;
    }, {}) as IReportIntervalsData;
    if (intervalsWithNoContacts.length) {
        intervalsWithNoContacts.forEach((interval) => {
            Object.keys(franchiseeData).forEach((key) => {
                franchiseeData[key]['intervals'][interval] = 0;
            });
        });
    }
    //sort by total in ascending order
    return Object.fromEntries(
        Object.entries(franchiseeData).sort((a, b) => a[1]['total'] - b[1]['total'])
    ) as IReportIntervalsData;

}
export function getFormConversionData(data: IContactReportResult,conversionForms: IRecentConversionForm[] ,userAndPermissions: IUserPermissions ): IFormsConversionsData | undefined {
   const canViewFormConversion =  userHasAnyPermission(userAndPermissions, [{
        module: 'hubspot:contact',
        key: 'unrestricted'
    },{
        module: 'hubspot:franchisee',
        key: 'unrestricted'
    }]);
   if (canViewFormConversion) {
       const intervalsWithNoContacts: string[] = [];
       let formConversionData = Object.entries(data).reduce((acc: any, [interval, contacts]) => {

           if (contacts.length) {
               contacts.forEach((contact) => {
                   /**
                    * Filtering using contact property
                    */
                   // const recentConversionForm = conversionForms.find((form)=>{
                   //     // return   (form.name.trim().toLowerCase() === contact.source.properties.recent_conversion_event_name.toLowerCase() )
                   //     return   (contact.source.properties.recent_conversion_event_name?.includes(form.name) )
                   // }
                   //   );
                   // const firstConversionForm = conversionForms.find((form)=>{
                   //     // return   (form.name.trim().toLowerCase() === contact.source.properties.first_conversion_event_name.toLowerCase() )
                   //     return  (contact.source.properties.first_conversion_event_name?.includes(form.name) )
                   // } );
                   //
                   // if (recentConversionForm){
                   //     setFormConversionsData(acc,recentConversionForm.name,FormConversions.RECENT_CONVERSION,interval);
                   // }
                   // if (firstConversionForm){
                   //     setFormConversionsData(acc,firstConversionForm.name,FormConversions.FIRST_CONVERSION,interval);
                   // }

                   /**
                    * Filtering using contact property with history
                    */
                   conversionForms.forEach((form) => {
                       const firstFormConversion: IHubspotPropertyWithHistory[] = contact.source.propertiesWithHistory.first_conversion_event_name as IHubspotPropertyWithHistory[];
                       const isFirstConversion = firstFormConversion.find((firstConversion) => {
                               /**
                                * Example of contact first conversion form
                                * Fiberglass Pool Pricing Calculator: Speak with Designer [Design/Price Tool] V2
                                */
                                   //split contact conversion form name by colon to get the actual form name
                               const formName = firstConversion.value.split(':')[1];
                               return (formName && formName.trim().toLowerCase() === form.name.toLowerCase());
                               // return firstConversion.value.includes(form.name);
                           }
                       );

                       if (isFirstConversion) {
                           setFormConversionsData(acc, form.name, FormConversions.FIRST_CONVERSION, interval);
                       }
                       /**
                        * Remove recent conversion from chart view
                        */
                       // const recentFormConversion: IHubspotPropertyWithHistory[] =  contact.source.propertiesWithHistory.recent_conversion_event_name as IHubspotPropertyWithHistory[];
                       // const isRecentConversion = recentFormConversion.find((recentConversion)=>recentConversion.value.includes(form.name));
                       // if (isRecentConversion){
                       //     setFormConversionsData(acc,form.name,FormConversions.RECENT_CONVERSION,interval);
                       // }
                   });
               });
           }
           //the next two conditions are used to add 0 to franchisees for intervals with no contacts
           else if (Object.keys(acc).length) {
               setFormConversionIntervalWithNoResponse(acc, interval);
           } else {
               intervalsWithNoContacts.push(interval);
           }
           return acc;
       }, {}) as IFormsConversionsData;
       if (intervalsWithNoContacts.length) {
           intervalsWithNoContacts.forEach((interval) => {
               setFormConversionIntervalWithNoResponse(formConversionData, interval);
           });
       }
       //sort by total in ascending order
       return Object.fromEntries(
           Object.entries(formConversionData).sort((a, b) => b[1]['total'] - a[1]['total'])
       ) as IFormsConversionsData;
       //sort by total in ascending
   }
   return  undefined;
}
export function getGoalsReportData( filter: IContactReportFilterParams, contacts: Contact[] | undefined, goalId: string,franchisee: Franchisee,year:string, useUTC: boolean = true ): IReportIntervalsData {
    const data = getSourceReport(filter,contacts,useUTC);
    const goalsKey = 'Goals';
    const salesKey ='Sales';
    return  Object.entries(data).reduce((acc: any, [interval, contacts]) => {
        const [startDate] = interval.split(DATE_RANGE_SEPARATOR);
        const applicableDate = getTimeZoneDate(startDate);
        const monthIndex = applicableDate.getMonth();
        acc[goalsKey] ??= {
            intervals: {},
            total: 0
        }
        acc[salesKey] ??= {
            intervals: {},
            total: 0
        }
        acc[salesKey].intervals[monthIndex] = contacts.length;
        acc[salesKey].total += contacts.length;
        acc[goalsKey].intervals[monthIndex] = franchisee.goals[goalId][year][(monthIndex+1)];
        acc[goalsKey].total +=  franchisee.goals[goalId][year][(monthIndex+1)];
        return acc;
    }, {}) as IReportIntervalsData;
}
export function getSalesGoalsReportData( data: IContactReportResult,filter: IContactReportFilterParams,goalId: string): IReportIntervalsData {
    const goalsKey = 'Goals';
    const salesKey ='Sales';
    return Object.entries(data).reduce((acc: any, [interval, contacts]) => {
            const [startDate] = interval.split(DATE_RANGE_SEPARATOR);
            const applicableDate = getTimeZoneDate(startDate);
            const monthIndex = applicableDate.getMonth();
            const year = applicableDate.getFullYear();
            if ((filter.franchisee) ){
                setReportIntervalsData(salesKey, acc, monthIndex.toString(), contacts.length);
                setReportIntervalsData(goalsKey, acc, monthIndex.toString(), (filter.franchisee?.goals[goalId]?.[year]?.[(monthIndex + 1)] ?? 0));
            }else{
                setReportIntervalsData(salesKey, acc, monthIndex.toString(), 0);
                setReportIntervalsData(goalsKey, acc, monthIndex.toString(), 0);
            }
            return acc;
        }, [])  as IReportIntervalsData;
}
export function _getSalesReportData( data: IContactReportResult,salesKey: string): IReportIntervalsData {
    return  Object.entries(data).reduce((acc: any, [interval, contacts]) => {
        acc[salesKey] ??= {
            intervals: {},
            total: 0
        }
        acc[salesKey].intervals[interval] ??= contacts.length;
        acc[salesKey].total += contacts.length;
        return acc;
    }, {}) as IReportIntervalsData;
}

export function getSalesReportData( data: IContactReportResultWithPreviousData,franchisee?: Franchisee): IReportIntervalsData {
    if (franchisee){
        filterContactReportResultByFranchisee(data.currentInterval,franchisee);
    }
    let response = _getSalesReportData(data.currentInterval,SalesLabels.SELECTED_INTERVAL_);
    if (data.previousInterval){
        response = {...response,...(_getSalesReportData(data.previousInterval,SalesLabels.PREVIOUS_INTERVAL_))}
    }
    return response;

}

export function getReportChartDataWithLabels(report: IReportIntervalsData ,months: string[]): IChartDataWithLabels[] {
    return Object.entries(report).reduce<IChartDataWithLabels[]>((acc, [key, values]) => {
        const labelData: IChartDataWithLabels = {name: key, data: [] }
        MONTHS_OF_THE_YEAR.forEach((month,monthIndex)=>{
            if (months.includes(month)){
                const intervalCumulative = values?.intervals[monthIndex] ?? 0;
                labelData.data.push(intervalCumulative);
            }
        })
        acc.push(labelData)

        return acc;
    }, []);
}
export function getReportTotalsChartDataWithLabels(data: IMainSourceReportData | IReportIntervalsData | IIntervalWithAverageData | undefined): IChartDataWithLabels[]{
    let obj: IChartDataWithLabels = {
        name: t('report.leadSource.charts.total'),
        data: []
    };
    if (data) {
        obj.data = getReportTotals(data);
    }
    return [obj];
}
function setFormConversionIntervalWithNoResponse(formConversionData:IFormsConversionsData,interval: string ){
    Object.entries(formConversionData).forEach(([formName, values]) => {
        Object.keys(values['conversionType']).forEach((formType) => {
            formConversionData[formName]['conversionType'][formType]['intervals'][interval] = 0;
        })
    })
}
function setFormConversionsData(acc: IFormsConversionsData,formName:string,formType:string,interval:string){
    //get location data
    acc[formName] ??= {
        conversionType: {},
        total: 0
    }
    acc[formName]['total'] = acc[formName]['total'] + 1;
    // set survey data
    acc[formName]['conversionType'][formType] ??= {
        intervals: {},
        total: 0
    };
    acc[formName]['conversionType'][formType]['intervals'][interval] = ( acc[formName]['conversionType'][formType]['intervals'][interval] ?? 0) + 1;
    acc[formName]['conversionType'][formType]['total'] = acc[formName]['conversionType'][formType]['total'] + 1;
}
export function getFormConversionsChartData(formsConversions: IFormsConversionsData): IChartDataWithLabels[] {
    /**
     * Remove recent conversion from chart view
     */
    const firstConversion: IChartDataWithLabels = {name: FormConversions.FIRST_CONVERSION, data: []}
    // const recentConversion: IChartDataWithLabels = {name: FormConversions.RECENT_CONVERSION, data: []}
    Object.values(formsConversions).forEach((formConversion)=>{
        let _firstConversion = 0;
        // let _recentConversion = 0;
        if (formConversion.conversionType[FormConversions.FIRST_CONVERSION]){
            _firstConversion =  Object.values( formConversion.conversionType[FormConversions.FIRST_CONVERSION]?.intervals ?? {}).reduce((acc, val) => acc + val, 0);
        }
        firstConversion.data.push(_firstConversion);
        // if (formConversion.conversionType[FormConversions.RECENT_CONVERSION]){
        //     _recentConversion =  Object.values( formConversion.conversionType[FormConversions.RECENT_CONVERSION].intervals).reduce((acc, val) => acc + val, 0);
        // }

        // recentConversion.data.push(_recentConversion);
    });
    // return [firstConversion,recentConversion];
    return [firstConversion];
}
/**
 * function returns the totals for each source
 * @param report  IMainSourceReportData | IReportIntervalsData | undefined
 * @return number[]
 */
export function getReportTotals(report: IMainSourceReportData | IReportIntervalsData | IIntervalWithAverageData | undefined): number[] {
    if (report) {
        return Object.values(report).map((val) => {
            return val['total'];
        })
    }
    return [];
}

/**
 * function return source total with the name of the source
 * @param report  IMainSourceReportData |IReportIntervalsData
 * @param sortByIntervalKey
 * @return IChartDataWithLabels[]
 */
export function getReportIntervalTotals(report: IMainSourceReportData | IReportIntervalsData | ILocationSurveysData, sortByIntervalKey: boolean = false): IChartDataWithLabels[] {
    if (sortByIntervalKey){
        for (const key in report) {
            report[key].intervals = Object.fromEntries(
                Object.entries(report[key]?.intervals ?? {}).sort((a, b) => Date.parse(a[0]) - Date.parse(b[0])  )
            )
        }
    }

    return  Object.entries(report).map(([key, values]) => {
            // @ts-ignore
            return {name: key, data: values['intervals'] ? Object.values(values['intervals']) : Object.values(values)}
        }
    );
}
export function getContactData(filter: IContactReportFilterParams | IPaginatedContactReportFilterParams, data:IContactResultsWithCount | undefined): IContactReportResultWithPreviousData {
    const sourceData:IContactReportResultWithPreviousData=  {} as IContactReportResultWithPreviousData
    sourceData.currentInterval = getSourceReport(filter , data?.results);
    if (filter.comparisonStateEndDate?.startDate && filter.comparisonStateEndDate?.endDate){
        const _filter = {...filter};
        _filter.startDateTime =  filter.comparisonStateEndDate.startDate.split("T")[0];
        _filter.endDateTime =  filter.comparisonStateEndDate.endDate.split("T")[0];
        sourceData.previousInterval = getSourceReport(_filter , data?.previousData?.results);
    }
    return sourceData
}
export function modifyContactFilter(filter:IPaginatedContactReportFilterParams | IContactReportFilterParams ,location: Location,navigate: NavigateFunction){
    if (filter.reportViewType !== ReportViewTypes.DASHBOARD){
        filter.comparisonStateEndDate = undefined;
        filter.compareData = false;
    }
    if (filter.reportViewType !== ReportViewTypes.TABLE && (filter.page && filter.page >1)){
        removePageQueryParam(location,navigate);
    }
}

// export function getComparisonStateEndDate(startDateTime: string,endDateTime: string,timeZone?: string):ComparisonStateEndDate {
//         const previousYearStartDateTime = getPreviousYearDate(startDateTime) ;
//         const previousYearEndDateTime = getPreviousYearDate(endDateTime) ;
//         return {
//             startDate: previousYearStartDateTime,
//             endDate: previousYearEndDateTime
//         }
// }

/**
 *
 * @param dateString
 * @param format
 */
export function getDateFormat(dateString: string, format: string): string{
    return date.format(getTimeZoneDate(dateString),format);
}
/**
 * function returns string[] which is an array of the different interval headers
 * @param filter
 * @param data
 */
export function getPeriodicIntervalHeader(filter: IContactReportFilterParams | ISurveyReportFilterParams, data: IContactReportResult | ISurveyResponse): string[] {
    return Object.keys(data).sort().map((dateString) => {
        const startInterval = (dateString.split(DATE_RANGE_SEPARATOR)[0]).split("T")[0];
        // return date.format(new Date(startInterval),"MMM-DD-YYYY");
        // @ts-ignore
        const viewType = filter?.interval ?? getReportPeriod(filter)
        if (viewType === 'weekly') {
            return getDateFormat(startInterval,"MMM-DD-YYYY");
            //return  date.format(getUTCDate(startInterval),"MMM-DD-YYYY");
            // const header = getWeekHeaderFromInterval(key);
            // return header.name + '-' + header.year;
        } else if (viewType === 'monthly') {
            return getDateFormat(startInterval,"MMMM");
            //return  date.format(getUTCDate(startInterval),"MMMM");
            // const header = getMonthHeaderFromInterval(key);
            // return header.name + '-' + header.year
        } else if (viewType === 'yearly') {
            // return getYearHeaderFromInterval(key)
           // return  date.format(getUTCDate(startInterval),"YYYY");
            return getDateFormat(startInterval,"YYYY");

        } else {
            return getDateFormat(startInterval,"ddd,MMM-DD-YYYY");
            //return date.format(getUTCDate(startInterval),"ddd,MMM-DD-YYYY");
            // const header = getDayHeaderFromInterval(key);
            // return header.month + '-' + header.name + '-' + header.year;
        }
    }) as string[]
}


export function getChartColors(totalLabels: number,providedColors: string[] ) {

    const colors = [...providedColors];

    if (colors.length < totalLabels){
        const dif = totalLabels - colors.length;
        for (let i = 1; i < dif; i++) {
          let  shuffledWord = shuffleWord(COLORS[i].substring(1));
            if (providedColors.includes(`#${shuffledWord}`)){
                const color = COLORS[(i+1)] ?? (
                    "#"+
                    COLORS[(getRandomInt((COLORS.length-1)))].substring(1,3)+
                    COLORS[(getRandomInt((COLORS.length-5)))].substring(2,4)
                );
                shuffledWord = shuffleWord(color.substring(1));
            }
            providedColors.push(`#${shuffledWord}`);
        }
    }
    return providedColors;
}
function getRandomInt(max: number) {
    return Math.ceil(Math.random() * max);
}
export async function exportLeadSourceReport(filter: IPaginatedContactReportFilterParams) {
    const t = i18n.t;
    const {results: data} = await getContactReportDetails({...filter, pageSize: -1});
    if (filter.reportViewType === ReportViewTypes.TABLE) {
        sort(data);
        const exportData = data.map((contact: Contact) => {
            const properties = contact.source.properties;
            const createdDate = getTimeZoneDate(properties.createdate, filter.timeZone);
            const modifiedDate = getTimeZoneDate(properties.lastmodifieddate, filter.timeZone);

            const row: Record<string, string | number> = {};

            row[`${t('report.contact.table.header.contactId')}`] = properties.hs_object_id;
            row[`${t('report.contact.table.header.firstName')}`] = properties.firstname;
            row[`${t('report.contact.table.header.lastName')}`] = properties.lastname;
            row[`${t('report.contact.table.header.email')}`] = properties.email;
            row[`${t('report.contact.table.header.phone')}`] = properties.phone;
            row[`${t('report.contact.table.header.lifeCycleStage')}`] = properties.lifecyclestage;
            row[`${t('report.contact.table.header.listId')}`] = contact.listMemberships?.map((lm) => (lm.list?.franchisee?.name ?? lm.list?.name)).join(',') ?? '';
            row[`${t('report.contact.table.header.source')}`] = properties.hs_analytics_source;
            row[`${t('report.contact.table.header.dataSource1')}`] = properties.hs_analytics_source_data_1;
            row[`${t('report.contact.table.header.dataSource2')}`] = properties.hs_analytics_source_data_2;
            row[`${t('report.contact.table.header.createdDate')}`] = `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`;
            row[`${t('report.contact.table.header.modifiedDate')}`] = `${modifiedDate.toLocaleDateString()} ${modifiedDate.toLocaleTimeString()}`;

            return row;
        });

        const ws = utils.json_to_sheet(exportData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, t(`report.types.${filter.reportViewType}`));

        const now = new Date();
        writeFileXLSX(wb, `${t(`report.types.${filter.reportViewType}`)}_Export_${now.toISOString()}.xlsx`);
    } else if (
        (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_WEEKLY) ||
        (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_MONTHLY) ||
        (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_YEARLY)
    ) {

        const reportData = getSourceReport(filter, data);

        const sortedIntervals = Object.keys(reportData).sort();
        const tableData = getSummaryReportTableData(reportData);

        const totalsRow: Record<string, any> = {};
        totalsRow[t('report.leadSource.table.header.source')] = `${t(`report.leadSource.table.footer.totalsText.${filter.reportViewType}`)}`;

        const sourceAggregate = Object.entries(tableData).reduce<Record<string, string | number>[]>((acc, [source, dataSources]) => {
            const row: Record<string, any> = {};
            row[t('report.leadSource.table.header.source')] = source;
            sortedIntervals.forEach((interval) => {
                const totalForInterval = Object.values(dataSources as Record<string, Record<string, number>>).reduce<number>((acc, intervalScores) => {
                    return acc + (intervalScores?.[interval] ?? 0);
                }, 0);

                if (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_WEEKLY) {
                    const header = getWeekHeaderFromInterval(interval);
                    const intervalHeader = `${header.name}\n ${header.start}\n ${header.end}`;
                    row[intervalHeader] = totalForInterval;
                    totalsRow[intervalHeader] = (totalsRow[intervalHeader] ?? 0) + totalForInterval;
                } else if (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_MONTHLY) {
                    const header = getMonthHeaderFromInterval(interval);
                    const intervalHeader = `${header.name}\n ${header.year}`;
                    row[intervalHeader] = totalForInterval;
                    totalsRow[intervalHeader] = (totalsRow[intervalHeader] ?? 0) + totalForInterval;
                } else { //if (filter.reportViewType === ContactReportViewType.AGGREGATE_SOURCE_YEARLY)
                    const header = getYearHeaderFromInterval(interval);
                    row[header.name] = totalForInterval;
                    totalsRow[header.name] = (totalsRow[header.name] ?? 0) + totalForInterval;
                }
            });

            return [...acc, row];
        }, []);

        // Add the totals row to the aggregate report rows
        sourceAggregate.push(totalsRow);

        const dataSourceAggregates = Object.entries(tableData).reduce<Map<string, Record<string, string | number>[]>>((acc, [source, dataSources]) => {
            const dataSourceEntries = Object.entries(dataSources as Record<string, Record<string, number>>)
                .reduce<Record<string, number>[]>((acc, [dataSource, intervalScores]) => {
                    const row: Record<string, any> = {};
                    row[`${t('report.contact.table.header.dataSource')}`] = dataSource;
                    sortedIntervals.forEach((interval) => {
                        if (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_WEEKLY) {
                            const header = getWeekHeaderFromInterval(interval);
                            row[`${header.name}\n ${header.start}\n ${header.end}`] = intervalScores?.[interval] ?? 0;
                        } else if (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_MONTHLY) {
                            const header = getMonthHeaderFromInterval(interval);
                            row[`${header.name}\n ${header.year}`] = intervalScores?.[interval] ?? 0;
                        } else { //if (filter.reportViewType === ContactReportViewType.AGGREGATE_SOURCE_YEARLY)
                            const header = getYearHeaderFromInterval(interval);
                            row[header.name] = intervalScores?.[interval] ?? 0;
                        }
                    });
                    return [...acc, row];
                }, []);

            acc.set(source, dataSourceEntries);
            return acc;
        }, new Map());


        const wb = utils.book_new();
        // Append summary aggregate report data
        const ws = utils.json_to_sheet(sourceAggregate);
        utils.book_append_sheet(wb, ws, t('report.types.sourceAggregate'));
        // Append Sub summary aggregate report data
        dataSourceAggregates.forEach((value, key) => {
            const ws = utils.json_to_sheet(value);
            utils.book_append_sheet(wb, ws, t(key));
        });
        const now = new Date();
        writeFileXLSX(wb, `${t(`report.types.${filter.reportViewType}`)}_Export_${now.toISOString()}.xlsx`);
    }
}

export function sort(data: Contact[] | IContactSnapshot[]) {
    data.sort((a, b) => {
        if ((a.email?.trim() || '').toLowerCase() < (b.email?.trim() || '').toLowerCase()) {
            return -1;
        } else if ((a.email?.trim() || '').toLowerCase() > (b.email?.trim() || '').toLowerCase()) {
            return 1;
        } else {
            return 0;
        }
        // if (`${a.firstname} ${a.lastname}`.toLowerCase() < `${b.firstname} ${b.lastname}`.toLowerCase()){
        //     return -1;
        // } else if (`${a.firstname} ${a.lastname}`.toLowerCase() > `${b.firstname} ${b.lastname}`.toLowerCase()){
        //     return 1;
        // } else {
        //     return 0;
        // }
    });

    return data;
}

// export function getTimeZoneDate(isoDate: string, timeZone?: string): Date {
//
//     timeZone ||= `${(new Date(isoDate).getTimezoneOffset() / 60) * -1}`;
//     const timeZoneOffsetInMinutes = (-1 * 60 * parseFloat(timeZone));
//     const isoDateTokens = getIsoDateParts(isoDate);
//     return new Date(Date.UTC(
//         isoDateTokens.year, (isoDateTokens.month - 1), isoDateTokens.date,
//         isoDateTokens.hours,timeZoneOffsetInMinutes, isoDateTokens.seconds, isoDateTokens.ms
//     ));
// }


export function getTimeZoneDate(isoDate: string, timeZone?: string): Date {

    timeZone ||= `${(new Date(isoDate).getTimezoneOffset() / 60)}`;
    const timeZoneOffsetInMinutes = (-1 * 60 * parseFloat(timeZone));
    const isoDateTokens = getIsoDateParts(isoDate);
    return new Date(Date.UTC(
        isoDateTokens.year, (isoDateTokens.month - 1), isoDateTokens.date,
        isoDateTokens.hours, (isoDateTokens.minutes - timeZoneOffsetInMinutes), isoDateTokens.seconds, isoDateTokens.ms
    ));
}
export function getUTCDate(isoDate: string, timeZone?: string): Date {

    timeZone ||= `${(new Date(isoDate).getTimezoneOffset() / 60) * -1}`;
    const timeZoneOffsetInMinutes = (-1 * 60 * parseFloat(timeZone));
    const isoDateTokens = getIsoDateParts(isoDate);
    return new Date(Date.UTC(
        isoDateTokens.year, (isoDateTokens.month - 1), isoDateTokens.date,
        isoDateTokens.hours,timeZoneOffsetInMinutes, isoDateTokens.seconds, isoDateTokens.ms
    ));
}

export function getContactSnapshots(contact: Contact): IContactSnapshots {
    const snapshots: Record<string, Record<string, string | number>> = {}
    const {propertiesWithHistory} = contact.source;
    if (propertiesWithHistory) {
        for (const [key, records] of Object.entries(propertiesWithHistory as Record<string, Record<string, string | number>[]>)) {
            for (const record of records) {
                if (record.value) {
                    snapshots[record.timestamp] ??= {};
                    snapshots[record.timestamp][key] = record['value'];
                }
            }
        }
    }
    return snapshots;
}

export function getCombinedSnapshot(contact: Contact, params: IContactReportFilterParams): IContactSnapshot {

    const {startDateTime, endDateTime} = params;

    let snapshot: IContactSnapshot;
    const snapshots = getContactSnapshots(contact);

    // Get the sorted timeline for the records in the snapshots
    const sortedTimelines = Object.keys(snapshots).sort();

    // Let's filter timelines that fall within the specified period for which we expect change in data.
    const validTimelines = sortedTimelines.filter((timeline) => ((startDateTime <= timeline) && (timeline <= endDateTime)));

    // Let's get the compact snapshot base off snapshot for valid timelines found
    snapshot = validTimelines.reduce<IContactSnapshot>((acc, timeline) => ({...acc, ...snapshots[timeline]}), {});

    const oldTimelines = sortedTimelines.filter((timeline) => (timeline < startDateTime));
    const oldSnapshot = oldTimelines.reduce<IContactSnapshot>((acc, timeline) => ({...acc, ...snapshots[timeline]}), {});

    // Merge old snapshots with one that is constructed and add some more recent properties for easy reference
    snapshot = {
        ...oldSnapshot,
        ...snapshot,
        id: contact.id,
        uid: contact.uid || contact.source?.id,
        firstname: contact.firstName,
        lastname: contact.lastName,
        phone: contact.phone,
        email: contact.email,
        //lists: contact.meta?.['lists'] ?? '',
        //createdate: contact.source.properties?.createdate,
        //lastmodifieddate: contact.source.properties?.lastmodifieddate
    };

    return snapshot;
}

/**
 * function returns start date and end date in utc
 * @param filter
 */
export function getEndDateAndStartDateInUTC(startDateTime: string,endDateTime: string,timeZone?: string): { utcStartDate: Date, utcEndDate: Date } {
    const startDateTokens = startDateTime.split('-').map((token) => parseInt(token));
    const endDateTokens = endDateTime.split('-').map((token) => parseInt(token));

    //Time zone adjustment computation
    //NB: Hubspot stores records using UTC+00:00. Therefore for times in UTC-05:00 say,
    // UTC must have advanced 5 hours ahead. Thus we need to add this number of hours to the selected dates.
    // The opposite is true when time zone is at UTC+05:00. UTC was behind by 5 hours so we need to subtract 5 hours from the selected dates.
    // I also try to accommodate for switching timezone across boundaries but user must use Default option
    const startTimeZoneDeviationInMinutes = (timeZone) ? (-1 * 60 * parseFloat(timeZone)) : new Date(`${startDateTime}T00:00:00.000Z`).getTimezoneOffset();
    const endTimeZoneDeviationInMinutes = (timeZone) ? (-1 * 60 * parseFloat(timeZone)) : new Date(`${endDateTime}T23:59:59.999Z`).getTimezoneOffset();

    const utcStartDate = new Date(
        Date.UTC(
            startDateTokens[0], startDateTokens[1] - 1, startDateTokens[2],
            0, startTimeZoneDeviationInMinutes, 0, 0
        )
    );
    const utcEndDate = new Date(
        Date.UTC(
            endDateTokens[0], endDateTokens[1] - 1, endDateTokens[2],
            23, (59 + endTimeZoneDeviationInMinutes), 59, 999
        )
    );
    return {
        utcStartDate,
        utcEndDate
    }

}

// export function getPreviousYearDate(dateString: string) {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const previousYear = year - 1;
//     date.setFullYear(previousYear);
//     return date;
// }
/**
 * function computes interval reports from an array of contacts
 * @param filter
 * @param contacts
 * @returns IContactReportResult
 */

export function getSourceReport(filter: IContactReportFilterParams , contacts: Contact[] | undefined, useUTC: boolean = true): IContactReportResult {
    let dataset: IContactReportResult = {};
    if (contacts) {
        const timeIntervals = getReportSubTimeIntervals(filter, useUTC);
        for (const contact of contacts) {
            for (const interval of timeIntervals) {
                const key = `${interval.start}${DATE_RANGE_SEPARATOR}${interval.end}`;
                // Initialize the entries for the current key if not already.
                dataset[key] ??= [];
                const sentinelDate = filter.report === 'sales' ? contact.source['properties'].order_date : contact.source['properties'].createdate
                if ((interval.start <= sentinelDate) && (sentinelDate <= interval.end)) {
                    // Add new snapshot to existing list for key
                    dataset[key] = [...dataset[key], contact];
                }
            }
        }

        //change interval keys to local time

        dataset = Object.entries(dataset).reduce<IContactReportResult>((acc, [key, values]) => {
            const intervals = key.split(DATE_RANGE_SEPARATOR)
            const startInterval = getTimeZoneDate(intervals[0], filter.timeZone).toISOString();
            const endInterval = getTimeZoneDate(intervals[1], filter.timeZone).toISOString();
            acc[(`${startInterval}${DATE_RANGE_SEPARATOR}${endInterval}`)] = values;

            return acc;
        }, {});

    }

    return dataset;
}

/**
 * functions computes the various intervals of a report from the start date to the end date
 * @param filter
 * @returns IReportTimeInterval[]
 */
export function getReportSubTimeIntervals(filter: IContactReportFilterParams | ISurveyReportFilterParams, useUTC: boolean = true): IReportTimeInterval[] {
    // @ts-ignore
    const viewType = getReportPeriod(filter);
    const periodicIntervals: IReportTimeInterval[] = [];
    const startDate = getTimeZoneDate(filter.startDateTime);// new Date("2023-01-01")
    const endDate = getTimeZoneDate(filter.endDateTime);


    let startDateTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    let endDateTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
    if (useUTC){
        const {utcStartDate, utcEndDate} = getEndDateAndStartDateInUTC(filter.startDateTime,filter.endDateTime,filter.timeZone);
        startDateTime = utcStartDate;
        endDateTime = utcEndDate;
    }
    // const startDateTimeStrTokens = filter.startDateTime.split('-').map((t) => parseInt(t));
    // const endDateTimeStrTokens = filter.endDateTime.split('-').map((t) => parseInt(t));


    if (viewType === 'daily') {

        const startOfInterval = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate(), startDateTime.getHours(), startDateTime.getMinutes(), startDateTime.getSeconds(), startDateTime.getMilliseconds());
        const endOfInterval = new Date(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(), endDateTime.getHours(), endDateTime.getMinutes(), endDateTime.getSeconds(), endDateTime.getMilliseconds());
        let _startSubInterval = startOfInterval;
        do {

            const _nextStartSubInterval = date.addHours(_startSubInterval, 24);
            periodicIntervals.push({
                start: _startSubInterval.toISOString(),
                end: date.addMilliseconds(_nextStartSubInterval, -1).toISOString()
            });
            _startSubInterval = _nextStartSubInterval;

        } while (periodicIntervals.at(-1)!.end < endOfInterval.toISOString())

    } else if (viewType === 'weekly') {

        const startOfInterval = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), (startDateTime.getDate() - startDateTime.getDay()), startDateTime.getHours(), startDateTime.getMinutes(), startDateTime.getSeconds(), startDateTime.getMilliseconds());
        const endOfInterval = new Date(endDateTime.getFullYear(), endDateTime.getMonth(), (endDateTime.getDate() + (6 - endDateTime.getDay())), endDateTime.getHours(), endDateTime.getMinutes(), endDateTime.getSeconds(), endDateTime.getMilliseconds());

        let _startSubInterval = startOfInterval;
        do {

            const _nextStartSubInterval = date.addDays(_startSubInterval, 7);
            periodicIntervals.push({
                start: _startSubInterval.toISOString(),
                end: date.addMilliseconds(_nextStartSubInterval, -1).toISOString()
            });
            _startSubInterval = _nextStartSubInterval;

        } while (periodicIntervals.at(-1)!.end < endOfInterval.toISOString())

    } else if (viewType === "monthly") {

        // const startOfInterval = new Date(startDateTimeStrTokens[0], startDateTimeStrTokens[1] -1, 1, 0, 0, 0, 0);
        // const endOfInterval = new Date(endDateTimeStrTokens[0], endDateTimeStrTokens[1] -1, new Date(endDateTimeStrTokens[0], endDateTimeStrTokens[1], 0).getDate(), 23, 59, 59, 999);
        const startOfInterval = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate(), startDateTime.getHours(), startDateTime.getMinutes(), startDateTime.getSeconds(), startDateTime.getMilliseconds());
        const endOfInterval = new Date(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(), endDateTime.getHours(), endDateTime.getMinutes(), endDateTime.getSeconds(), endDateTime.getMilliseconds());
        let _startSubInterval = startOfInterval;

        do {

            // const _nextStartSubInterval = new Date(_startSubInterval.getFullYear(), ( _startSubInterval.getMonth() + 1), 1) //date.addMonths(_startSubInterval, 1);
            const _nextStartSubInterval = date.addDays(_startSubInterval, getDaysInMonth(_startSubInterval.getFullYear(),(_startSubInterval.getMonth() + 1))) ;
            periodicIntervals.push({
                start: _startSubInterval.toISOString(),
                end: date.addMilliseconds(_nextStartSubInterval, -1).toISOString()
            });
            _startSubInterval = _nextStartSubInterval;

        } while (periodicIntervals.at(-1)!.end < endOfInterval.toISOString())

    } else if (viewType === "yearly") {
        const startOfInterval = new Date(startDateTime.getFullYear(), 0, startDateTime.getDate(), startDateTime.getHours(), startDateTime.getMinutes(), startDateTime.getSeconds(), startDateTime.getMilliseconds());
        const endOfInterval = new Date(endDateTime.getFullYear(), 11, endDateTime.getDate(), endDateTime.getHours(), endDateTime.getMinutes(), endDateTime.getSeconds(), endDateTime.getMilliseconds());

        let _startSubInterval = startOfInterval;
        do {

            const _nextStartSubInterval = date.addYears(_startSubInterval, 1);
            periodicIntervals.push({
                start: _startSubInterval.toISOString(),
                end: date.addMilliseconds(_nextStartSubInterval, -1).toISOString()
            });
            _startSubInterval = _nextStartSubInterval;

        } while (periodicIntervals.at(-1)!.end < endOfInterval.toISOString())
    }
    return periodicIntervals;
}

export function getReportSubTimeIntervals_KEEP(filter: IContactReportFilterParams): IReportTimeInterval[] {

    const periodicIntervals: IReportTimeInterval[] = [];

    const startDate = new Date(filter.startDateTime);
    const endDate = new Date(filter.endDateTime);

    const startDateTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    const endDateTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);

    if (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_WEEKLY) {

        // Sunday based start of week
        const startOfInterval = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), (startDateTime.getDate() - startDateTime.getDay()), startDateTime.getHours(), startDateTime.getMinutes(), startDateTime.getSeconds(), startDateTime.getMilliseconds());
        const endOfInterval = new Date(endDateTime.getFullYear(), endDateTime.getMonth(), (endDateTime.getDate() + (6 - endDateTime.getDay())), endDateTime.getHours(), endDateTime.getMinutes(), endDateTime.getSeconds(), endDateTime.getMilliseconds());

        periodicIntervals.push({
            start: startOfInterval,
            end: date.addMilliseconds(date.addDays(startOfInterval, 7), -1)
        });

        while (periodicIntervals.at(-1)!.end < endOfInterval) {
            const lastSubInterval = periodicIntervals.at(-1);
            periodicIntervals.push({
                start: date.addDays(lastSubInterval!.start as Date, 7),
                end: date.addDays(lastSubInterval!.end as Date, 7)
            });
        }

    } else if (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_MONTHLY) {

        const startOfInterval = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), 1, 0, 0, 0, 0);
        const endOfInterval = new Date(endDateTime.getFullYear(), endDateTime.getMonth(), getDaysInMonth(endDateTime.getFullYear(), endDateTime.getMonth() + 1), 23, 59, 59, 999);

        periodicIntervals.push({
            start: startOfInterval,
            end: date.addMilliseconds(date.addMonths(startOfInterval, 1), -1)
        });

        while (periodicIntervals.at(-1)!.end < endOfInterval) {
            const lastSubInterval = periodicIntervals.at(-1);
            periodicIntervals.push({
                start: date.addMonths(lastSubInterval!.start as Date, 1),
                end: date.addMonths(lastSubInterval!.end as Date, 1)
            });
        }

    } else if (filter.reportViewType === ReportViewTypes.AGGREGATE_SOURCE_YEARLY) {

        const startOfInterval = new Date(startDateTime.getFullYear(), 0, 1, 0, 0, 0, 0);
        const endOfInterval = new Date(endDateTime.getFullYear(), 11, 31, 23, 59, 59, 999);

        periodicIntervals.push({
            start: startOfInterval,
            end: date.addMilliseconds(date.addYears(startOfInterval, 1), -1)
        });

        while (periodicIntervals.at(-1)!.end < endOfInterval) {
            const lastSubInterval = periodicIntervals.at(-1);
            periodicIntervals.push({
                start: date.addYears(lastSubInterval!.start as Date, 1),
                end: date.addYears(lastSubInterval!.end as Date, 1)
            });
        }

    }

    return periodicIntervals.map((interval) => ({
        start: typeof (interval.start) === "string" ? interval.start : (interval.start as Date).toISOString(),
        end: typeof (interval.end) === "string" ? interval.end : (interval.end as Date).toISOString()
    }));
}
export function modifyContactReportFilter(filter: IPaginatedContactReportFilterParams):IPaginatedContactReportFilterParams{
    filter.comparisonStateEndDate = getComparisonStateEndDate(filter);
    const{franchisee,...rest}= filter;
    return {
        ...rest,
        timeZoneDeviationInMinutes:getTimeZoneDeviationInMinutes(filter.startDateTime),
        pageSize: (filter?.reportViewType !== ReportViewTypes.TABLE) ? -1 : filter.pageSize
    }
}


