import {Role} from "../role/role.models";
import {FranchiseeMembership} from "../../hubspot";

const Parse = require('parse/dist/parse');

export class User extends Parse.User {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(attributes?: Parse.Attributes) {
        super(attributes)
    }

    get name(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get firstName(): string {
        return this.get('firstName');
    }
    set firstName(value: string) {
        this.set('firstName', value);
    }
    get lastName(): string {
        return this.get('lastName');
    }
    set lastName(value: string) {
        this.set('lastName', value);
    }
    get email(): string {
        return this.getEmail() || this.get('email')
    }
    set email(value: string) {
        this.setEmail(value)
        //this.set('email', value)
        this.username = value
    }

    get username(): string {
        return this.getUsername() || this.get('username')
    }
    set username(value: string) {
        this.setUsername(value)
    }

    get phone(): string {
        return this.get('phone');
    }
    set phone(value) {
        this.set('phone', value);
    }

    get password(): string {
        return this.get('password');
    }
    set password(value: string) {
        this.set('password', value);
    }

    get dateOfBirth(): string {
        return this.get('dateOfBirth');
    }
    set dateOfBirth(value) {
        this.set('dateOfBirth', value);
    }

    get languageCode(): string {
        return this.get('languageCode');
    }
    set languageCode(value) {
        this.set('languageCode', value);
    }

    get addressLine1(): string {
        return this.get('addressLine1');
    }
    set addressLine1(value) {
        this.set('addressLine1', value);
    }

    get addressLine2(): string {
        return this.get('addressLine2');
    }
    set addressLine2(value) {
        this.set('addressLine2', value);
    }

    get about(): string {
        return this.get('about');
    }
    set about(value) {
        this.set('about', value);
    }

    get acceptTerms(): boolean {
        return this.get('acceptTerms');
    }
    set acceptTerms(value: boolean) {
        this.set('acceptTerms', value);
    }

    get approved(): boolean {
        return this.get('approved');
    }
    set approved(value) {
        this.set('approved', value);
    }

    get archived(): boolean {
        return this.get('archived');
    }
    set archived(value) {
        this.set('archived', value);
    }

    get photo(): Parse.File {
        return this.get('photo');
    }
    set photo(value) {
        this.set('photo', value);
    }

    get photoURL(): string | undefined {
        return this.photo?.url();
    }

    get emailVerified(): boolean {
        return this.get('emailVerified');
    }

    get meta(): Record<string, any> {
        return this.get('meta');
    }
    set meta(value) {
        this.set('meta', value);
    }

    get roles(): Role[] {
        return this.get('roles');
    }
    set roles(value) {
        this.set('roles', value);
    }
    get franchiseeMemberships(): FranchiseeMembership[] {
        return this.get('franchiseeMemberships');
    }
    set franchiseeMemberships(value) {
        this.set('franchiseeMemberships', value);
    }
    get displayedBetaNotification(): boolean {
        return this.get('displayedBetaNotification') ?? false;
    }
    set displayedBetaNotification(value) {
        this.set('displayedBetaNotification', value);
    }
}