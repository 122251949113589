// Read more: https://create-react-app.dev/docs/adding-custom-environment-variables/
// process.env.NODE_ENV, process.env.PUBLIC_URL

const config = {
    APP_URL: 'http://localhost:3000',
    PARSE_SERVER_APPLICATION_ID: 'rpd',
    PARSE_SERVER_JAVASCRIPT_KEY: 'rpd',
    PARSE_SERVER_URL: 'http://localhost:1337/parse',
    PARSE_SERVER_ENCRYPT_USER_SECRET: ''
}
if (process.env.NODE_ENV !== 'development'){
    Object.assign(config, {
        APP_URL: process.env.REACT_APP_URL || process.env.PUBLIC_URL || 'http://localhost:3000',
        PARSE_SERVER_APPLICATION_ID: process.env.REACT_APP_PARSE_SERVER_APPLICATION_ID || 'rpd',
        PARSE_SERVER_JAVASCRIPT_KEY: process.env.REACT_APP_PARSE_SERVER_JAVASCRIPT_KEY || 'rpd',
        PARSE_SERVER_URL: process.env.REACT_APP_PARSE_SERVER_URL || 'http://localhost:1337/parse',
        PARSE_SERVER_ENCRYPT_USER_SECRET: process.env.REACT_APP_PARSE_SERVER_ENCRYPT_USER_SECRET || ''
    });
}

export default config;
