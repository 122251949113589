import { User } from 'lib/auth';
import {HubSpotToParseObject, ISocialMediaLink} from 'lib/common';
import { List } from 'lib/hubspot/list';
import {Territory} from "../territory";
import {IFranchiseeGoals} from "../franchisee-goal";
import {BusinessLocation} from "../../customer-experience";
const Parse = require('parse/dist/parse');

export class Franchisee extends HubSpotToParseObject {
    constructor() {
        super('Franchisee');
    }

    get shortName(): string {
        return this.get('shortName');
    }
    set shortName(value) {
        this.set('shortName', value);
    }

    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }

    get headline(): string {
        return this.get('headline');
    }
    set headline(value) {
        this.set('headline', value);
    }

    get addressLine1(): string {
        return this.get('addressLine1');
    }
    set addressLine1(value) {
        this.set('addressLine1', value);
    }

    get addressLine2(): string {
        return this.get('addressLine2');
    }
    set addressLine2(value) {
        this.set('addressLine2', value);
    }

    get zipCode(): string {
        return this.get('zipCode');
    }
    set zipCode(value) {
        this.set('zipCode', value);
    }

    get city(): string {
        return this.get('city');
    }
    set city(value) {
        this.set('city', value);
    }

    get county(): string {
        return this.get('county');
    }
    set county(value) {
        this.set('county', value);
    }

    get state(): string {
        return this.get('state');
    }
    set state(value) {
        this.set('state', value);
    }

    get country(): string {
        return this.get('country');
    }
    set country(value) {
        this.set('country', value);
    }

    get phone(): string {
        return this.get('phone');
    }
    set phone(value) {
        this.set('phone', value);
    }

    get email(): string {
        return this.get('email');
    }
    set email(value) {
        this.set('email', value);
    }

    get pathname(): string {
        return this.get('pathname');
    }
    set pathname(value) {
        this.set('pathname', value);
    }

    get silhouetteImageURL(): string {
        return this.get('silhouetteImageURL');
    }
    set silhouetteImageURL(value) {
        this.set('silhouetteImageURL', value);
    }

    get phoneCTA(): string {
        return this.get('phoneCTA');
    }
    set phoneCTA(value) {
        this.set('phoneCTA', value);
    }

    get hfsURL(): string {
        return this.get('hfsURL');
    }
    set hfsURL(value) {
        this.set('hfsURL', value);
    }

    get geoPoint(): Parse.GeoPoint {
        return this.get('geoPoint');
    }
    set geoPoint(value) {
        this.set('geoPoint', value);
    }

    get onboardDate(): Date {
        return this.get('onboardDate');
    }
    set onboardDate(value) {
        this.set('onboardDate', value);
    }

    get territories(): Territory[] {
        return this.get('territories') || [];
    }
    set territories(value) {
        this.set('territories', value);
    }

    get avgRating(): number {
        return this.get('avgRating');
    }
    set avgRating(value) {
        this.set('avgRating', value);
    }

    get totalRating(): number {
        return this.get('topRating');
    }
    set totalRating(value) {
        this.set('topRating', value);
    }

    get socialLinks(): ISocialMediaLink[] {
        return this.get('socialLinks');
    }
    set socialLinks(value) {
        this.set('socialLinks', value);
    }

    get lists(): List[] {
        return this.get('lists') || [];
    }
    set lists(value) {
        this.set('lists', value);
    }

    get members(): User[] {
        return this.get('members');
    }
    set members(value) {
        this.set('members', value);
    }
    get goals(): IFranchiseeGoals {
        return this.get('goals');
    }
    set goals(value) {
        this.set('goals', value);
    }
    get businessLocation(): BusinessLocation {
        return this.get('businessLocation');
    }
    set businessLocation(value) {
        this.set('businessLocation', value);
    }

}
Parse.Object.registerSubclass('Franchisee', Franchisee);