import {Variant} from "react-bootstrap/types";
import {Role, User} from "../auth";
import {Franchisee, IDealResults} from "../hubspot";
import { IContactResultsWithCount} from "../report";
import {ISurveyReportResultsWithCount} from "../report/birdEye";

const Parse = require('parse/dist/parse');

export interface IAlert {
    message?: string | null,
    variant?: Variant
    dismissible?: boolean
    escaped?: boolean
}


export type SocialMediaType =
    | 'facebook'
    | 'linkedin'
    | 'tiktok'
    | 'pinterest'
    | 'instagram'
    | 'whatsapp'
    | 'youtube';
export interface IDate {
    year: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number,
    ms: number
}

export interface ISocialMediaLink {
    type: SocialMediaType
    url: string
}
export interface ISort {
    key: string
    order: SortDirection
}
export interface IFetchFilter {
    keyword?: string; // Keyword(s) to filter or query by
    page?: number; // The page number starting at 1
    pageSize?: number; // Number of items per page
    skip?: number; // Number of items to skip
    withCount?: boolean; // Whether to return reports with count
    sorts?: ISort[];
    sessionToken?: string;
}
 export interface IReportFilter {
    startDateTime: string; // Start date time in iso format
    endDateTime: string; // End date time in iso format
    timeZone?: string;
     pageSize?: number;
     page?: number;
     withCount?: boolean;
     comparisonStateEndDate?:StateEndDate;
     compareData?: boolean;
     timeZoneDeviationInMinutes?: number;
     year?: string;
     orderBy?: string;
     order?: SortDirection
}
export enum ReportViewTypes {
    TABLE = 'table',
    DASHBOARD = 'dashboard',
    AGGREGATE_SOURCE_WEEKLY = 'aggregateSourceWeekly',
    AGGREGATE_SOURCE_MONTHLY = 'aggregateSourceMonthly',
    AGGREGATE_SOURCE_YEARLY = 'aggregateSourceYearly',
    AGGREGATE_SALES_WEEKLY = 'aggregateSalesWeekly',
    AGGREGATE_SALES_MONTHLY = 'aggregateSalesMonthly',
    AGGREGATE_SALES_YEARLY = 'aggregateSalesYearly',
    AGGREGATE_DEALS_WEEKLY = 'aggregateDealsWeekly',
    AGGREGATE_DEALS_MONTHLY = 'aggregateDealsMonthly',
    AGGREGATE_DEALS_YEARLY = 'aggregateDealsYearly'

}
export abstract class HubSpotToParseObject extends Parse.Object {

    protected constructor(className?: string) {
        super(className);
    }

    get uid(): string {
        return this.get('uid');
    }
    set uid(value) {
        this.set('uid', value);
    }

    get source(): Record<string, any> {
        return this.get('source');
    }
    set source(value) {
        this.set('source', value);
    }

    get modified(): Date {
        return this.get('modified');
    }
    set modified(value) {
        this.set('modified', value);
    }

    get meta(): Record<string, any> {
        return this.get('meta');
    }
    set meta(value) {
        this.set('meta', value);
    }

}
export interface IStatisticPeriod {
    type: 'month'|'year'|'week'|'day',
    total: number //The number of months or years or weeks or days you want to compare results to
}
export type StateEndDate ={
    startDate: string,
    endDate: string,
}
export interface IStatisticFilter extends IReportFilter{
    comparisonPeriod?:IStatisticPeriod,
    comparisonStateEndDate?:StateEndDate
}
export interface IContactStatistics{
    current: IContactResultsWithCount
    previous: IContactResultsWithCount
}
export interface IStatistics {
    contact:IContactResultsWithCount,
    sales: IContactResultsWithCount,
    surveyResponses: ISurveyReportResultsWithCount
    deals: IDealResults
}
export type StartAndEndDate = {startDateTime: string, endDateTime: string}
export type ReactSelectOptions = {value: string, label: string}
export type FranchiseeSelectOptions = {value: Franchisee, label: string}
export type ReactUserSelectOptions = {value: User, label: string}
export type ReactRoleSelectOptions = {value: Role, label: string}
export type QuarterAndYear = {year: number, quarter: number}
export type SortDirection = 'descending' | 'ascending'
export type SortDirectionConfig = { key: string, direction: SortDirection }
export interface ISortable {
    [key: string]: any;
}
export interface IReactGAEvent {
    category: string
    action: string
    label: string
    value?: number
    nonInteraction?: boolean
    transport?: 'beacon'|'xhr'|'image'
}
