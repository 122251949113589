import {CloudFunctions} from "../../parse";
import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {IPipelineResults} from "./pipeline.interfaces";
import {Pipeline} from "./pipeline.models";
const Parse = require('parse/dist/parse');


/**
 * Syncs Pipeline in hubspot with that of parse server
 **/

export async function syncPipelines() : Promise<any>{
    Parse.Cloud.run(CloudFunctions.SYNC_PIPELINES).then((response: any) =>{
        return response;
    }).catch((error: any) => {
        throw(error)
    });
}

/**
 * Fetches a paginated list of Pipelines from app internal database
 * @param filter filter parameter of type [IFetchFilter]
 */
export async function getPipelines(filter?: IFetchFilter) : Promise<IPipelineResults>{

    if (filter?.pageSize === -1){
        return await Parse.Cloud.run(CloudFunctions.FETCH_PIPELINES, filter) as IPipelineResults;
    }
    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(Pipeline).matches('uid', keyword, 'i'),
            new Parse.Query(Pipeline).matches('name', keyword, 'i'),
        ) :
        new Parse.Query(Pipeline);

    query.skip(skip);
    query.limit(limit);
    query.descending('createdAt');


    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return { results: await query.find() }
    }
}
export async function getPipelineById(id: string): Promise<Pipeline> {
    const query = Parse.Query.or(
        (new Parse.Query(Pipeline)).equalTo("uid", `${id}`),
        (new Parse.Query(Pipeline)).equalTo("source.id", `${id}`),
        (new Parse.Query(Pipeline)).equalTo("objectId", `${id}`)
    );
    query.includeAll();
    return await query.first();
}
