import {User} from "../auth";
const Parse = require('parse/dist/parse');

export class Feedback extends Parse.Object {
    constructor() {
        super('Feedback');
    }
    get topics(): string {
        return this.get('topics');
    }
    set topics(value) {
        this.set('topics', value);
    }
    get email(): string {
        return this.get('email');
    }
    set email(value) {
        this.set('email', value);
    }
    get description(): string {
        return this.get('description');
    }
    set description(value) {
        this.set('description', value);
    }
    get user(): User {
        return this.get('user');
    }
    set user(value) {
        this.set('user', value);
    }
}
