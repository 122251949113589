import {HubSpotToParseObject} from "../../common";
import {ListMembership} from "../list-membership";
import {Deal} from "../deal";

export class Contact extends HubSpotToParseObject {
    constructor() {
        super('Contact');
    }

    get firstName(): string {
        return this.get('firstName');
    }
    set firstName(value) {
        this.set('firstName', value);
    }

    get lastName(): string {
        return this.get('lastName');
    }
    set lastName(value) {
        this.set('lastName', value);
    }

    get name(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get email(): string {
        return this.get('email');
    }
    set email(value) {
        this.set('email', value);
    }

    get phone(): string {
        return this.get('phone');
    }
    set phone(value) {
        this.set('phone', value);
    }

    get listMemberships(): ListMembership[] {
        return this.get('listMemberships') || [];
    }
    set listMemberships(value) {
        this.set('listMemberships', value);
    }
    get deals(): Deal[] {
        return this.get('deals') || [];
    }
    set deals(value) {
        this.set('deals', value);
    }
}
