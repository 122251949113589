import { User } from 'lib/auth';
// import { Parse } from 'lib/parse'
import {HubSpotToParseObject} from "../../common";
const Parse = require('parse/dist/parse');

export class LifeCycleStage extends HubSpotToParseObject {
    constructor() {
        super('LifeCycleStage');
    }

    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }

    get description(): string {
        return this.get('description');
    }
    set description(value) {
        this.set('description', value);
    }

    get readOnly(): boolean {
        return this.get('readOnly');
    }
    set readOnly(value) {
        this.set('readOnly', value);
    }

    get hidden(): boolean {
        return this.get('hidden');
    }
    set hidden(value) {
        this.set('hidden', value);
    }

    get displayOrder(): number {
        return this.get('displayOrder');
    }
    set displayOrder(value) {
        this.set('displayOrder', value);
    }

    get doubleData(): boolean {
        return this.get('doubleData');
    }
    set doubleData(value) {
        this.set('doubleData', value);
    }

    get contacts(): Parse.Relation {
        return this.relation('contacts');
    }

    get createdBy(): User {
        return this.get('createdBy');
    }
    set createdBy(value) {
        this.set('createdBy', value);
    }

}
Parse.Object.registerSubclass('LifeCycleStage', LifeCycleStage);
