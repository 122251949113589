import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {ISurveyResultsWithCount} from "./survey.interfaces";
import {Survey} from "./survey.models";

const Parse = require('parse/dist/parse');

export async function getSurveys(filter?: IFetchFilter): Promise<ISurveyResultsWithCount> {

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(Survey).matches('uid', keyword, 'i'),
            new Parse.Query(Survey).matches('name', keyword, 'i'),
            new Parse.Query(Survey).equalTo('source.responses', parseInt(keyword) ?? 0),
            new Parse.Query(Survey).equalTo('source.questionCount', parseInt(keyword) ?? 0),
            new Parse.Query(Survey).matches('source.ownerName', keyword, 'i'),
            new Parse.Query(Survey).matches('source.ownerEmail', keyword, 'i'),
            new Parse.Query(Survey).matches('source.businessName', keyword, 'i'),
        ) :
        new Parse.Query(Survey);

    query.include('businessLocation');
    // query.rela
    if (limit === -1) {
        return {results: await query.findAll()}
    }

    // limit = 25;

    query.skip(skip);
    query.limit(limit);

    if (filter?.withCount) {
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return {results: await query.find()}
    }
}