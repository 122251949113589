import {BusinessLocation} from "../business-location";
import {HubSpotToParseObject} from "../../common";

export class Survey extends HubSpotToParseObject {
    constructor() {
        super('Survey');
    }
    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }
    get businessLocation(): BusinessLocation {
        return this.get('businessLocation');
    }
    set businessLocation(value) {
        this.set('businessLocation', value);
    }

}
