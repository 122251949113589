import Routes from 'routes/Routes';
import ReactGA from 'react-ga4';

import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// For Default import Saas.scss
import 'assets/scss/Saas.scss';
import { useEffect } from 'react';
import { useAuthStore } from 'lib/state/zustand';
import { LOCAL_STORAGE_KEYS } from 'lib/auth';


const App = () => {
    const { logOut } = useAuthStore((state) => ({ logOut: state.logOut }));

    const checkForInactivity = () => {

        const loginTime = localStorage.getItem(LOCAL_STORAGE_KEYS.LOGIN_TIME_KEY);

        if (loginTime){
            const timDiff = Date.now() - parseInt(loginTime);         
            if (timDiff > parseInt(process.env.REACT_APP_SESSION_TIME_LIMIT!)) {                
                logOut()
            }
        }

    }

    const updateExpireTime = () => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_TIME_KEY, `${new Date().getTime()}`);
    }

    useEffect(() => {
        const interval = setInterval(() =>{
            checkForInactivity()
        }, 1200000);

        return() => clearInterval(interval)
    }, [])

    useEffect(() => {
        updateExpireTime();

        window.addEventListener('click',updateExpireTime);
        window.addEventListener('keypress',updateExpireTime);
        window.addEventListener('mousemove',updateExpireTime);
        window.addEventListener('scroll',updateExpireTime);

        return () => {
            window.addEventListener('click',updateExpireTime);
            window.addEventListener('keypress',updateExpireTime);
            window.addEventListener('mousemove',updateExpireTime);
            window.addEventListener('scroll',updateExpireTime);
    }

    }, []);
    //Initialise Google Analytics
    useEffect(() => {
        ReactGA.initialize(  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID ?? 'G-B9M7H8PVE2');
    }, []);



    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: ( 60 * 1000 ), // 60 secs for stale time
                // refetchOnWindowFocus: false,
            }
        }
    });
    return (
        <QueryClientProvider client={queryClient}>
            <Routes />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default App;
