import {HubSpotToParseObject} from "../../common";
import {Survey} from "../survey";

export class SurveyResponse extends HubSpotToParseObject {
    constructor() {
        super('SurveyResponse');
    }
    get requestDate(): string {
        return this.get('requestDate');
    }
    set requestDate(value) {
        this.set('requestDate', value);
    }
    get responseDate(): string {
        return this.get('responseDate');
    }
    set responseDate(value) {
        this.set('responseDate', value);
    }
    get survey(): Survey {
        return this.get('survey');
    }
    set survey(value) {
        this.set('survey', value);
    }

}
