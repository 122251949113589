import { IModulePermission, IPermissionData, IUserPermissions } from './permission.interface';
import { Permission } from './permission.models';

export function groupPermissions(data: Permission[]): IPermissionData {
    return data.reduce((acc, permission) => {
        const key = permission.module;
        // @ts-ignore
        acc[key] ??= [];
        // @ts-ignore
        acc[key].push(permission);
        return acc;
    }, {}) as IPermissionData;
}

export function userHasPermission(user: IUserPermissions, permission: IModulePermission): boolean {
    if (permission && user.permissions?.length) {
        return user.permissions.some((perm) => perm.module === permission.module && perm.key === permission.key);
    }

    return false;
}

export function userHasAnyPermission(user: IUserPermissions, permissions: IModulePermission[]): boolean {
    if (!permissions.length) return true;
    return permissions.some((permission) =>
        user.permissions.some(
            (userPermission) => userPermission.module === permission.module && userPermission.key === permission.key
        )
    );
}
