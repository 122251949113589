import {IReportFilter} from "../../common";
import {Franchisee} from "../../hubspot";
import {IReportIntervalsData} from "../contact";

export interface IAdsFilter extends IReportFilter {
    authenticationCode?: any,
    franchiseId: string,
    franchisee?: Franchisee,
    loading?: boolean,
    reportViewType?: AdsViewTypes
}

export enum AdsViewTypes {
    CAMPAIGNS = "campaigns",
    SEARCH_KEYWORDS = "searchKeyword",
    AUCTION_INSIGHTS = "auctionInsights"
}

export type GoogleAdsCampaignsTableData = {
    id: string;
    campaign: string;
    impressions: number;
    clicks: number;
    ctr: string;
    conversions: number | string;
};
export type GoogleAdsCampaignsCardsData = {
    avgCPC: string;
    costsPerConversions: string;
    costs: string;
    conversions: string;
};
export type GoogleAdsSearchKeywordsData = {
    keyword: string,
    cost: string,
    impressions: number,
    clicks: number,
    conversions: number
}
export type GoogleAdsAuctionInsightData = {
    id: string,
    insightDomain: string,
    impressionShare: string,
    positionAboveRate: string,
    overlapRate: string,
    topOfPageRate: string
}

export interface IGoogleAdsCampaignsReport {
    tableReport?: GoogleAdsCampaignsTableData[];
    cardsReport?: GoogleAdsCampaignsCardsData;
    impressionGraphData?: IReportIntervalsData;
    clicksAndConversionGraphData?: IReportIntervalsData;
    periodicInterval?: string[]
}

export interface IGoogleAdsReport {
    results?: {
        campaigns?: IGoogleAdsCampaignsReport;
        searchKeywords?: GoogleAdsSearchKeywordsData[];
        auctionInsight?: GoogleAdsAuctionInsightData[]
    }

    url?: string
}