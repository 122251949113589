import {Navigate, useLocation} from 'react-router-dom';
import {useAuthStore} from "../lib/state/zustand";
import {routesPermissions} from "../lib/auth/permission/routes.permissions";
import {userHasAnyPermission} from "../lib/auth/permission/helpers";
import {User} from "../lib/auth";

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({component: RouteComponent, roles, ...rest}: PrivateRouteProps) => {
    const location = useLocation();
    const {isCurrent, isAuthenticated, currentUser, permissions} = useAuthStore((state) => ({
        isCurrent: state.isCurrent,
        isAuthenticated: state.isAuthenticated,
        currentUser: state.currentUser,
        permissions: state.permissions

    }));

    if (isAuthenticated()) {
        // Todo: Renenable role and permission based access when the feature is implement
        let hasAccess = true;
        Object.entries(routesPermissions).forEach(([key, route]) => {
            if ((route.url === location.pathname)) {
                if (route.permissions.length){
                    hasAccess = userHasAnyPermission({
                        user: currentUser as User,
                        permissions: permissions!
                    }, route.permissions);
                }
            }
        });

        // validate all protected routes
        if (hasAccess ){
            return <RouteComponent/>;
        }else {
            return <Navigate to={{ pathname: routesPermissions['welcome'].url}} />;
        }



    } else if (isCurrent()) {
        return <Navigate to={'/account/confirm'} state={{from: location}} replace/>;
    } else {
        return <Navigate to={'/account/login'} state={{from: location}} replace/>;
    }
};

export default PrivateRoute;
