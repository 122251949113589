import {IReportFilter, ReportViewTypes, SortDirection} from "../../common";
import {SurveyResponse} from "../../customer-experience/survey-response";
import {IReportIntervalsData, IReportIntervalsWithTotal} from "../contact";
import {DealStage} from "../../hubspot/deal-stage/deal-stage.models";
import {Contact} from "../../hubspot";

export type Interval = 'monthly'| 'yearly'| 'weekly' | 'daily';
export interface ISurveyReportFilterParams extends IReportFilter{
    surveyId?: string,
    keyword?: string,
    businessNumber?: string,
    franchiseeId?: string,
    from?: string | number,
    orderBy?: string,
    order?: SortDirection,
    reportViewType: ReportViewTypes,
    interval?: Interval,
    withSentSurveys?: boolean,
    questionTitle?: string //hdyf =How do you feel
}
export interface ISurveyReportResultsWithCount {
    results: SurveyResponse[]
    count?: number
    sentSurveys?: DealStage[]
    previousData?: {
        results: Contact[]
        count?: number
    }
}
export interface ISurveyResponse {
    [isoStartEnd: string]: SurveyResponse[]
}
export interface ISurveySent {
    [isoStartEnd: string]: DealStage[]
}
// export interface ISurveyReportResult {
//     [isoStartEnd: string]: SurveyResponse[]
// }
export interface ISurveyReportResult {
    surveyResponses: ISurveyResponse,
    surveySent: ISurveySent | ISurveyResponse
}

export interface ILocationSurveys extends IReportIntervalsWithTotal{
    // subSources: IReportIntervals,
    surveys: IReportIntervalsData,
}
export interface ILocationSurveysData {
    [location: string]: ILocationSurveys
}
export interface IResponseAnswer{
    "questionTitle": string,
    "questionType": string,
    "visible": boolean,
    "conditional": boolean,
    "shownToCustomer": boolean,
    "hidden": boolean,
    "maxValue": number,
    "minValue": number,
    "showTime": boolean,
    "showDate": boolean,
    "answer": string
}
export interface IResponseAnswerWithChildAnswers extends IResponseAnswer{
    childAnswers?: IResponseAnswer[]
}
export interface IIntervalWithAverageData {
    [interval: string]: {
        total: number, // sum of all how to you feel response
        count: number, //number of how do you feel questions
        average: number // Average hDYF for a given interval
    }
}
export interface IFranchiseeIntervalWithAverageData {
    [franchisee: string]: IIntervalWithAverageData
}
export enum trackerQuestions {
    RECOMMEND_US = 'Considering your experience with River Pools on a scale of\n' +
        '0-10, how likely are you to recommend us to friends or family?',
    HOW_DO_YOU_FEEL = 'How do you feel about the process so far?',
    QUALITY_OF_COMMUNICATION = 'How would you rate the quality of communication?'
}
export interface ISurveyResponseReports {
    surveyCompletionRate?: IReportIntervalsData;
    overAllScore?: ILocationSurveysData;
    howYouFeelTracker?: ILocationSurveysData;
    howYouFeelTrackerIntervalReport?: IIntervalWithAverageData;
    communicationScoreAverage?: IIntervalWithAverageData;
    overallScoreAverage?: IIntervalWithAverageData;
    periodicInterval?: string[];
    tableData?: ISurveyReportResultsWithCount;
}
export interface IStatisticsReport {
    leadsData?:IReportIntervalsData;
    lcsData?: IReportIntervalsData;
    salesData?: IReportIntervalsData;
    howYouFeelTrackerIntervalReport?: IIntervalWithAverageData;
    franchiseeSalesData?:IReportIntervalsData;
    franchiseeLeadsData?: IReportIntervalsData;
    periodicInterval?: string[];
    franchiseeHowYouFeelTrackerIntervalReport?: IFranchiseeIntervalWithAverageData;
}
export interface ISurveyResponseTableData {
    id: string;
    uid: string;
    customerName: string;
    customerEmail: string;
    name: string;
    overallScore: string;
    businessLocationName: string;
    surveyResponse: SurveyResponse

}
