import {HubSpotToParseObject} from "../../common";
import {PipelineStage} from "../pipeline-stage";
import {Contact} from "../contact";

export class Deal extends HubSpotToParseObject {
    constructor() {
        super('Deal');
    }
    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }

    get amount(): string {
        return this.get('amount');
    }
    set amount(value) {
        this.set('amount', value);
    }

    get pipelineStage(): PipelineStage  {
        return this.get('pipelineStage');
    }
    set pipelineStage(value) {
        this.set('pipelineStage', value);
    }

    get contacts(): Parse.Relation {
        return this.relation('contacts');
    }
    get dealContacts(): Contact[] {
        return this.get('dealContacts');
    }
    set dealContacts(value) {
        this.set('dealContacts', value);
    }
}
