import {CloudFunctions} from "../../parse";
import {
    IContactResultsWithCount,
    IContactStatistics,
    IContactStatisticsFilter,
    IConversionFormStatistics,
    ILeadsReport,
    IPaginatedContactReportFilterParams,
    IReportIntervalsData,
    ISalesReport,
} from "./contact.report.interface";
import {getEndDateAndStartDateInUTC, modifyContactReportFilter} from "./helpers";
import {getComparisonStateEndDate, ReportViewTypes} from "../../common";

const Parse = require('parse/dist/parse');


/**
 * Fetches a paginated list of Lists from app internal database
 * @param filter filter parameters
 */
export async function getLeadsReport(filter: IPaginatedContactReportFilterParams) : Promise<ILeadsReport> {
   return  await Parse.Cloud.run(CloudFunctions.FETCH_LEADS_REPORT, modifyContactReportFilter(filter)) as ILeadsReport;
}
export async function getSalesReport(filter: IPaginatedContactReportFilterParams) : Promise<ISalesReport> {
    return  await Parse.Cloud.run(CloudFunctions.FETCH_SALES_REPORT, modifyContactReportFilter(filter)) as ISalesReport;
}

export async function getGoalsReport(filter: IPaginatedContactReportFilterParams) : Promise<IReportIntervalsData> {
    return await Parse.Cloud.run(CloudFunctions.FETCH_GOALS_REPORT, modifyContactReportFilter(filter)) as IReportIntervalsData;
}
export async function getContactReportDetails(filter: IPaginatedContactReportFilterParams) : Promise<IContactResultsWithCount> {
    const{utcStartDate,utcEndDate}  = getEndDateAndStartDateInUTC(filter.startDateTime,filter.endDateTime,filter.timeZone);
    filter.comparisonStateEndDate = getComparisonStateEndDate(filter)
    const{franchisee,...rest}= filter;

    return await Parse.Cloud.run(CloudFunctions.FETCH_CONTACT_REPORT, {
        ...rest,
        startDateTime: utcStartDate.toISOString(),
        endDateTime: utcEndDate.toISOString(),
        pageSize: (filter?.reportViewType !== ReportViewTypes.TABLE) ? -1 : filter.pageSize
    }) as IContactResultsWithCount;
}
export async function getContactStatistics(filter?: IContactStatisticsFilter):Promise<IContactStatistics> {
     return await Parse.Cloud.run(CloudFunctions.GET_CONTACT_STATISTICS,filter) ;
}
export async function getFormConversionStatistics():Promise<IConversionFormStatistics> {
     return await Parse.Cloud.run(CloudFunctions.GET_FORM_CONVERSION_STATISTICS) ;
}

