import {CloudFunctions} from "../../parse";
import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {IPipelineStageFilter, IPipelineStageResults} from "./pipeline-stage.interfaces";
import {PipelineStage} from "./pipeline-stage.models";
import {IPipelineResults} from "../pipeline/pipeline.interfaces";
import {Pipeline} from "../pipeline";
const Parse = require('parse/dist/parse');


/**
 * Fetches a paginated list of Pipelines from app internal database
 * @param filter filter parameter of type [IFetchFilter]
 */
export async function getPipelinesStages(filter?: IPipelineStageFilter) : Promise<IPipelineStageResults>{

    if (filter?.pageSize === -1){
        return await Parse.Cloud.run(CloudFunctions.FETCH_PIPELINES_STAGES, filter) as IPipelineStageResults;
    }
    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(PipelineStage).matches('uid', keyword, 'i'),
            new Parse.Query(PipelineStage).matches('name', keyword, 'i'),
        ) :
        new Parse.Query(PipelineStage);

    if (filter?.pipelineId){
        const pipelineQuery = new Parse.Query(Pipeline);
        pipelineQuery.equalTo('uid', filter.pipelineId);
        query.matchesQuery('pipeline', pipelineQuery);
    }
    query.skip(skip);
    query.limit(limit);

    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return { results: await query.find() }
    }
}
export async function getPipelineStageById(id: string): Promise<PipelineStage> {
    const query = Parse.Query.or(
        (new Parse.Query(PipelineStage)).equalTo("uid", `${id}`),
        (new Parse.Query(PipelineStage)).equalTo("source.id", `${id}`),
        (new Parse.Query(PipelineStage)).equalTo("objectId", `${id}`)
    );
    query.includeAll();
    return await query.first();
}
