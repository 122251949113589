import {CloudFunctions} from "../../parse";
import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {ICompanyResults} from "./company.interfaces";
import {Company} from "./company.models";
const Parse = require('parse/dist/parse');


/**
 * Syncs companies in hubspot with that of parse server
 **/

export async function syncCompanies() : Promise<any>{
    Parse.Cloud.run(CloudFunctions.SYNC_COMPANIES).then((response: any) =>{
        return response;
    }).catch((error: any) => {
        throw(error)
    });
}

/**
 * Fetches a paginated companies from app internal database
 * @param filter filter parameter of type [IFetchFilter]
 */
export async function getCompanies(filter?: IFetchFilter) : Promise<ICompanyResults>{

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword?.trim()) ?
        Parse.Query.or(
            new Parse.Query(Company).matches('uid', keyword.trim(), 'i'),
            new Parse.Query(Company).matches('name', keyword.trim(), 'i'),
            new Parse.Query(Company).matches('email', keyword.trim(), 'i'),
            new Parse.Query(Company).matches('phone', keyword.trim(), 'i'),
        ) :
        new Parse.Query(Company);

    if (filter?.pageSize === -1){
        return await query.findAll();
    }
    query.skip(skip);
    query.limit(limit);

    ///query.includeAll();

    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return { results: await query.find() }
    }
}

export async function getCompanyById(id: string | number): Promise<Company> {
    const query = Parse.Query.or(
        (new Parse.Query(Company)).equalTo("uid", `${id}`),
        (new Parse.Query(Company)).equalTo("source.id", `${id}`),
        (new Parse.Query(Company)).equalTo("objectId", `${id}`)
    );
    query.includeAll();
    return await query.first();
}
