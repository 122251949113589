const Parse = require('parse/dist/parse');
export class Goal extends Parse.Object {
    constructor() {
        super('Goal');
    }
    get description(): string {
        return this.get('description');
    }
    set description(value) {
        this.set('description', value);
    }
    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }
    get isFranchiseeGoal(): boolean {
        return this.get('isFranchiseeGoal');
    }
    set isFranchiseeGoal(value) {
        this.set('isFranchiseeGoal', value);
    }
}