import {CloudFunctions} from "../../parse";
import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {IContactResults} from "./contact.interfaces";
import {Contact} from "./contact.models";
const Parse = require('parse/dist/parse');


/**
 * Syncs lists in hubspot with that of parse server
 **/

export async function syncContacts() : Promise<any>{
    Parse.Cloud.run(CloudFunctions.SYNC_CONTACTS).then((response: any) =>{
        return response;
    }).catch((error: any) => {
        throw(error)
    });
}

export async function getContactSources(data:{keyword: string}): Promise<any> {
     return await Parse.Cloud.run(CloudFunctions.GET_CONTACT_SOURCES_FROM_STATISTICS,data);
}

/**
 * Fetches a paginated lead-source of Contacts from app internal database
 * @param filter filter parameter of type [IFetchFilter]
 */
export async function getContacts(filter?: IFetchFilter) : Promise<IContactResults>{

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(Contact).matches('uid', keyword, 'i'),
            new Parse.Query(Contact).matches('firstName', keyword, 'i'),
            new Parse.Query(Contact).matches('lastName', keyword, 'i'),
            new Parse.Query(Contact).matches('email', keyword, 'i'),
            new Parse.Query(Contact).matches('phone', keyword, 'i'),
        ) :
        new Parse.Query(Contact);

    if (filter?.pageSize === -1){
        return await query.findAll();
    }
    query.skip(skip);
    query.limit(limit);
    query.include(['listMemberships.list.franchisee.name', 'deals.pipelineStage.name']);
    if (filter?.withCount){
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return { results: await query.find() }
    }
}
export async function getContactById(id: string): Promise<Contact> {
    const query = Parse.Query.or(
        (new Parse.Query(Contact)).equalTo("uid", `${id}`),
        (new Parse.Query(Contact)).equalTo("source.id", `${id}`),
        (new Parse.Query(Contact)).equalTo("objectId", `${id}`)
    );

    query.include(['listMemberships', 'deals', 'deals.pipelineStage.pipeline.name']);

    return await query.first();
}