import {IFetchFilter, TABLE_PAGE_SIZE} from "../../common";
import {IBusinessLocationResultsWithCount} from "./business-location.interfaces";
import {BusinessLocation} from "./business-location.models";

const Parse = require('parse/dist/parse');

export async function getBusinessLocations(filter?: IFetchFilter): Promise<IBusinessLocationResultsWithCount> {

    const limit = filter?.pageSize || TABLE_PAGE_SIZE;
    const skip = filter?.skip ?? (((filter?.page || 1) - 1) * limit);
    const keyword = filter?.keyword?.trim();

    const query = (keyword) ?
        Parse.Query.or(
            new Parse.Query(BusinessLocation).matches('uid', keyword, 'i'),
            new Parse.Query(BusinessLocation).matches('name', keyword, 'i'),
            new Parse.Query(BusinessLocation).matches('alias', keyword, 'i'),
            new Parse.Query(BusinessLocation).matches('location.city', keyword, 'i'),
            new Parse.Query(BusinessLocation).matches('location.stateName', keyword, 'i')
        ) :
        new Parse.Query(BusinessLocation);

    if (limit === -1) {
        return {results: await query.findAll()}
    }

    query.skip(skip);
    query.limit(limit);

    if (filter?.withCount) {
        query.withCount();
        // @ts-ignore
        return await query.find();
    } else {
        return {results: await query.find()}
    }
}