import {IReportFilter, ReportViewTypes} from "../../common";
import {Contact} from "../../hubspot/contact";
import {Franchisee} from "../../hubspot";
import {Goal} from "../../auth/goal/goal.models";

export enum ContactAggregatePeriod {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export interface IContactReportFilterParams extends IReportFilter{
    // createdDate?: string, // Date string
    orderedDate?: string, // Date string, if set the startDateTime and endDateTime will be set as the upperbound and the lower bound
    installationDate?: string, // String
    warrantySignedDate?: string, // String
    lifeCycleStage?: string,
    webTrafficSource?: string,
    webTrafficSourceData1?: string,
    webTrafficSourceData2?: string,
    franchisee?: Franchisee,
    franchiseeId?: string, // Franchisee id - if supplied, it will be used if listIds not supplied
    listIds?: string, // Comma separated list of List Ids - if supplied, franchisee will be ignored
    companyId?: string, // Company Id
    rpOnly?: boolean,
    reportViewType: ReportViewTypes,
    keyword?: string,
    report: 'sales' | 'leads',
    withFranchisees?: boolean

}

export type IPaginatedContactReportFilterParams = IContactReportFilterParams & {
    from?: string | number;
    goal?: string; //goal name or goal id

}

export interface IContactSnapshot {
    id?: string,
    uid?: string,
    firstname?: string,
    lastname?: string,
    email?: string,
    phone?: string,
    hs_analytics_source?: string,
    hs_analytics_source_data_1?: string,
    hs_analytics_source_data_2?: string,
    lifecyclestage?: string
    lists?: { id?: string, name?: string, addedAt?: string, franchisee?: string }[]
    createdate?: string,
    lastmodifieddate?: string,
    company?: string,
    serial_number?: string,
    installation_date?: string,
    order_date?: string,
    date_warranty_signed?: string,
    riverpools?: string,
    thursday_pools?: string,
}

export interface IContactSnapshots {
    [isoDate: string]: IContactSnapshot
}
export interface IInterval{
    [interval: string] : number
}
export interface IReportIntervals{
    [key: string]: IInterval
}
export interface IReportIntervalsWithTotal {
    intervals: IInterval,
    total: number,
    average?: number
}
export interface IReportIntervalsData{
    [key:string]: IReportIntervalsWithTotal
}
export interface IMainSource extends IReportIntervalsWithTotal{
    // subSources: IReportIntervals,
    subSources: IReportIntervalsData,
}
export interface ISalesGoals {
    goalsData: IReportIntervalsData,
    franchisee: Franchisee | undefined
}
export interface ILeadsComparisonData {
    current: IReportIntervalsData,
    previous?: IReportIntervalsData
}
export interface IMainSourceReportData {
    [source: string]: IMainSource
}
export interface IFormConversionData {
    conversionType: IReportIntervalsData,
    total: number
}
export interface IFormsConversionsData {
    [form: string]: IFormConversionData
}
export interface IChartDataWithLabels {
    name: string,
    data: number[]
}
export interface IChartDataWithLabels2 {
    name: string ,
    data: {x:string | Date,y:string| number}[]
}

export interface IContactReportResult {
    [isoStartEnd: string]: Contact[]
}
export interface IContactReportResultWithPreviousData {
    currentInterval: IContactReportResult
    previousInterval?: IContactReportResult
}
export interface IRecentConversionForm {
    id: string,
    name: string,
    created?: string
}
export interface IHubspotPropertyWithHistory {
    "value": string
    "timestamp": string
    "sourceType"?: string
    "sourceId"?: string
}

export interface IContactResultsWithCount {
    results: Contact[]
    count: number
    recentConversionForms?: IRecentConversionForm[] | undefined
    goal?: Goal
    numberOfAssignedFranchisees?: number
    previousData?: {
        results: Contact[]
        count?: number
    },
    franchisees?: Franchisee[]
}

export const DATE_RANGE_SEPARATOR = '~'

export const SOURCES = [
    'GOOGLE',
    'FACEBOOK',
    'EMAIL MARKETING',
    'PAID SEARCH',
    'PAID SOCIAL',
    'REFERRALS',
    'DIRECT TRAFFIC',
    'SOCIAL MEDIA',
    'BING',
    'YAHOO',
    'DUCKDUCKGO',
]
export const EXCLUDE_SOURCES = [
    'EMAIL MARKETING',
    // 'PAID SEARCH',
    // 'PAID SOCIAL',
    // 'REFERRALS',
    'DIRECT TRAFFIC',
    'OFFLINE'
]

export interface IContactSummaryReportHeader {
    start: string,
    end: string,
    name: string,
    year?: string,
    month?: string

}

export interface IReportTimeInterval {
    start: string | Date
    end: string | Date
}

export const MONTHS_OF_THE_YEAR = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export interface ISourceContactStatistics {
    [dataSource: string]: number
}
// export interface IMonthContactStatistics {
//     // [source: string]: ISourceContactStatistics
//     [source: string]: number
// }
export interface IMonthContactStatistics {
    sources : ISourceContactStatistics
    total: number
}
export interface IYearContactStatistics {
    [month: string]: IMonthContactStatistics
}
export interface IFranchiseeContactStatistics {
    [year: string]: IYearContactStatistics
}
export interface IContactStatistics {
    [franchisee: string]: IFranchiseeContactStatistics
}
export interface IContactStatisticsFilter {
    reportViewType: ReportViewTypes,
    franchisee?: string,
    source?: string

}
export enum FormConversions {
    RECENT_CONVERSION = 'Recent Conversion',
    FIRST_CONVERSION = 'First Conversion'
}
export enum LeadsLabels {
    SELECTED_INTERVAL_ = 'Selected Period Leads',
    PREVIOUS_INTERVAL_ = 'Previous Period Leads'
}
export enum SalesLabels {
    SELECTED_INTERVAL_ = 'Selected Period Sales',
    PREVIOUS_INTERVAL_ = 'Previous Period Sales'
}
export interface IConversionFormMonthlyStatistics{
    [month: string]: number
}
export interface IConversionFormYearStatistic {
    total: number
    months: IConversionFormMonthlyStatistics
}
export interface IConversionFormYearlyStatistic{
    [year: string]: IConversionFormYearStatistic
}
export interface IConversionFormStatistics {
    [form: string]: IConversionFormYearlyStatistic
}
export interface IGoalReportFilter{
    franchisee: Franchisee | undefined,
    goalId: string | undefined,
    year: string| undefined,
    loading: boolean
}
export type GoalFilter ={
    franchisee: Franchisee | undefined,
    goalId: string | undefined,
    year: string| undefined
}
export interface IContactReports{
    sourceData?: IMainSourceReportData | undefined
    lcsData?: IReportIntervalsData | undefined
    franchiseeData?: IReportIntervalsData | undefined
    sourceLCSData?: ISourceLCSReportData | undefined
    periodicInterval?: string[]
    sortedIntervals?: string[]
    tableData?: IContactResultsWithCount
}
export interface ILeadsReport extends IContactReports{
    leadsData?: IReportIntervalsData | undefined
    recentConversionData?:IFormsConversionsData | undefined
}
export interface ISalesReport extends IContactReports{
    salesData?: IReportIntervalsData | undefined
    salesGoalsData?: IReportIntervalsData | undefined
}
export interface ISourceLCSReportData {
    [source: string]: IReportIntervalsData
}
export interface IContactReportTableData {
    id: string;
    uid: string;
    hs_analytics_source?: string;
    hs_analytics_source_data_1?: string;
    hs_analytics_source_data_2?: string;
    email: string;
    firstName:string;
    lastName: string;
    listId: string;
    company: string;
    contact: Contact;
}
export interface ILeadsTableData extends IContactReportTableData{
    phone: string;
    firstConversion: string;
    recentConversion: string;
    createdDate: string;
    modifiedDate: string;
    lifeCycleStage: string


}