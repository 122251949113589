import {Deal} from "../deal";
import {PipelineStage} from "../pipeline-stage";

const Parse = require('parse/dist/parse');
export class DealStage extends Parse.Object  {
    constructor() {
        super('DealStage');
    }

    get deal(): Deal {
        return this.get('deal');
    }
    set deal(value) {
        this.set('deal', value);
    }

    get dateEntered(): string {
        return this.get('dateEntered');
    }
    set dateEntered(value) {
        this.set('dateEntered', value);
    }

    get pipelineStage(): PipelineStage  {
        return this.get('pipelineStage');
    }
    set pipelineStage(value) {
        this.set('pipelineStage', value);
    }

}
