import {HubSpotToParseObject} from "../../common";
import {Pipeline} from "../pipeline";

export class PipelineStage extends HubSpotToParseObject {
    constructor() {
        super('PipelineStage');
    }

    get name(): string {
        return this.get('name');
    }
    set name(value) {
        this.set('name', value);
    }

    get pipeline(): Pipeline {
        return this.get('pipeline');
    }
    set pipeline(value) {
        this.set('pipeline', value);
    }
}
