
const Parse = require('parse/dist/parse');

export class Permission extends Parse.Object {
    constructor() {
        super('Permission');
    }

    get description(): string {
        return this.get('description');
    }

    set description(value) {
        this.set('description', value);
    }

    get name(): string {
        return this.get('name');
    }

    set name(value) {
        this.set('name', value);
    }

    get module(): string {
        return this.get('module');
    }

    set module(value) {
        this.set('module', value);
    }

    get key(): string {
        return this.get('key');
    }

    set key(value) {
        this.set('key', value);
    }

    get inherits(): Permission[] {
        return this.get('inherits');
    }

    set inherits(inherits) {
        this.set('inherits', inherits);
    }

    get weight(): number {
        return this.get('weight');
    }

    set weight(value) {
        this.set('weight', value);
    }

    get meta(): Parse.Object {
        return this.get('meta');
    }

    set meta(value) {
        this.set('meta', value);
    }
}
