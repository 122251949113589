import { HubSpotToParseObject } from '../../common';
import { Contact } from '../contact';

export class Company extends HubSpotToParseObject {
  constructor() {
    super('Company');
  }

  get name(): string {
    return this.get('name');
  }
  set name(value) {
    this.set('name', value);
  }

  get phone(): string {
    return this.get('phone');
  }
  set phone(value) {
    this.set('phone', value);
  }

  get email(): string {
    return this.get('email');
  }
  set email(value) {
    this.set('email', value);
  }


  get owner(): Contact | undefined {
    return this.get('owner');
  }
  set owner(value) {
    this.set('owner', value);
  }

}



